import React, { useContext } from "react";
import { CoreContext } from "../../context/coreContext";

function ProfileSelector() {
  const { t,profileType, setProfileType, businessName} = useContext(CoreContext);
  return (
    <>
      <div className=" mb-3 rounded-md h-auto  flex  flex-row items-center justify-center md:justify-start">
        <div
          onClick={() => {setProfileType(true)}}
          className={
            profileType
              ? "cursor-pointer text-sm h-9 w-24 rounded-l-full      bg-eloop-green   text-eloop-blackish font-bold flex items-center justify-center"
              : "cursor-pointer text-xs h-9 w-24 rounded-l-full     bg-eloop-lightGray   text-eloop-mediumGray font-light  flex items-center justify-center"
          }
        >
          {t("Login.48")}
        </div>
        <div
          onClick={() => {setProfileType(false)}}
          className={
          !profileType
              ? "cursor-pointer text-center text-xs h-9 w-24 rounded-r-full    font-semibold bg-eloop-green  text-eloop-blackish   flex items-center justify-center"
              : "cursor-pointer text-center text-xs h-9 w-24 rounded-r-full    font-light bg-eloop-lightGray    text-eloop-mediumGray flex items-center justify-center"
          }
        >
          {businessName ? businessName : "Business"}
        </div>
      </div>
    </>
  );
}

export default ProfileSelector;
