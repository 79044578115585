import React, { useContext, useEffect } from "react";
import { CoreContext } from "../../context/coreContext";
import SectionText from "../../components/text/sectionText";
import { MobileView } from "react-device-detect";
import logo from "../../img/fo-icon.png";
import lightLogo from "../../img/fo-icon-light.png";
import { useLoading, Bars } from "@agney/react-loading";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { FrontOfficeContext } from "../../context/frontofficeContext";
function LoginPage() {
  const history = useHistory();

  const { containerProps, indicatorEl } = useLoading({
    loaderProps: {
      style: { color: "gray-900", marginRight: "0.6rem" },
    },
    loading: true,
    indicator: <Bars width="15" />,
  });
  const {
    theme,
    clickedLogin,
    email,
    setEmail,
    loggedIn,
    t,
    setRefreshToken,
    setLoggedIn,
    Logout,
    password,
    wrongInfo,
    setPassword,
    setClickedLogin,
    setRegistering,
    Login,
  } = useContext(CoreContext);
  let tokenFromCookies = Cookies.get("token");
  const { FrontOfficeLogout } = useContext(FrontOfficeContext);
  useEffect(() => {
    if (loggedIn) {
      Cookies.remove("token");

      Logout();
      FrontOfficeLogout();
    }
    if (tokenFromCookies) {
      HandleSubmit(tokenFromCookies);
    }
    setRegistering(false);
    setClickedLogin(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function saveEmail(email) {
    // setClickedForgotPassword(false);
    setEmail(email.toLowerCase());
  }

  function savePassword(password) {
    setPassword(password);
  }

  async function HandleSubmit(tokenCookie) {
    let refreshToken;
    if (tokenCookie) {
      refreshToken = await Login(tokenCookie);
      Cookies.set("token", refreshToken, { expires: 1 });
    } else {
      refreshToken = await Login();
      Cookies.set("token", refreshToken, { expires: 1 });
    }
    if (refreshToken !== 401) {
      setRefreshToken(refreshToken);
      setLoggedIn(true);
      history.push("/profile");
    }
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      HandleSubmit();
    }
  }
  return (
    <>
      <div className="flex h-screen items-center justify-center">
        <div className="flex flex-col items-center">
          <MobileView>
            <img
              src={theme === "dark" ? lightLogo : logo}
              className="h-28 "
              alt="logo"
            />
          </MobileView>
          <SectionText label={t("Login.2")} />
          <input
            value={email}
            placeholder="Email"
            className="text-sm shadow-sm border border-gray-200 dark:text-white w-64 mt-3 text-center  h-9 appearance-none outline-none rounded-full mb-3"
            onChange={(e) => saveEmail(e.target.value)}
          />
          <input
            value={password}
            placeholder={t("Login.8")}
            onKeyPress={(e) => {
              handleKeyPress(e);
            }}
            onChange={(e) => savePassword(e.target.value)}
            type="password"
            className="text-sm text-center  shadow-sm border border-gray-200 dark:text-white focus:border-gray-200 focus:ring-0 h-9 w-64 appearance-none  rounded-full mb-2 outline-none password"
          />
          {wrongInfo && (
            <div className="text-xs text-red-600">{t("Login.185")}</div>
          )}
          <div
            onClick={() => HandleSubmit()}
            className="h-9 w-48 rounded-full mt-3 bg-eloop-green font-bold text-sm cursor-pointer flex items-center justify-center text-blackish "
          >
            {clickedLogin ? (
              <div className="text-eloop-blackish flex flex-row items-center">
                {" "}
                <section {...containerProps}>{indicatorEl}</section>
                {t("Login.9")}
              </div>
            ) : (
              t("Login.2")
            )}
          </div>
          <div
            onClick={() => history.push("/forgot-password")}
            className="text-xs cursor-pointer  text-gray-500 mt-4"
          >
            {t("Login.10")}
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
