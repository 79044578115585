import config from "../config/config";
import axios from "axios";

class DatawarehouseAPI {
  //declaring urls for requests
  baseURL = process.env.REACT_APP_SERVER_BASE_URL;
  checkUserStatusURL = config.DATAWAREHOUSE.endpoints.checkUserStatus;
  payAgainURL = config.DATAWAREHOUSE.endpoints.payAgain;
  getPremiumPriceURL = config.DATAWAREHOUSE.endpoints.getPremiumPrice;
  getInvoicesURL = config.DATAWAREHOUSE.endpoints.getInvoices;
  getStatsURL = config.DATAWAREHOUSE.endpoints.getStats;
  getActiveTripURL = config.DATAWAREHOUSE.endpoints.getActiveTrip;
  referralStatsURL = config.DATAWAREHOUSE.endpoints.getReferralStats;
  calcCostURl = config.DATAWAREHOUSE.endpoints.calculateCost;
  checkRookieFeeURL = config.DATAWAREHOUSE.endpoints.checkRookieFee;
  signUpRookieURL = config.DATAWAREHOUSE.endpoints.signUpRookie;
  addPhoneNumUrl = config.DATAWAREHOUSE.endpoints.addPhoneNum;
  adyenReturnURL = process.env.REACT_APP_ADYEN_RETURN_URL;
  sendOTPURL = config.DATAWAREHOUSE.endpoints.sendOTP;
  checkOTPURL = config.DATAWAREHOUSE.endpoints.checkOTP;
  payInvoiceAgainURL = config.DATAWAREHOUSE.endpoints.payInvoiceAgain;
  localUrl = "http://localhost:8080";

  //declaring header for requests
  header = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };

  //get user status data with user id
  CheckUserStatus(userId) {
    try {
      return new Promise((resolve, reject) => {
        //we check if user id exists in params, if not return 401
        if (userId) {
          const options = {
            method: "POST",
            headers: this.header,
            body: JSON.stringify({
              userId: userId,
            }),
          };
          fetch(`${this.baseURL + this.checkUserStatusURL}`, options).then(
            async (response) => {
              const data = await response.json();
              if (response.ok) {
                resolve(data);
              } else {
                resolve(401);
              }
            }
          );
        } else {
          resolve(401);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  //send kyc link via twilio api, routing thru datawarehouse
  SendPBLink(email, phoneNum) {
    return new Promise((resolve, reject) => {
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: phoneNum,
          email: email,
        }),
        redirect: "follow",
      };
      try {
        fetch(
          `${this.baseURL}/api/registration/verify/kyc`,
          requestOptions
        ).then(async (response) => {
          if (response.ok) {
            let data = await response.json();
            if (data.status === "accepted") {
              resolve(200);
            } else {
              resolve(401);
            }
          } else {
            resolve(401);
          }
        });
      } catch (e) {
        console.log(e);
      }
    });
  }

  //get adyen form for adyen api only integration// email and entityId required
  GetAdyenForm(email, entityId) {
    return new Promise((resolve, reject) => {
      try {
        var raw = JSON.stringify({
          email: email,
          returnUrl: this.adyenReturnURL,
          entityId: entityId,
        });
        var requestOptions = {
          method: "POST",
          headers: this.header,
          body: raw,
          redirect: "follow",
        };
        fetch(
          `${this.baseURL + config.DATAWAREHOUSE.endpoints.getAdyenFormData}`,
          requestOptions
        )
          .then(async (response) => {
            if (response.ok) {
              const data = await response.json();
              resolve(data);
            } else {
              resolve(401);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    });
  }

  // get premium plan price
  GetPremiumPrice() {
    try {
      return new Promise((resolve, reject) => {
        const options = {
          method: "POST",
          headers: this.header,
          body: JSON.stringify({
            type: "SINGLE",
            name: "Annual subscription",
            productId: "0d1a2ea5-a50a-4323-80a2-bcd5f48e0769",
          }),
        };
        fetch(`${this.baseURL + this.getPremiumPriceURL}`, options).then(
          async (response) => {
            const data = await response.json();
            if (response.ok) {
              resolve(data);
            } else {
              resolve(401);
            }
          }
        );
      });
    } catch (e) {
      console.log(e);
    }
  }

  SuspendUser(userId) {
    return new Promise((resolve, reject) => {
      try {
        const options = {
          method: "DELETE",
          headers: this.header,
        };
        let url = `${this.baseURL}/users/${userId}`;
        fetch(url, options).then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            if (data.status === 200) {
              resolve(200);
            } else {
              resolve(401);
            }
          } else {
            resolve(401);
          }
        });
      } catch (e) {
        console.log(e);
        resolve(401);
      }
    });
  }

  BuyCredit(userId, entityId, productId) {
    try {
      return new Promise((resolve, reject) => {
        const options = {
          method: "POST",
          headers: this.header,
          body: JSON.stringify({
            userId: userId,
            entityId: entityId,
            productId: productId,
          }),
        };
        let url = `${this.baseURL + "/credits/buy"}`;
        fetch(url, options).then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            if (data.statusCode === 200) {
              resolve(200);
            } else {
              resolve(401);
            }
          } else {
            resolve(401);
          }
        });
      });
    } catch (e) {
      console.log(e);
    }
  }

  //get user invocies using refresh token
  GetInvoices(refreshToken) {
    try {
      return new Promise((resolve, reject) => {
        //we check if refreshToken exists in params, if not return 401
        if (refreshToken) {
          const options = {
            method: "POST",
            headers: this.header,
            body: JSON.stringify({
              bearer: refreshToken,
            }),
          };

          fetch(`${this.baseURL + this.getInvoicesURL}`, options).then(
            async (response) => {
              const data = await response.json();
              if (response.ok) {
                resolve(data);
              } else {
                resolve(401);
              }
            }
          );
        } else {
          resolve(401);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
  CheckStats(userId) {
    try {
      return new Promise((resolve, reject) => {
        //we check if user id exists in params, if not return 401
        if (userId) {
          const options = {
            method: "POST",
            headers: this.header,
            body: JSON.stringify({
              userId: userId,
            }),
          };
          fetch(`${this.baseURL + this.getStatsURL}`, options).then(
            async (response) => {
              const data = await response.json();
              if (response.ok) {
                resolve(data);
              } else {
                resolve(401);
              }
            }
          );
        } else {
          resolve(401);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
  //get active trip info with refresh token
  GetActiveTrip(refreshToken) {
    try {
      return new Promise((resolve, reject) => {
        //we check if refreshToken exists in params, if not return 401
        if (refreshToken) {
          const options = {
            method: "POST",
            headers: this.header,
            body: JSON.stringify({
              bearer: refreshToken,
            }),
          };
          // setTimeout(()=>resolve(activeTripExample), 2000);

          fetch(`${this.baseURL + this.getActiveTripURL}`, options).then(
            async (response) => {
              const data = await response.json();
              if (response.ok) {
                resolve(data);
              } else {
                resolve(401);
              }
            }
          );
        } else {
          resolve(401);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
  //get referral stats with entityid and user id
  GetReferralStats(entityId, userId) {
    try {
      return new Promise((resolve, reject) => {
        //we check if entity id and user id exists in params, if not return 401
        if (entityId && userId) {
          const options = {
            method: "POST",
            headers: this.header,
            body: JSON.stringify({
              userId: userId,
              entityId: entityId,
            }),
          };
          fetch(`${this.baseURL + this.referralStatsURL}`, options).then(
            async (response) => {
              const data = await response.json();
              if (response.ok) {
                resolve(data);
              } else {
                resolve(401);
              }
            }
          );
        } else {
          resolve(401);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  //calculate trip with given info
  CalculateCost(
    days,
    hours,
    minutes,
    kmToCalculate,
    userPlanToCalc,
    token,
    model,
    userId
  ) {
    try {
      return new Promise((resolve, reject) => {
        let billingGroupDiscount;
        switch (userPlanToCalc) {
          case "Basic":
            billingGroupDiscount = 0;
            break;
          case "Plus":
            billingGroupDiscount = 10;
            break;
          case "Pro":
            billingGroupDiscount = 20;
            break;
          default:
            billingGroupDiscount = 0;
            break;
        }

        const options = {
          method: "POST",
          headers: this.header,
          body: JSON.stringify({
            days: Number(days),
            hours: Number(hours),
            minutes: Number(minutes),
            km: Number(kmToCalculate),
            billingGroup: userPlanToCalc,
            billingGroupDiscountPercent: billingGroupDiscount,
            model: model,
            eot: Number(token),
            userId: userId,
          }),
        };
        fetch(`${this.baseURL + this.calcCostURl}`, options).then(
          async (response) => {
            const data = await response.json();
            if (response.ok) {
              resolve(data);
            } else {
              resolve(401);
            }
          }
        );
      });
    } catch (e) {
      console.log(e);
    }
  }
  //check rookie subscription status using user id and serviceStatus
  CheckRookie(userId, serviceStatus) {
    try {
      return new Promise((resolve, reject) => {
        //we check if userId exists in params, if not return 401
        if (userId && serviceStatus) {
          const options = {
            method: "POST",
            headers: this.header,
            body: JSON.stringify({
              userId: userId,
              serviceStatus: serviceStatus,
            }),
          };
          fetch(`${this.baseURL + this.checkRookieFeeURL}`, options).then(
            async (response) => {
              const data = await response.json();
              if (response.ok) {
                resolve(data);
              } else {
                resolve(401);
              }
            }
          );
        } else {
          resolve(401);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
  //add users phone number
  AddPhoneNum = (profileId, phoneNum) => {
    //replace all plus's before phone number and add just one
    phoneNum = phoneNum.replace(/\+/g, "");
    phoneNum = "+" + phoneNum;
    var requestOptions = {
      method: "POST",
      headers: this.header,
      body: JSON.stringify({
        profileId: profileId,
        phoneNumber: phoneNum,
      }),
      redirect: "follow",
    };
    fetch(`${this.baseURL + this.addPhoneNumUrl}`, requestOptions).then(
      async (response) => {}
    );
  };

  //subscribe to rookie using user id, entitiy id and profile idd
  SubscribeToRookie(userId, entityId, profileId) {
    try {
      return new Promise((resolve, reject) => {
        //we check if required vars exists in params, if not return 401
        if (userId && entityId && profileId) {
          const options = {
            method: "POST",
            headers: this.header,
            body: JSON.stringify({
              userId: userId,
              entityId: entityId,
              profileId: profileId,
            }),
          };
          fetch(`${this.baseURL + this.signUpRookieURL}`, options).then(
            async (response) => {
              const data = await response.json();
              if (response.ok) {
                resolve(data);
              } else {
                resolve(401);
              }
            }
          );
        } else {
          resolve(401);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  //send pb reference key to server, so we know user sent pb link as sms
  SendPBkey(key, userId, profileId) {
    try {
      return new Promise((resolve, reject) => {
        //we check if required params exists, if not return 401
        if (key && userId && profileId) {
          const options = {
            method: "POST",
            headers: this.header,
            body: JSON.stringify({
              key: key,
              userId: userId,
              profileId: profileId,
              date: new Date(),
            }),
            redirect: "follow",
          };
          fetch(`${this.baseURL}/findPBkey`, options).then(
            async (response) => {}
          );
        } else {
          resolve(401);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  CheckPassbase(email) {
    return new Promise((resolve, reject) => {
      var options = {
        method: "POST",
        headers: this.header,
        body: JSON.stringify({
          email: email,
        }),
        redirect: "follow",
      };
      try {
        fetch(
          `${this.baseURL + config.DATAWAREHOUSE.endpoints.kycStatus}`,
          options
        ).then(async (response) => {
          if (response.ok) {
            let data = await response.json();
            resolve(data);
            if (data.status === "approved") {
              resolve(200);
            } else if (data.status === "declined") {
              resolve(200);
            } else {
              resolve(200);
            }
          } else {
            resolve(401);
          }
        });
      } catch (e) {
        console.log(e);
      }
    });
  }

  //send otp verification
  SendOTP(phoneNum) {
    try {
      return new Promise((resolve, reject) => {
        //we check if phone number exists in params, if not return 401
        if (phoneNum) {
          const options = {
            method: "POST",
            headers: this.header,
            body: JSON.stringify({
              phoneNumber: phoneNum,
            }),
          };
          fetch(`${this.baseURL + this.sendOTPURL}`, options).then(
            async (response) => {
              const data = await response.json();
              if (data.status === "pending") {
                resolve(200);
              } else {
                resolve(401);
              }
            }
          );
        } else {
          resolve(401);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  //pay invoice again with invoice id
  PayInvoiceAgain(invoiceId) {
    try {
      return new Promise((resolve, reject) => {
        //we check if phone number exists in params, if not return 401
        if (invoiceId) {
          const options = {
            method: "POST",
            headers: this.header,
            body: JSON.stringify({
              invoiceId: invoiceId,
            }),
          };
          fetch(`${this.baseURL + this.payInvoiceAgainURL}`, options).then(
            async (response) => {
              if (response.ok) {
                resolve(200);
              } else {
                resolve(401);
              }
            }
          );
        } else {
          resolve(401);
        }
      });
    } catch (e) {}
  }

  //check otp verification
  CheckOTP(phoneNum, otp) {
    try {
      return new Promise((resolve, reject) => {
        //we check if phone number exists in params, if not return 401
        if (phoneNum && otp) {
          const options = {
            method: "POST",
            headers: this.header,
            body: JSON.stringify({
              phoneNumber: phoneNum,
              code: otp,
            }),
          };
          fetch(`${this.baseURL + this.checkOTPURL}`, options).then(
            async (response) => {
              const data = await response.json();
              if (data.status === "approved") {
                resolve(200);
              } else {
                resolve(401);
              }
            }
          );
        } else {
          resolve(401);
        }
      });
    } catch (e) {}
  }

  async GetSumsubToken(userId) {
    let data = {
      userId: userId,
    };
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(
            "https://api.eloop.app" +
              config.DATAWAREHOUSE.endpoints.sumsubToken,
            data,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            reject(400);
          });
      } catch (e) {
        reject(400);
      }
    });
  }

  async InviteToBusiness(email, businessId, businessName) {
    let data = {
      businessName: businessName,
      email: email,
    };
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(
            `${this.baseURL}/v2/registration/business/${businessId}/users/invite`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            reject(400);
          });
      } catch (e) {
        reject(400);
      }
    });
  }

  ApplyPromo(token, promoCode) {
    try {
      return new Promise((resolve, reject) => {
        //we check if required vars exists in params, if not return 401
        if (promoCode && token) {
          const options = {
            method: "POST",
            headers: this.header,
            body: JSON.stringify({
              bearer: token,
              promoCode: promoCode,
            }),
          };
          fetch(
            `${this.baseURL + config.DATAWAREHOUSE.endpoints.applyPromo}`,
            options
          ).then(async (response) => {
            const data = await response.json();
            if (response.ok) {
              if (data.statusCode === 200) {
                resolve(200);
              } else {
                resolve(401);
              }
            } else {
              resolve(401);
            }
          });
        } else {
          resolve(401);
        }
      });
    } catch (e) {}
  }

  GetPromoMembership(promoCode) {
    try {
      return new Promise((resolve, reject) => {
        if (promoCode) {
          const options = {
            method: "GET",
            headers: this.header,
          };
          fetch(
            `${
              this.baseURL + config.DATAWAREHOUSE.endpoints.getPromoMembership
            }?promoCode=${promoCode}`,
            options
          ).then(async (response) => {
            const data = await response.json();
            if (response.ok) {
              resolve(data);
            } else {
              resolve(401);
            }
          });
        } else {
          resolve(401);
        }
      });
    } catch (e) {}
  }

  useMembershipPromoCode(userId, promoCode) {
    try {
      return new Promise((resolve, reject) => {
        if (userId && promoCode) {
          const options = {
            method: "PUT",
            headers: this.header,
          };
          fetch(
            `${
              this.baseURL + config.DATAWAREHOUSE.endpoints.getPromoMembership
            }/users/${userId}/codes/${promoCode}/use`,
            options
          ).then(async (response) => {
            const data = await response.json();
            if (response.ok) {
              resolve(data);
            } else {
              resolve(401);
            }
          });
        } else {
          resolve(401);
        }
      });
    } catch (e) {}
  }
}

export default DatawarehouseAPI;
