import React from "react";

function Notification({ message, myFunction, btnText }) {
  return (
    <div>
      <div className=" bg-eloop-red p-2 mb-2 shadow-lg rounded-2xl flex justify-between  items-center">
        <div className="ml-2 text-white text-left font-medium">{message}</div>
        <div
          onClick={myFunction}
          className="px-4 cursor-pointer pt-1 pb-1 rounded-full bg-white text-eloop-blackish "
        >
          {" "}
          {btnText}
        </div>
      </div>
    </div>
  );
}

export function Message({ message }) {
  return (
    <div>
      <div className=" bg-eloop-red p-2 mb-2 shadow-lg rounded-md flex justify-between  items-center">
        <div className="ml-2 text-white text-left font-medium">{message}</div>
      </div>
    </div>
  );
}

export default Notification;
