import { createContext, useContext, useState } from "react";
import { CoreContext } from "./coreContext";
import VulogApi from "../api/vulogAPI";
import moment from "moment";
import DatawarehouseApi from "../api/datawarehouseAPI";

export const FrontOfficeContext = createContext();

function FrontOfficeContextProvider(props) {
  const { entityId, userId, refreshToken, setUserPlan, isJwtNotExpired } =
    useContext(CoreContext);
  const VulogAPI = new VulogApi();
  const DatawarehouseAPI = new DatawarehouseApi();
  const [FOcurrentPage, setFOCurrentPage] = useState("Login");
  //to get referral stats
  const [referralCode, setReferralCode] = useState("");
  const [referralLink, setReferralLink] = useState("");
  const [usersReferred, setUsersReferred] = useState("");
  const [earned, setEarned] = useState("");

  async function Referral() {
    try {
      return await new Promise(async (resolve, reject) => {
        const data = await DatawarehouseAPI.GetReferralStats(entityId, userId);
        setReferralCode(data.referralCode);
        setReferralLink(data.referralLink);
        setUsersReferred(data.usersReferred);
        setEarned(data.referralAmountEarned);
        resolve(data);
      });
    } catch (e) {}
  }

  //to get promo codes of user
  const [credits, setCredits] = useState("");
  const [totalCredit, setTotalCredit] = useState("");
  var countCredit = 0;
  const [firstCodeExpire, setFirstCodeExpire] = useState("");

  async function GetPromoCredits(myEntityId) {
    const [year, month, day] = new Date().toISOString().match(/\d+/g);
    var dateToCheckFor = year + "-" + month + "-" + day;
    var datesToBeChecked = [];
    try {
      return await new Promise(async (resolve, reject) => {
        const data = await VulogAPI.GetCredits(refreshToken, myEntityId);

        try {
          var dataReturn = data.map(function (obj) {
            obj.initialAmount = obj.initialAmount.toFixed(2);
            obj.initialAmount = obj.initialAmount + " €";
            // check if there is validity end date, if not then use 2030-01-01
            const [y, m, d] = obj.validityEndDate
              ? obj.validityEndDate.match(/\d+/g)
              : "2030-01-01".match(/\d+/g);
            var newDateToUse = y + "-" + m + "-" + d;
            if (
              moment(newDateToUse).isAfter(dateToCheckFor) &&
              (obj.availableAmount > 0 || obj.discountCategory === "PERCENTAGE")
            ) {
              if (obj.creditAlreadyUsed === true && obj.oneTimeUsage === true) {
                countCredit += 0;
              } else {
                countCredit += obj.availableAmount;
                datesToBeChecked.push(newDateToUse);
              }
            }
            var newDate = d + "." + m + "." + y;

            let promocodeReference = "-";
            let discountPercentage = "-";
            let availableAmount = obj.availableAmount + " €";
            if (obj.oneTimeUsage === true && obj.creditAlreadyUsed === true) {
              availableAmount = "0 €";
            }
            if (obj.promoCodeReference) {
              promocodeReference =
                obj.promoCodeReference.substring(0, 6) + "...";
            }
            if (!obj.promoCodeReference) {
              if (obj.notes) {
                if (obj.notes.includes("EOT_CASHBACK")) {
                  promocodeReference = "EOTCASHBACK";
                } else if (obj.notes.includes("|")) {
                  promocodeReference = obj.notes.split("|")[1];
                } else if (obj.notes.includes("Generated from Product")) {
                  promocodeReference = "Credit Package";
                } else {
                  promocodeReference = obj.notes.substring(0, 24);
                }
                // if the string is too long, we cut it
                if (promocodeReference.length > 24) {
                  promocodeReference = promocodeReference.substring(0, 24) + "...";
                }
              }
            }
            if (obj.discountPercentage) {
              discountPercentage = obj.discountPercentage + " %";
            }
            return {
              promoCodeReference: promocodeReference,
              validityEndDate: newDate,
              discountPercentage: discountPercentage,
              availableAmount: availableAmount,
              initialAmount: obj.initialAmount,
            };
          });
          let nearestDate = datesToBeChecked[0];
          let dateTosShow;
          try {
            dateTosShow =
              nearestDate.substring(8, 10) +
              "." +
              nearestDate.substring(5, 7) +
              "." +
              nearestDate.substring(0, 4);
          } catch (e) {
            console.log(e);
          }
          setFirstCodeExpire(dateTosShow);
          setTotalCredit(countCredit.toFixed(2));
          setCredits(dataReturn);
          resolve(dataReturn);
        } catch (e) {
          console.log(e);
          resolve(data);
        }
        if (data === 401) {
        }
      });
    } catch (e) {}
  }

  //to get Active Trip of user
  const [dayDriven, setDayDriven] = useState(0);
  const [hourDriven, setHourDriven] = useState(0);
  const [minuteDriven, setMinuteDriven] = useState(0);
  const [secondDriven, setSecondDriven] = useState(0);

  const [kmDriven, setKmDriven] = useState("");
  const [plateNum, setPlateNum] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [hasActiveTrip, setHasActiveTrip] = useState(false);
  const [autonomy, setAutonomy] = useState("");
  const [lastUpdate, setLastUpdate] = useState("");

  async function ActiveTrip(myRefresh) {
    try {
      return await new Promise(async (resolve, reject) => {
        const data = await DatawarehouseAPI.GetActiveTrip(myRefresh);
        if (data.activeTrip === true) {
          setHasActiveTrip(true);
          setDayDriven(data.time.days);
          setHourDriven(data.time.hours);
          setMinuteDriven(data.time.minutes);
          setSecondDriven(data.time.seconds);
          setKmDriven(data.km);
          setPlateNum(data.plate);
          const [y, m, d] = data.lastUpdated.match(/\d+/g);
          let dateForTime = data.lastUpdated
            ? new Date(data.lastUpdated)
            : new Date();
          let time = dateForTime.getHours() + ":" + dateForTime.getMinutes();
          var newDateToUse = d + "." + m + "." + y;
          setAutonomy(data.autonomy);
          setLastUpdate(newDateToUse + " \n " + time);
          setTotalCost(data.cost.total);
          resolve(data);
        } else {
          setHasActiveTrip(false);
          resolve(data);
        }
      });
    } catch (e) {}
  }

  //to apply promoCode
  const [validPromoCode, setValidPromoCode] = useState(1);
  const [promoCode, setPromoCode] = useState("");

  const ApplyPromoCode = async (code) => {
    var codeToUse;
    if (code) {
      codeToUse = code;
    } else {
      codeToUse = promoCode;
    }
    try {
      return await new Promise(async (resolve, reject) => {
        var response = await DatawarehouseAPI.ApplyPromo(
          refreshToken,
          codeToUse
        );
        if (response === 200) {
          resolve("ok");
          setValidPromoCode(2);
          setPromoCode("");
        }
        if (response !== 200) {
          setValidPromoCode(3);
          resolve("error");
        }
      });
    } catch (e) {}
  };

  //to get invocies
  const [trips, setTrips] = useState("");
  const [products, setProducts] = useState("");

  function TripDurationFormatter(obj) {
    var duration;
    if (obj.days > 0) {
      duration =
        obj.days +
        " d " +
        obj.hours +
        " h " +
        obj.minutes +
        " m " +
        obj.seconds +
        " s";
    } else {
      if (obj.hours > 0) {
        if (obj.minutes > 0) {
          duration = obj.hours + " h " + obj.minutes + " m";
        } else {
          duration = obj.hours + " h";
        }
      } else {
        if (obj.minutes > 0) {
          duration = obj.minutes + " m ";
        }
      }
    }
    return duration;
  }

  const [showInvoice, setShowInvoice] = useState(false);
  const [invoiceLink, setInvoiceLink] = useState("");

  async function ViewInvoice(link, token) {
    var myToken = await isJwtNotExpired(token);
    setInvoiceLink(
      `https://java-eu01.vulog.com/apiv5/invoices/${link + myToken}`
    );
    setShowInvoice(true);
  }

  const [payableProducts, setPayableProducts] = useState("");
  const [payableTrips, setPayableTrips] = useState("");

  function GetInvoices(myRefresh) {
    var refreshToUse;
    if (myRefresh) {
      refreshToUse = myRefresh;
    } else {
      refreshToUse = refreshToken;
    }
    return new Promise(async (resolve, reject) => {
      const data = await DatawarehouseAPI.GetInvoices(refreshToUse);
      try {
        var mappedTrips = data.tripInvoices.map(function (obj) {
          const [y, m, d, hh, mm] = obj.trip.tripStartDate.match(/\d+/g);
          var newDate = d + "." + m + "." + y + " | " + hh + ":" + mm;
          var newDistance = obj.trip.distance.toFixed(2);
          let duration = TripDurationFormatter(obj.trip.durationJSON);
          return {
            invoiceId: obj.invoice.id,
            date: newDate,
            distance: newDistance + " km",
            duration: duration,
            car: obj.vehiculePlate,
            cost: obj.totalWithTax.toFixed(2) + " €",
            status: obj.invoice.billingStatus,
            viewLink: `${obj.invoice.id}/pdf?x-api-key=${VulogAPI.apiKey}&access_token=`,
            isPayable: obj.isPayable,
            paymentLink: obj.paymentLink,
            needs3DSecure: obj.needs3DSecure,
          };
        });

        var mappedProduct = data.productInvoices.map(function (obj) {
          const [y, m, d, hh, mm] = obj.invoice.billingDate.match(/\d+/g);
          var newDate = d + "." + m + "." + y + " | " + hh + ":" + mm;
          if (
            obj.productId === "aaf61bb1-cb54-43bb-bb00-709878d5ee4e" ||
            obj.productId === "ce9e5823-5bfd-4d82-9e24-e48aba25b33b"
          ) {
            if (obj.invoice.billingStatus === "PENDING") {
              setUserPlan("Plus");
            }
          }
          if (
            obj.productId === "5b8bd1b7-599d-4d9c-9448-e9ba407f2aca" ||
            obj.productId === "9a85b8b8-749c-487c-98ec-27eeeeed3483"
          ) {
            if (obj.invoice.billingStatus === "PENDING") {
              setUserPlan("Pro");
            }
          }

          return {
            invoiceId: obj.invoice.id,
            date: newDate,
            name: obj.productName,
            amount: obj.totalWithTax + " €",
            status: obj.invoice.billingStatus,
            viewLink: `${obj.invoice.id}/pdf?x-api-key=${VulogAPI.apiKey}&access_token=`,
            isPayable: obj.isPayable,
            paymentLink: obj.paymentLink,
            needs3DSecure: obj.needs3DSecure,
          };
        });

        let unpaidProducts = [];
        for (let i = 0; i < mappedProduct.length; i++) {
          if (
            mappedProduct[i].status === "REFUSED" ||
            mappedProduct[i].status === "UNPAID" ||
            mappedProduct[i].status === "PENDING" ||
            mappedProduct[i].status === "PENDING_AUTHENTICATION"
          ) {
            unpaidProducts.push(mappedProduct[i]);
          }
        }
        let unpaidTrips = [];
        for (let i = 0; i < mappedTrips.length; i++) {
          if (
            mappedTrips[i].status === "REFUSED" ||
            mappedTrips[i].status === "PENDING" ||
            mappedTrips[i].status === "UNPAID" ||
            mappedTrips[i].status === "PENDING_AUTHENTICATION"
          ) {
            unpaidTrips.push(mappedTrips[i]);
          }
        }

        setTrips(mappedTrips);
        setPayableProducts(unpaidProducts);
        setPayableTrips(unpaidTrips);
        setProducts(mappedProduct);

        resolve({
          trips: mappedTrips,
          products: mappedProduct,
          unpaidProducts: unpaidProducts,
          unpaidTrips: unpaidTrips,
        });

        if (data === 401) {
          resolve(401);
        }
      } catch (e) {
        console.log(e);
      }
    });
  }

  function getInvoicesWith3ds(refreshToken) {
    try {
      return VulogAPI.getInvoicesWith3DS(refreshToken);
    } catch (e) {}
  }
  function replayInvoicesWith3ds(refreshToken, invoicesObj) {
    try {
      return VulogAPI.replayInvoicesWith3DS(refreshToken, invoicesObj);
    } catch (e) {}
  }
  //for new password
  const [passChangeStatus, setPassChangeStatus] = useState("");
  const [passError, setPassError] = useState("");
  const [newPassword, setNewPassword] = useState("");

  async function ChangePassword() {
    setPassChangeStatus("loading");
    try {
      return await new Promise(async (resolve, reject) => {
        var data = await VulogAPI.ChangePassword(newPassword, refreshToken);
        if (data === 200) {
          resolve(data);
          setPassChangeStatus("success");
        } else {
          if (data.invalid_reason === "Same_as_previous") {
            setPassError("same");
            resolve(data);
          }
          setPassChangeStatus("failed");
        }
      });
    } catch (e) {}
  }

  function FrontOfficeLogout() {
    setTrips("");
    setCredits("");
    setProducts("");
    setTotalCredit("");
    setFirstCodeExpire("");
  }

  const value = {
    autonomy,
    lastUpdate,
    showInvoice,
    invoiceLink,
    ViewInvoice,
    setShowInvoice,
    payableProducts,
    payableTrips,
    setCredits,
    FrontOfficeLogout,
    FOcurrentPage,
    setFOCurrentPage,
    newPassword,
    ChangePassword,
    setPassChangeStatus,
    setNewPassword,
    passChangeStatus,
    passError,
    GetInvoices,
    getInvoicesWith3ds,
    replayInvoicesWith3ds,
    trips,
    products,
    validPromoCode,
    setPromoCode,
    ApplyPromoCode,
    setValidPromoCode,
    ActiveTrip,
    dayDriven,
    hourDriven,
    minuteDriven,
    secondDriven,
    kmDriven,
    plateNum,
    totalCost,
    hasActiveTrip,
    credits,
    totalCredit,
    firstCodeExpire,
    GetPromoCredits,
    Referral,
    referralCode,
    referralLink,
    usersReferred,
    earned,
  };

  return (
    <FrontOfficeContext.Provider value={value}>
      {props.children}
    </FrontOfficeContext.Provider>
  );
}

export default FrontOfficeContextProvider;
