import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { CoreContext } from "../../context/coreContext";
import Notification, {Message} from "./notification";

function Notifications() {
  let navigator = useHistory();
  const {
    t,
    hasUnpaidInvoice,
    missingCard,
    profileStatus,
   
  } = useContext(CoreContext);

  return (
    <>
      {hasUnpaidInvoice && !missingCard && (
        <Notification
          message={t("Login.105")}
          btnText={t("Login.74")}
          myFunction={() => navigator.push("/invoice")}
        />
      )}
      {profileStatus==="SUSPENDED" && <Message message={t("Login.204")}/>}
    </>
  );
}

export default Notifications;
