import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import LoginPage from "../pages/login";
import { isMobile } from "react-device-detect";
import DesktopNavbar from "../components/header/desktopNavbar";
import MobileNavbar from "../components/header/mobileNavbar";
import Dashboard from "../pages/dashboard";
import ForgotPassword from "../pages/password-reset/step-1-send-reset-email";
import TooYoung from "../pages/dashboard/too-young";
import ContactPage from "../pages/dashboard/conctact-us";
import ResetPassword from "../pages/password-reset/step-2-reset-password";

function App() {
  return (
    <>
      <BrowserRouter>
        <div className="bg-white dark:bg-eloop-darkGray h-screen min-h-screen">
          <Switch>
            <Route path="/contact">
              <ContactPage />
            </Route>

            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/reset-password">
              <ResetPassword />
            </Route>
            <Route path="/youngster">
              <TooYoung />
            </Route>
            <Route path="/login">
              {isMobile ? <MobileNavbar /> : <DesktopNavbar />}
              <LoginPage />
            </Route>

            <Route path="/">
              <Dashboard />
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
