import React, { useContext, useEffect, useState } from "react";
import InvoicesPage from "./invoices";
import CreditsPage from "./credits";
import ProfilePage from "./my-account";
import { useLoading, Bars } from "@agney/react-loading";
import Popup, { EcollectPopup } from "../../components/popup";
import DesktopNavbar from "../../components/header/desktopNavbar";
import MobileNavbar from "../../components/header/mobileNavbar";
import { isMobile } from "react-device-detect";
import Stats from "./stats";
import ActiveTrip from "./activeTrip";
import Referral from "./invite-friends";
import { CoreContext } from "../../context/coreContext";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import Upgrade from "./subscriptionPlan/upgrade";
import Processing from "./kyc-pending";
import Cookies from "js-cookie";
import CloseAccount from "./deleteAccount";
import CreditStore from "./shop";
import Downgrade from "./subscriptionPlan/downgrade";
import Pro from "./subscriptionPlan/pro";
import TripCalculator from "./calculator";

function DashboardRouter() {
  const [finishedLoading, setFinishedLoading] = useState(true);
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Bars width="50" className="text-gray-900 dark:text-white" />,
  });

  const navigator = useHistory();

  let token = new URLSearchParams(useLocation().search).get("UserToken");
  let apps = new URLSearchParams(useLocation().search).get("apps");
  let payment = new URLSearchParams(useLocation().search).get("payment");
  const {
    popupOpen,
    GetUserInfo,
    setFromApp,
    Login,
    loggedIn,
    setTheme,
    setLoggedIn,
    showEcollectPopup,
  } = useContext(CoreContext);
  const [showNavbar, setShowNavbar] = useState(true);

  async function Core() {
    if (token) {
      if (payment) {
        setFinishedLoading(false);
        Cookies.set("fromApp", false);
        setShowNavbar(true);
        setFromApp(false);

        if (apps === "true") {
          setTheme("light");
          setFinishedLoading(false);
          Cookies.set("fromApp", true);
          setShowNavbar(false);
          setFromApp(true);
        }
      } else {
        setTheme("light");
        setFinishedLoading(false);
        Cookies.set("fromApp", true);
        setShowNavbar(false);
        console.log(apps);
        setFromApp(true);
      }
      // login user with token
      var refreshToken = await Login(token);
      // save token in cookies
      Cookies.set("token", refreshToken, { expires: 1 });
      var entityId = await GetUserInfo(refreshToken);
      if (entityId !== 401) {
        setLoggedIn(true);
        navigator.push(navigator.location.pathname);
        setFinishedLoading(true);
      }
      setFinishedLoading(true);
    } else {
      !loggedIn && navigator.push("/login");
    }
  }

  useEffect(() => {
    if (Cookies.get("fromApp")) {
      setFromApp(true);
      setShowNavbar(false);
      setTheme("light");
    }
    Core();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // loading bar
  if (!finishedLoading) {
    return (
      <>
        <div className="flex items-center justify-center h-screen">
          <section {...containerProps}>
            {indicatorEl} {/* renders only while loading */}
          </section>
        </div>
      </>
    );
  } else {
    return (
      <>
        {showEcollectPopup && <EcollectPopup />}
        {popupOpen && <Popup />}
        {showNavbar && (isMobile ? <MobileNavbar /> : <DesktopNavbar />)}
        <Switch>
          <Route path="/profile" component={ProfilePage} />
          <Route path="/store" component={CreditStore} />
          <Route path="/invoice" component={InvoicesPage} />
          <Route path="/credits" component={CreditsPage} />
          <Route path="/stats" component={Stats} />
          <Route path="/upgrade" component={Upgrade} />
          <Route path="/active" component={ActiveTrip} />
          <Route path="/referral" component={Referral} />
          <Route path="/calculator" component={TripCalculator} />
  
          <Route path="/processing" component={Processing} />
          <Route path="/close" component={CloseAccount} />
          <Route path="/downgrade" component={Downgrade} />
          <Route path="/pro" component={Pro} />
        </Switch>
      </>
    );
  }
}

export default DashboardRouter;
