import React, { useContext, useState, useEffect } from "react";
import { CoreContext } from "../../../context/coreContext";
import { FrontOfficeContext } from "../../../context/frontofficeContext";
import { useLoading, Bars } from "@agney/react-loading";
import { useHistory } from "react-router-dom";
export const ActiveTrip = () => {
  const [finishedLoading, setFinishedLoading] = useState(false);
  const {
    setFOCurrentPage,
    dayDriven,
    hourDriven,
    minuteDriven,
    kmDriven,
    plateNum,
    totalCost,
    hasActiveTrip,
    ActiveTrip,
    autonomy,
  } = useContext(FrontOfficeContext);
  const { t, isJwtNotExpired, accessToken, GetUserInfo } =
    useContext(CoreContext);
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: (
      <Bars width="50" className="text-eloop-blackish dark:text-white" />
    ),
  });

  let navigator = useHistory();

  async function Core() {
    setFinishedLoading(false);
    var Token = await isJwtNotExpired(accessToken);
    var userData = await GetUserInfo(Token);

    if (
      userData.serviceStatus === "SERVICE_REG_SUSPENDED" ||
      userData.profileStatus === "SUSPENDED"
    ) {
      navigator.push("/profile");
    }
    await ActiveTrip(Token);
    setFinishedLoading(true);
  }

  useEffect(() => {
    setFOCurrentPage("activeTrip");
    Core();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!finishedLoading) {
    return (
      <>
        <div className="flex items-center justify-center h-screen">
          <section {...containerProps}>
            {indicatorEl} {/* renders only while loading */}
          </section>
        </div>
      </>
    );
  } else
    return (
      <div className="flex h-screen items-center justify-center bg-white dark:bg-eloop-darkGray">
        {!hasActiveTrip ? (
          <div className="flex flex-col  items-center justify-center bg-white  dark:text-white text-gray-900 dark:bg-eloop-blackish mx-3 md:mx-0  shadow-lg  p-5 rounded-md">
            <div>{t("Login.160")}</div>
            <div
              onClick={async () => {
                setFinishedLoading(false);
                var Token = await isJwtNotExpired(accessToken);
                await ActiveTrip(Token);
                setFinishedLoading(true);
              }}
              className="p-2 px-4 mt-2  bg-eloop-green rounded-full cursor-pointer text-eloop-blackish font-semibold "
            >
              {t("Login.158")}
            </div>
          </div>
        ) : (
          <div className="flex flex-col  items-center justify-center bg-gray-300 dark:text-white text-gray-900 dark:bg-eloop-darkGray mx-3 md:mx-0  shadow-lg  p-5 rounded-md">
            <div className="text-2xl mb-4 text-center font-extrabold ">
              {t("Login.159")}
            </div>

            <div className="w-80  mb-2  flex shadow-md rounded-md bg-gray-200 dark:bg-eloop-blackish h-20">
              <div className="w-1/2 border-r-2 flex flex-col items-center justify-center border-white dark:text-white text-gray-900">
                <div className="text-xl font-bold">
                  {dayDriven > 0 && dayDriven + "d "}
                  {hourDriven + "h "}
                  {minuteDriven + "m "}
                </div>
                <div className="text-xs text-center p-0.5">
                  {t("Login.154")}
                </div>
              </div>
              <div className="w-1/2  flex flex-col items-center justify-center  dark:text-white text-gray-900">
                <div className="text-xl font-bold">{kmDriven}</div>
                <div className="text-xs text-center p-0.5">
                  {t("Login.155")}
                </div>
              </div>
            </div>
            <div className="w-80  mb-2 flex shadow-md rounded-md bg-gray-200 dark:bg-gray-900 h-20">
              <div className="w-1/2 border-r-2 flex flex-col items-center justify-center border-white dark:text-white text-gray-900">
                <div className="text-xl font-bold">{plateNum}</div>
                <div className="text-xs text-center p-0.5">
                  {t("Login.156")}
                </div>
              </div>
              <div className="w-1/2  flex flex-col items-center justify-center  dark:text-white text-gray-900">
                <div className="text-xl font-bold">{totalCost + " €"}</div>
                <div className="text-xs text-center p-0.5">
                  {t("Login.157")}
                </div>
              </div>
            </div>
            <div className="w-80   mb-2 flex shadow-md rounded-md bg-gray-200 dark:bg-gray-900 h-24">
              <div className="w-full  flex flex-col items-center justify-center border-white dark:text-white text-gray-900">
                <div className="text-xs font-bold"> {t("Login.battery")}</div>
                <div className="text-2xl font-bold">
                  {((autonomy * 100) / 420).toFixed(1) + "%"}
                </div>
                <div className="text-xs text-gray-500 text-center p-0.5">
                  {t("Login.notAccurate")}
                </div>
              </div>
            </div>
            <div
              onClick={async () => {
                setFinishedLoading(false);
                var Token = await isJwtNotExpired(accessToken);
                await ActiveTrip(Token);
                setFinishedLoading(true);
              }}
              className="p-2 px-4 mt-2  bg-eloop-green rounded-full cursor-pointer text-eloop-blackish "
            >
              {t("Login.158")}
            </div>
          </div>
        )}
      </div>
    );
};

export default ActiveTrip;
