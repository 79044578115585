import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CoreContext } from "../../../context/coreContext";
import { FrontOfficeContext } from "../../../context/frontofficeContext";
import upgradeIMG from "./assets/upgrade.svg";

export const Pro = () => {
  let navigator = useHistory();
  const { t, i18n } = useContext(CoreContext);
  const { setFOCurrentPage } = useContext(FrontOfficeContext);

  useEffect(() => {
    setFOCurrentPage("upgrade");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="h-screen  flex flex-col  items-center justify-center whitespace-pre-line">
      <div className="bg-white shadow-lg relative dark:bg-eloop-blackish rounded-md items-center gap-5 m-4 md:w-1/3 py-7 dark:text-white  flex flex-col justify-center px-9">
        <img src={upgradeIMG} alt="img" className="w-56 mt-10 mb-1" />
        <p className="w-auto font-extrabold text-center">{t("Login.195")}</p>
        <div className="flex items-center flex-col justify-center text-center">
          <div className="text-sm">
            {t("upgrade.22")}{" "}
            <span
              className="underline cursor-pointer text-blue-500"
              onClick={() =>
                window.open(
                  i18n.langugage === "en"
                    ? "https://eloop.app/en/memberships/pro/"
                    : "https://eloop.app/de/mitgliedschaften/pro/",
                  "_blank"
                )
              }
            >
              {i18n.language === "en" ? "here." : "hier."}
            </span>
            <br />
            {t("upgrade.27")}
          </div>

          <div
            onClick={() => navigator.push("/upgrade")}
            className={
              "mr-2 select-none px-1 py-0 h-9 w-52 text-sm text-right grid place-content-center font-semibold rounded-full mt-3 bg-eloop-purple cursor-pointer  text-white"
            }
          >
            {t("Login.190")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pro;
