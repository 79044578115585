import React from "react";

import { useTranslation } from "react-i18next";
// import { CoreContext } from '../../context/coreContext';

export const Package = ({
  price,
  amount,
  setPopupOpen,
  setPrice,
  setAmount,
  setProductId,
  productId,
  src,
}) => {
    const { t } = useTranslation();
  return (
    <div className=" h-auto w-80 mx-2 md:mx-0 md:w-1/4 pb-8 mb-4 rounded-xl items-center gap-2  flex flex-col  bg-gray-50 shadow-lg">
      <img src={src} alt="img" className=" -mb-4 md:h-64 h-64" />
    
      <h2 className="text-xl text-center mt-1 font-bold">
        {`${price} € + ${amount - price} € ${t("shop.5")}`}{" "}
      </h2>
      <h3 className="text-sm  text-center whitespace-pre-line">
        {`${t("shop.6")} ${amount - price} ${t("shop.62")}`}
      </h3>
      <div
        onClick={() => {
          setPrice(price);
          setPopupOpen(true);
          setAmount(amount);
          setProductId(productId);
        }}
        className="mr-2 h-9 px-5  w-40 text-sm text-right grid place-content-center rounded-full mt-2 bg-eloop-green cursor-pointer   text-black font-bold"
      >
        {t("shop.7")}
      </div>
    </div>
  );
};
export default Package;
