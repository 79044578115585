import React, { useContext, useEffect } from "react";
import fail from "./assets/fail.png";
import { useHistory } from "react-router-dom";
import { CoreContext } from "../../../context/coreContext";
import { FrontOfficeContext } from "../../../context/frontofficeContext";

function Downgrade() {
  let navigator = useHistory();
  const { t, i18n, userPlan } = useContext(CoreContext);
  const { setFOCurrentPage } = useContext(FrontOfficeContext);
  useEffect(() => {
    setFOCurrentPage("downgrade");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="h-screen  flex flex-col  whitespace-pre-line items-center justify-center">
      <div className="bg-white shadow-lg relative dark:bg-eloop-blackish rounded-md items-center gap-5 m-4 w-auto md:w-2/5 py-7 dark:text-white  flex flex-col justify-center px-9">
        <img src={fail} alt="img" className="w-56 mt-10 mb-1" />
        <p className=" font-extrabold text-center -mb-2">
          {userPlan === "Pro" || "Pro (old)"
            ? t("upgrade.29")
            : t("upgrade.30")}
        </p>
        <div className="flex items-center flex-col justify-center text-center">
          <div className="text-sm w-auto whitespace-pre-line ">
            {userPlan === "Plus" ? (
              <div>{t("upgrade.23")}</div>
            ) : (
              <div className="f">
                {t("upgrade.25")}{" "}
                <span
                  className="underline cursor-pointer text-blue-500"
                  onClick={() =>
                    window.open(
                      i18n.language === "en"
                        ? "https://eloop.app/en/memberships/plus/"
                        : "https://eloop.app/de/mitgliedschaften/plus/",
                      "_blank"
                    )
                  }
                >
                  {i18n.language === "en" ? "here.\n" : "hier.\n"}
                </span>
                <br /> {t("upgrade.26")}
              </div>
            )}
          </div>

          <div
            onClick={() => navigator.push("/upgrade")}
            className={
              "mr-2 select-none  px-1 py-0 h-9 w-52 text-sm text-right grid place-content-center font-semibold rounded-full mt-4 bg-eloop-purple cursor-pointer  text-white"
            }
          >
            {t("Login.190")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Downgrade;
