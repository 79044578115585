class BackofficeAPI {
    CalculateCashback(userId) {
        return new Promise((resolve, reject) => {
          try{
          let url = `https://api.eloop.app/bo/cashback?userId=${userId}`;
          const options = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          };
    
          fetch(url, options).then(async (response) => {
            if (response.ok) {
              const data = await response.json();
              resolve(data);
            } else {
              reject(401);
            }
          });
        }catch(e){
          reject(401);
        }
        });
        
      }
}

export default BackofficeAPI;