import React, { useContext, useState, useEffect } from "react";
import DatawarehouseAPI from "../../../api/datawarehouseAPI";
import { CoreContext } from "../../../context/coreContext";
import { useLoading, Bars } from "@agney/react-loading";
import { useHistory } from "react-router-dom";
import { FrontOfficeContext } from "../../../context/frontofficeContext";
export const Stats = () => {
  let navigator = useHistory();

  const { setFOCurrentPage } = useContext(FrontOfficeContext);
  let DatawarehouseApi = new DatawarehouseAPI();
  const [finishedLoading, setFinishedLoading] = useState(false);
  const { t, userId } = useContext(CoreContext);
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Bars width="50" className="text-gray-900 dark:text-white" />,
  });

  const [tripDriven, setTripDriven] = useState("");
  const [co2Saved, setCo2Saved] = useState("");
  async function CheckStats() {
    try {
      return await new Promise(async (resolve, reject) => {
        const data = await DatawarehouseApi.CheckStats(userId);
        setTripDriven(data.trips);
        setCo2Saved(data.co2saved);
        resolve(data);
      });
    } catch (e) {}
  }
  async function Core() {
    setFinishedLoading(false);
    await CheckStats();
    setFinishedLoading(true);
  }

  useEffect(() => {
    setFOCurrentPage("stats");
    Core();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!finishedLoading) {
    return (
      <>
        <div className="flex items-center justify-center h-screen">
          <section {...containerProps}>{indicatorEl}</section>
        </div>
      </>
    );
  } else
    return (
      <div className="flex h-screen items-center justify-center bg-white ">
        <div className="flex flex-col items-center justify-center bg-eloop-lightGray mx-3 md:mx-0 text-eloop-mediumGray shadow-lg  p-5 rounded-md">
          <div className="text-2xl text-center font-extrabold ">
            {t("Login.151")} {co2Saved} {t("Login.152")}
          </div>
          <div className="text-xl text-green-800  text-center font-medium mb-6 ">
            {t("Login.150")}
          </div>
          <div className="w-11/12 md:w-80 mb-2 flex shadow-md rounded-md bg-eloop-blackish h-20">
            <div className="w-1/2 border-r-2 flex flex-col items-center justify-center border-white text-white">
              <div className="text-3xl font-bold">{tripDriven}</div>
              <div className="text-xs text-center p-0.5">{t("Login.148")}</div>
            </div>
            <div className="w-1/2  flex flex-col items-center justify-center  text-white">
              <div className="text-3xl font-bold">{co2Saved} KG</div>
              <div className="text-xs text-center p-0.5">{t("Login.149")}</div>
            </div>
          </div>

          <div
            onClick={() => {
              navigator.push("/profile");
            }}
            className="h-9 px-7  p-3 mb-2 text-center  w-auto text-sm rounded-full mt-4 bg-eloop-green shadow-lg  cursor-pointer flex items-center justify-center  text-eloop-blackish"
          >
            {t("Login.190")}
          </div>
        </div>
      </div>
    );
};

export default Stats;
