import React, { useContext, useEffect } from "react";
import { isMobile } from "react-device-detect";
import MobileNavbar from "../../../components/header/mobileNavbar";
import DesktopNavbar from "../../../components/header/desktopNavbar";
import { CoreContext } from "../../../context/coreContext";
import BabyImg from "./assets/baby.svg";
import { useHistory } from "react-router-dom";
import {BsArrowLeft} from "react-icons/bs";

export const TooYoung = () => {
  let navigator = useHistory();
  const { setRegistering, t, setLoggedIn } = useContext(CoreContext);
  useEffect(() => {
    setRegistering(true);
    setLoggedIn(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {isMobile ? <MobileNavbar /> : <DesktopNavbar />}
      <div className="h-screen  flex flex-col  items-center justify-center">
        <div className="bg-white relative shadow-lg dark:bg-eloop-blackish rounded-md items-center gap-5 m-4 md:w-96 py-10 dark:text-white  flex flex-col justify-center px-3">
        <BsArrowLeft
          onClick={()=>navigator.push("/profile")}
           className="absolute text-xl  cursor-pointer left-7  top-7"/>
          <img src={BabyImg} alt="img" className="w-56 mb-1" />
          <p className="w-10/12 text-center">{t("Login.188")}</p>
         
        </div>
      </div>
    </div>
  );
};
export default TooYoung;
