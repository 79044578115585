import config from "../config/config";

class PassbaseAPI {

    sendSmsUrl = config.PASSBASE.endpoints.sendSMS;
    checkVerificationURL = config.PASSBASE.endpoints.checkVerification;
    PbApiKey = process.env.REACT_APP_PB_X_API_KEY;

    SendSMS(email, phoneNum) {
        try {
          return new Promise((resolve, reject) => {
            const options = {
              method: "POST",
              headers: {
                "sdk-version": "4.6.16",
                "content-type": "application/json;charset=UTF-8",
                "x-api-key": process.env.REACT_APP_PB_X_API_KEY,
                authority: "api.passbase.com",
              },
              body: JSON.stringify({
                captcha_token: [...Array(80)]
                  .map((i) => (~~(Math.random() * 36)).toString(36))
                  .join(""),
                redirection_attributes: {
                  email: email,
                },
                type: "sms",
                value: "+" + phoneNum,
              }),
            };
            fetch(`${this.sendSmsUrl}`, options).then(
              async (response) => {
                if (response.ok) {
                const data = await response.json();
                  resolve(data.reference_token);
                }
                if(!response.ok){
                    resolve(401)
                }
              }
            );
          });
        } catch (e) {}
      }

      CheckPassbaseVerification = (token) => {
        try {
          return new Promise((resolve, reject) => {
        fetch(
          `${this.checkVerificationURL + token}`,
          {
            method: "GET",
            headers: {
              accept: "application/json, text/plain, */*",
              "x-api-key": this.PbApiKey,
            },
          }
        ).then(async (response) => { 
          if(response.ok){
            let data = await response.json();
            resolve(data.identity_access.key);
          }else{
            resolve(401);
          }
        });
      });
    }catch(e){}
  }
          
    
}



export default PassbaseAPI;