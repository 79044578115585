import React, { useContext, useEffect } from "react";
import { CoreContext } from "../../../context/coreContext";
import fail from "./assets/fail.png";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import DatawarehouseAPI from "../../../api/datawarehouseAPI";

function CloseAccount() {
  const { t, userId } = useContext(CoreContext);
  let DatawarehouseApi = new DatawarehouseAPI();

  let navigator = useHistory();

  function CloseAccount() {
    try {
      return new Promise(async (resolve, reject) => {
        let result = await DatawarehouseApi.SuspendUser(userId);
        if (result !== 401) {
          resolve(200);
          Cookies.remove("token");
          navigator.push("/login");
        } else {
          resolve(401);
        }
      });
    } catch (e) {

    }
  }

  useEffect(() => {
    Cookies.remove("token");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex flex-col h-screen items-center justify-center">
      <div className="bg-white shadow-lg items-center justify-center flex flex-col dark:bg-eloop-blackish rounded-md p-4">
        <img src={fail} className="mb-8 p-3  rounded-md   w-80" alt="img" />
        <div className="text-sm whitespace-pre-line text-center w-80 mb-5 dark:text-white">
          {t("Login.203")}
          <br />
          {t("Login.204")} <br />
        </div>
        <div
          onClick={() => CloseAccount()}
          className="dark:bg-eloop-darkGray cursor-pointer select-none justify-center flex items-center dark:text-white w-auto text-white px-4 py-2 bg-eloop-purple shadow-lg rounded-full"
        >
          {t("Login.202")}
        </div>
        <div
              onClick={() => {
                navigator.push("/profile");
              }}
              className="h-9  px-7 p-3 mb-2 text-center  w-auto text-sm rounded-full mt-4 bg-eloop-green cursor-pointer flex items-center justify-center  text-eloop-blackish font-medium"
            >
              {t("Login.190")}
            </div>
      </div>
    </div>
  );
}

export default CloseAccount;
