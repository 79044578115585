import React, { useContext, useState } from "react";
import { CoreContext } from "../../context/coreContext";
import { FrontOfficeContext } from "../../context/frontofficeContext";
import SectionText from "../text/sectionText";

function ChangePassword() {
  const { t } = useContext(CoreContext);
  const {
    newPassword,
    setNewPassword,
    passChangeStatus,
    setPassChangeStatus,
    passError,
    ChangePassword,
  } = useContext(FrontOfficeContext);
  const [showPass, setShowPass] = useState(false);
  return (
    <>
      <div className=" w-full items-start rounded-2xl  h-auto dark:bg-eloop-blackish shadow-lg bg-white mt-5 mb-5 md:mb-4 pb-2 flex flex-col">
        <SectionText label={t("Login.128")} />
        <div className="flex ml-2 flex-col items-center md:items-start">
          <div className=" flex">
           <div className="flex w-auto px-2 bg-gray-100 h-auto items-center mb-2 rounded-full justify-center">
           <input
              placeholder={t("Login.83")}
              onChange={(e) => {
                setPassChangeStatus("");
                setNewPassword(e.target.value);
              }}
              type={showPass ? "text" : "password"}
              className="text-sm md:w-auto w-40 dark:text-white font-bold text-center border-none focus:ring-0 focus:border-gray-200  h-10 appearance-none dark:bg-eloop-mediumGray  bg-gray-100  rounded-full  outline-none password"
            />
            <div
              onClick={() => {
                if (newPassword) setShowPass(!showPass);
              }}
              className="items-center  dark:bg-eloop-mediumGray select-none cursor-pointer justify-center p-2 px-3  rounded-full flex text-sm bg-gray-100 h-10 ml-2"
            >
              {showPass ? "🙈" : "👀"}
            </div>
           </div>
            <div

              onClick={() => newPassword.length >= 7 &&
                newPassword.toLowerCase() !== newPassword && ChangePassword()}
              className={
                newPassword.length >= 7 &&
                newPassword.toLowerCase() !== newPassword &&
                /\d/.test(newPassword)
                  ? "p-5 flex select-none items-center cursor-pointer bg-eloop-green ml-2  h-9 font-bold text-eloop-blackish rounded-full text-sm"
                  : "p-5 flex select-none items-center bg-gray-100 dark:bg-eloop-mediumGray dark:text-eloop-blackish font-bold  ml-2 h-9 text-gray-900 rounded-full text-sm"
              }
            >
              {passChangeStatus === "loading" && t("Login.65")}
              {passChangeStatus === "success" && t("Login.77")}
              {!passChangeStatus && t("Login.78")}
              {passChangeStatus === "failed" && t("Login.79")}
            </div>
          </div>
          {passChangeStatus === "failed" ? (
            <div className="text-xs text-red-600">
              {passError === "same" && t("Login.143")}
            </div>
          ) : (
            <div className="flex flex-row md:ml-7">
              <div
                className={
                  newPassword.length >= 7
                    ? "select-none text-xs text-eloop-green font-semibold"
                    : "select-none text-xs dark:text-white font-semibold"
                }
              >
                {t("Login.80")}
              </div>
              <div className="text-xs mr-1 ml-1 dark:text-white ">|</div>
              <div
                className={
                  newPassword.toLowerCase() !== newPassword
                    ? "select-none text-xs text-eloop-green font-semibold"
                    : "select-none text-xs dark:text-white font-semibold"
                }
              >
                {t("Login.81")}
              </div>
              <div className="text-xs mr-1 ml-1 dark:text-white ">|</div>
              <div
                className={
                  /\d/.test(newPassword)
                    ? "select-none text-xs text-eloop-green font-semibold"
                    : "select-none text-xs dark:text-white font-semibold"
                }
              >
                {t("Login.82")}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
