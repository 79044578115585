import React, { useContext } from "react";
import { CoreContext } from "../../context/coreContext";
import StatusText from "../text/statusText";
import DetailText from "../text/detailText";
import SectionText from "../text/sectionText";
import { useHistory } from "react-router-dom";
import config from "../../config/config";

function ReviewStatus() {
  let navigator = useHistory();
  const {
    t,
    profileStatus,
    userPlan,
    age,
    profileType,
    businessStatus,
    planExpDate,
    refreshToken,
    cashback,
    fromApp,
  } = useContext(CoreContext);

  async function CompleteProfile() {
    window.location.replace(
      `${config.signupUrl}?token=${refreshToken}&apps=${fromApp}${
        profileStatus === "DOC_MISSING" && "&step=kyc"
      }`
    );
  }

  return (
    <>
      <div className="w-full items-start rounded-2xl h-auto dark:bg-eloop-blackish  bg-white shadow-lg pb-2 flex flex-col">
        <SectionText label={t("Login.16")} />
        {profileStatus === "SERVICE_REG_REJECTED" && age < 23 ? (
          <div className="mb-2 flex flex-row justify-between w-full items-center">
            <StatusText label="" status="REJECTED" />
            <div
              onClick={() => navigator.push("/youngster")}
              className="mr-6 cursor-pointer text-sm h-8 text-center w-auto rounded-full p-3 bg-eloop-green text-eloop-blackish font-semibold flex items-center justify-center"
            >
              {t("Login.209")}
            </div>
          </div>
        ) : (
          <div className="mb-2 flex flex-row justify-between w-full items-center">
            <StatusText
              label=""
              status={profileType ? profileStatus : businessStatus}
            />
            {(profileStatus === "INCOMPLETE" ||
              profileStatus === "DOC_MISSING") && (
              <div
                onClick={() => CompleteProfile()}
                className="mr-6 cursor-pointer  text-sm h-8 text-center w-auto rounded-full p-3 bg-eloop-green text-eloop-blackish font-bold flex items-center justify-center"
              >
                {t("Login.173").toUpperCase()}
              </div>
            )}
          </div>
        )}

        <div className="mt-2 40  flex w-full flex-row justify-between items-center">
          <DetailText
            label={t("Login.126")}
            info={
              planExpDate
                ? userPlan + " | " + t("Login.68") + ": " + planExpDate
                : userPlan
            }
          />
          {/* disabled - request from marketing */}
          {/* {age >= 25 && profileStatus === "APPROVED" && (
            <div
              onClick={() => navigator.push("/upgrade")}
              className=" mr-2 select-none mb-2 px-1 py-0 h-9 w-56 text-sm text-right grid place-content-center font-semibold rounded-full mt-1 bg-eloop-green cursor-pointer   text-eloop-blackish"
            >
              {t("upgrade.28")}
            </div>
          )} */}
        </div>

        <div>
          <DetailText label={"EOT Cashback"} info={cashback} />
        </div>
      </div>
    </>
  );
}

export default ReviewStatus;
