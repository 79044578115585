import React, { useContext } from "react";
import { CoreContext } from "../../context/coreContext";
import logo from "../../img/fo-icon.png";

// import { FaSun, FaMoon } from "react-icons/fa";
import lightLogo from "../../img/fo-icon-light.png";
import { useHistory } from "react-router-dom";
import { FrontOfficeContext } from "../../context/frontofficeContext";
import Cookies from "js-cookie";

function DesktopNavbar() {
  let navigator = useHistory();
  const { FOcurrentPage } = useContext(FrontOfficeContext);

  const {
    theme,
    profileStatus,
    loggedIn,
    registering,

    t,
  } = useContext(CoreContext);
  return (
    <>
      <div className="fixed h-20 z-10 w-screen self-start select-none text-white border-b-2 border-gray-100 dark:bg-eloop-darkGray  bg-white items-center flex flex-row justify-between">
        <div className="ml-6">
          <img
            onClick={() => loggedIn && navigator.push("/profile")}
            src={theme === "dark" ? lightLogo : logo}
            className={
              loggedIn
                ? "opacity-0 md:opacity-100 md:h-20 object-contain cursor-pointer"
                : "opacity-0 md:opacity-100 md:h-20 object-contain"
            }
            alt="logo"
          />
        </div>
        <div className="flex flex-row justify-around w-auto text-sm mr-6 text-gray-900 font-bold">
          {loggedIn ? (
            <div className="flex flex-row">
              {profileStatus === "APPROVED" ? (
                <div className="flex flex-row">
                  <NavbarButton
                    isActive={FOcurrentPage === "profile"}
                    navigateTo={"/profile"}
                    label={t("Login.11")}
                  />
                  <NavbarButton
                    isActive={FOcurrentPage === "credits"}
                    navigateTo={"/credits"}
                    label={t("Login.13")}
                  />

                  {/* <NavbarButton
                 isActive={FOcurrentPage === "store"}
                 navigateTo={"/store"}
                 label={"SHOP"}
               /> */}
                  <NavbarButton
                    isActive={FOcurrentPage === "invoice"}
                    navigateTo={"/invoice"}
                    label={t("Login.12").toUpperCase()}
                  />

                  <a
                    href="https://eloop.app/de/hilfe/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="p-2 cursor-pointer  dark:text-white">
                      FAQs{" "}
                    </div>
                  </a>
                </div>
              ) : (
                <div className="flex flex-row">
                  <NavbarButton
                    isActive={FOcurrentPage === "profile"}
                    navigateTo={"/profile"}
                    label={t("Login.11")}
                  />

                  <a
                    href="https://eloop.app/de/hilfe/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="p-2 cursor-pointer  dark:text-white">
                      FAQs{" "}
                    </div>
                  </a>
                </div>
              )}

              <LogoutButton />
            </div>
          ) : (
            <div className="flex flex-row">
              <div className="flex">
                <a
                  href="https://eloop.app/de/hilfe/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="pt-2 pl-2  cursor-pointer  dark:text-white">
                    FAQs
                  </div>
                </a>
              </div>
              {registering ? (
                <LogoutButton />
              ) : (
                <NavbarButton
                  isActive={false}
                  navigateTo={"/registration"}
                  label={t("Login.7")}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function NavbarButton({ isActive, navigateTo, label }) {
  let navigator = useHistory();
  return (
    <>
      <div
        className={
          isActive
            ? "bg-eloop-purple font-bold text-white px-6 transition-all duration-200  rounded-full h-auto cursor-pointer"
            : ""
        }
      >
        <div
          onClick={() => {
            navigateTo === "/registration"
              ? window.open("https://signup.eloop.app", "_blank")
              : navigator.push(navigateTo);
          }}
          className={
            isActive ? "p-2 font-bold" : "p-2 cursor-pointer text-gray-900 "
          }
        >
          {label}
        </div>
      </div>
    </>
  );
}

function LogoutButton() {
  const { t, Logout, loggedIn } = useContext(CoreContext);
  const { FrontOfficeLogout } = useContext(FrontOfficeContext);
  let navigator = useHistory();
  return (
    <>
      <div
        onClick={() => {
          Logout();
          FrontOfficeLogout();
          Cookies.remove("token");
          navigator.push("/login");
        }}
        className={"p-2 mr-2 cursor-pointer dark:text-white"}
      >
        {loggedIn ? t("Login.14") : t("Login.2").toUpperCase()}
        {}
      </div>
    </>
  );
}

export default DesktopNavbar;
