import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import MobileNavbar from "../../components/header/mobileNavbar";
import DesktopNavbar from "../../components/header/desktopNavbar";
import { CoreContext } from "../../context/coreContext";
import VulogAPI from "../../api/vulogAPI";
import { useHistory, useLocation } from "react-router-dom";

export const ResetPassword = () => {
  const VulogApi = new VulogAPI();
  const [password1, setPassword1] = useState("");
  const [error, setError] = useState("");
  const [reset, setReset] = useState(false);
  let navigator = useHistory();
  let token = new URLSearchParams(useLocation().search).get("token");
  if (!token) {
    navigator.push("/forgot-password");
  }

  async function SendResetLink() {
    setError(false);
    const bearer = await VulogApi.GetPasswordResetToken();
    if (bearer) {
      let status = await VulogApi.ResetPassword(bearer, password1, token);
      if (status === 200) {
        setReset(true);
        setPassword1("");
      } else {
        setError(true);
      }
    }
  }
  const { setRegistering, t } = useContext(CoreContext);
  useEffect(() => {
    setRegistering(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [type, setType] = useState("password");
  return (
    <div>
      {isMobile ? <MobileNavbar /> : <DesktopNavbar />}
      <div className="h-screen  flex flex-col  items-center justify-center">
        <div className="bg-gray-50 shadow-sm transition-all  dark:bg-eloop-blackish rounded-lg items-center m-4 md:w-96  py-7 dark:text-white  flex flex-col justify-center px-5">
          {!reset && (
            <div className="flex flex-col items-center">
              <h1 className="font-medium mb-2">{t("Registration.40")}</h1>
              <div className="flex  flex-row items-center pr-4 pl-8 w-auto mb-3  justify-between  border-gray-100 border-2 h-10 rounded-full">
                <input
                  type={type}
                  value={password1}
                  onChange={(e) => {
                    setPassword1(e.target.value);
                  }}
                  placeholder={t("Login.8")}
                  className="text-center font-dm-sans pl-5 bg-transparent border-transparent focus:border-transparent focus:ring-0 focus:outline-none "
                />
                <div
                  onClick={() => {
                    type === "password" ? setType("text") : setType("password");
                  }}
                  className="font-dm-sans cursor-pointer  text-sm text-gray-500"
                >
                  {type === "text"
                    ? t("Registration.43")
                    : t("Registration.42")}
                </div>
              </div>

              <div className="items-center mb-2 text-center text-xs flex-wrap text-eloop-red">
                <span
                  className={password1.length >= 7 ? "text-eloop-green" : ""}
                >
                  {t("Login.80")}
                </span>
                <span
                  className={
                    password1.toLowerCase() !== password1
                      ? "text-eloop-green"
                      : ""
                  }
                >
                  {" "}
                  {t("Login.81")}
                </span>

                <div className={/\d/.test(password1) ? "text-eloop-green" : ""}>
                  {" "}
                  {t("Login.82")}
                </div>
              </div>
            </div>
          )}

          {reset && (
            <div className="text-eloop-blackish mb-3 font-semibold">
              {t("Registration.44")}
            </div>
          )}
          <div
            onClick={() => {
              if (reset) {
                navigator.push("/login");
              } else {
                password1 && SendResetLink();
              }
            }}
            className="text-md mb-2 mt-2 font-bold text-center cursor-pointer bg-eloop-purple flex items-center justify-center dark:text-gray-900 text-white border-none h-9 w-40 appearance-none focus:ring-0  rounded-full  outline-none"
          >
            {reset ? t("Login.2") : t("Registration.41")}
          </div>
          {error && (
            <div className="text-eloop-red text-sm font-medium">
              {t("Registration.45")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
