import React, { useContext, useState, useEffect } from "react";
import { TiLeaf } from "react-icons/ti";
import { BsCheckLg } from "react-icons/bs";
import { AiOutlineRocket } from "react-icons/ai";
import config from "../../../config/config";
import { BsLightningCharge } from "react-icons/bs";
import { CoreContext } from "../../../context/coreContext";
import { useHistory } from "react-router-dom";
import { FrontOfficeContext } from "../../../context/frontofficeContext";
import DatawarehouseAPI from "../../../api/datawarehouseAPI";

export const Upgrade = () => {
  let navigator = useHistory();
  const [planType, setPlanType] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [planId, setPlanId] = useState("");
  const [selectedPlan, setSelectedPlan] = useState();
  const [planPrice, setPlanPrice] = useState("");
  const [havePromoCode, setHavePromoCode] = useState(false);
  const [validPromoCode, setValidPromoCode] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [error, setError] = useState("");
  // const [promotionMemberships, setPromotionMemberships] = useState();
  const [eloopProMonthly, setEloopProMonthly] = useState(
    config.plans.pro.monthly
  );
  const [eloopProYearly, setEloopProYearly] = useState(config.plans.pro.yearly);
  const [eloopPlusMonthly, setEloopPlusMonthly] = useState(
    config.plans.plus.monthly
  );
  const [eloopPlusYearly, setEloopPlusYearly] = useState(
    config.plans.plus.yearly
  );

  const { setFOCurrentPage } = useContext(FrontOfficeContext);
  useEffect(() => {
    navigator.push("/profile");
    // setFOCurrentPage("upgrade");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { refreshToken, profileId, apiKey, userPlan, t, userId } =
    useContext(CoreContext);
  async function UpgradeToPremium() {
    return;
    return new Promise(async (resolve, reject) => {
      var myHeaders = new Headers();
      myHeaders.append("X-API-Key", apiKey);
      myHeaders.append("Authorization", "Bearer ".concat(refreshToken));
      const options = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          planId: planId,
          profileId: profileId,
        }),
      };

      fetch(
        `${process.env.REACT_APP_VULOG_BASE_URL}/apiv5/users/subscriptions`,
        options
      )
        .then(async (response) => {
          if (response.status === 201) {
            await datawarehouseApi.useMembershipPromoCode(userId, promoCode);
            resolve(response.ok);
          } else if (!response.ok) {
            resolve(!response.ok);
          }
        })
        .catch((error) => {});
      navigator.push("/profile");
    });
  }
  const datawarehouseApi = new DatawarehouseAPI();
  async function applyPromoCode() {
    setError("");
    if (promoCode === "") return;
    const response = await datawarehouseApi.GetPromoMembership(promoCode);
    if (response) {
      if (response.msg === "Found promotional memberships.") {
        // if there promotional memberships, update the state
        if (response.memberships.length > 0) {
          response.memberships.forEach((membership) => {
            if (membership.name.includes("Plus")) {
              if (membership.name.includes("yearly")) {
                setEloopPlusYearly({
                  price: membership.price.toString(),
                  id: membership.id,
                });
              } else {
                setEloopPlusMonthly({
                  price: membership.price.toString(),
                  id: membership.id,
                });
              }
            } else {
              if (membership.name.includes("yearly")) {
                setEloopProYearly({
                  price: membership.price.toString(),
                  id: membership.id,
                });
              } else {
                setEloopProMonthly({
                  price: membership.price.toString(),
                  id: membership.id,
                });
              }
            }
          });
        }
        setValidPromoCode(true);
      } else {
        setError(response.msg);
        setValidPromoCode(false);
      }
    }
  }

  return (
    <div className="h-auto gap-5  flex flex-col pt-20 pb-2 items-center justify-center">
      {openPopup && (
        <Popup
          popupFunc={() => UpgradeToPremium()}
          popupPrice={planPrice}
          setPopupOpen={setOpenPopup}
          planType={selectedPlan}
          popupText={
            selectedPlan === "Plus" ? t("upgrade.18") : t("upgrade.21")
          }
          yearly={planType}
        />
      )}
      <h2 className="text-2xl -mb-4 mt-4 font-bold">{t("upgrade.1")}</h2>
      <h3 className="text-sm text-gray-400 ">{t("upgrade.2")}</h3>
      <h2
        onClick={() => {
          setHavePromoCode(!havePromoCode);
        }}
        className="text-xs cursor-pointer text-gray-600"
      >
        {t("upgrade.iHavePromoCode")}
      </h2>
      {havePromoCode && (
        <div className="flex flex-row gap-2">
          <input
            placeholder="Code"
            type="text"
            className="h-8 w-40 rounded-md border-2 text-sm border-eloop-lightGray focus:ring-0 focus:outline-none px-2"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
          />
          <div
            onClick={() => {
              if (promoCode !== "") {
                applyPromoCode();
              }
            }}
            className="h-8 w-20 text-sm bg-eloop-purple text-white rounded-lg flex items-center justify-center cursor-pointer"
          >
            {t("Login.40")}
          </div>
        </div>
      )}
      {error !== "" && <h2 className="text-sm text-red-600  -mt-2">{error}</h2>}
      {validPromoCode === true && (
        <h2 className="text-sm text-green-600  -mt-2">Promo code applied</h2>
      )}

      <div className="rounded-md h-auto  flex  flex-row items-begin justify-center md:justify-start">
        <div
          onClick={() => {
            setPlanType(true);
          }}
          className={
            planType
              ? "cursor-pointer text-sm h-9 w-24 rounded-l-full bg-eloop-purple   text-white font-bold flex items-center justify-center"
              : "cursor-pointer text-xs h-9 w-24 rounded-l-full  bg-eloop-lightGray   text-eloop-mediumGray font-light  flex items-center justify-center"
          }
        >
          {t("upgrade.3")}
        </div>
        <div className="flex flex-col items-start">
          <div
            onClick={() => {
              setPlanType(false);
            }}
            className={
              !planType
                ? "cursor-pointer text-sm h-9 w-24 rounded-r-full    font-bold bg-eloop-purple  text-white   flex items-center justify-center"
                : "cursor-pointer text-xs h-9 w-24 rounded-r-full   font-light bg-eloop-lightGray    text-eloop-mediumGray flex items-center justify-center"
            }
          >
            {t("upgrade.4")}
          </div>
        </div>
      </div>

      <div className="flex flex-col  md:flex-row pb-10 md:py-0 justify-end   gap-7 ">
        {/* Basic plan */}
        <div className="bg-gray-100 h-auto  flex flex-col px-4   py-6 gap-6 rounded-xl w-80 md:w-72">
          <div className="flex flex-col gap-2">
            {" "}
            <div className="flex gap-2 items-center">
              {" "}
              <TiLeaf className="text-xl mb-1 text-eloop-purple" />{" "}
              <h2 className="font-semibold text-lg">Basic</h2>{" "}
            </div>{" "}
            <h2 className="text-gray-500 whitespace-pre-line  text-sm">
              {t("upgrade.7") + "\n\n"}
            </h2>{" "}
          </div>
          <div
            className={
              planType
                ? "flex items-end -mt-2  gap-1"
                : "flex mt-4 items-end gap-1"
            }
          >
            <div className="text-6xl  text-gray-800  font-black">
              <span className="text-gray-400">€</span>0
            </div>
            <div className="mb-2 text-sm">{t("Login.118")}</div>
          </div>
          <div
            onClick={() => userPlan !== "Basic" && navigator.push("/downgrade")}
            className={
              userPlan === "Basic"
                ? "bg-eloop-purple p-3 mt-2 font-bold text-center cursor-default select-none rounded-lg text-white text-sm"
                : "p-3 bg-eloop-green mt-2 cursor-pointer select-none font-bold text-center rounded-md text-gray-900 text-sm"
            }
          >
            {userPlan === "Basic" ? t("upgrade.5") : t("upgrade.6")}
          </div>
          <div className=" flex flex-col gap-2">
            <div className="flex items-start gap-2">
              <BsCheckLg className="text-eloop-green" />
              {t("upgrade.8")}
            </div>
          </div>
        </div>
        {/* plus plan */}
        <div className="bg-eloop-purple h-auto text-white flex flex-col px-4  py-6 gap-6 rounded-xl  w-80 md:w-72">
          <div className="flex flex-col gap-2">
            {" "}
            <div className="flex gap-2 items-center">
              {" "}
              <AiOutlineRocket className="text-2xl mb-1" />{" "}
              <h2 className="font-semibold text-lg">Plus</h2>{" "}
            </div>{" "}
            <h2 className="text-white whitespace-pre-line text-sm">
              {t("upgrade.9") + "\n" + t("upgrade.10")}
            </h2>{" "}
          </div>
          <div className="flex flex-col">
            <div className="flex items-end gap-1">
              <div className="text-6xl  text-white  font-black">
                <span className="text-gray-200">€</span>
                {planType
                  ? eloopPlusMonthly.price.split(".")[0]
                  : (eloopPlusYearly.price.split(".")[0] / 12).toFixed(0)}
              </div>
              <div className="flex flex-col h-full text-gray-300 pt-2 justify-end">
                <div className="mb-2 text-sm  font-bold text-white">
                  {planType
                    ? "." +
                      eloopPlusMonthly.price.split(".")[1] +
                      t("Login.118")
                    : "." +
                      (eloopPlusYearly.price / 12)
                        .toFixed(2)
                        .toString()
                        .split(".")[1] +
                      t("Login.118") +
                      "*"}
                </div>
              </div>
            </div>
            {!planType && (
              <div className="text-gray-200 mt-1 text-sm">
                {t("upgrade.33")}
              </div>
            )}
          </div>
          <div
            onClick={() => {
              setSelectedPlan("Plus");
              if (planType) {
                setPlanId(eloopPlusMonthly.id);
                setPlanPrice(eloopPlusMonthly.price);
              } else {
                setPlanId(eloopPlusYearly.id);
                setPlanPrice(eloopPlusYearly.price);
              }
              if (userPlan !== "Plus") {
                if (userPlan === "Pro") {
                  navigator.push("downgrade");
                } else {
                  setOpenPopup(true);
                }
              }
            }}
            className={
              userPlan === "Plus"
                ? "bg-white text-center cursor-default select-none rounded-md p-3 font-bold text-gray-900 text-sm"
                : "p-3 bg-eloop-green cursor-pointer select-none font-bold text-center rounded-md text-gray-900 text-sm"
            }
          >
            {userPlan === "Plus" ? t("upgrade.5") : t("upgrade.6")}
          </div>
          <div className=" flex flex-col gap-2">
            <div className="flex items-start gap-2">
              <BsCheckLg className="text-eloop-green" />
              {t("upgrade.11")}
            </div>
            <div className="flex items-start gap-2">
              <BsCheckLg className="text-eloop-green" />
              {t("upgrade.12")}
            </div>
          </div>
        </div>
        {/* pro plan */}
        <div className="bg-gray-100 h-auto   flex flex-col px-4   py-6 gap-6 rounded-xl w-80 md:w-80">
          <div className="flex flex-col gap-2">
            {" "}
            <div className="flex gap-2 items-center">
              {" "}
              <BsLightningCharge className="text-xl mb-1 text-eloop-purple " />{" "}
              <h2 className="font-semibold text-lg">Pro</h2>{" "}
            </div>{" "}
            <h2 className=" whitespace-pre-line font-bold text-sm">
              {" "}
              {t("upgrade.13") + "\n" + t("upgrade.14")}
            </h2>{" "}
          </div>
          <div className="flex flex-col">
            <div className="flex items-end gap-1">
              <div className="text-6xl   font-black">
                <span className="text-gray-400">€</span>
                {planType
                  ? eloopProMonthly.price.split(".")[0]
                  : (eloopProYearly.price.split(".")[0] / 12).toFixed(0) - 1}
              </div>
              <div className="flex flex-col h-full text-gray-500 pt-2 justify-end">
                <div className="mb-2 text-sm text-gray-900">
                  {planType
                    ? "." + eloopProMonthly.price.split(".")[1] + t("Login.118")
                    : "." +
                      (eloopProYearly.price / 12)
                        .toFixed(2)
                        .toString()
                        .split(".")[1] +
                      t("Login.118") +
                      "*"}
                </div>
              </div>
            </div>
            {!planType && (
              <div className="text-gray-500 mt-1 text-sm">
                {t("upgrade.33")}
              </div>
            )}
          </div>
          <div
            onClick={() => {
              setSelectedPlan("Pro"); //select pro or plus
              if (planType) {
                // select yearly or monthly
                setPlanId(eloopProMonthly.id);
                setPlanPrice(eloopProMonthly.price);
              } else {
                setPlanId(eloopProYearly.id);
                setPlanPrice(eloopProYearly.price);
              }
              if (userPlan !== "Pro") {
                if (userPlan === "Plus") {
                  navigator.push("/pro");
                  // setOpenPopup(true);
                } else {
                  setOpenPopup(true);
                }
              }
            }}
            className={
              userPlan === "Pro"
                ? "bg-white text-center cursor-default select-none rounded-md text-gray-40 p-3 font-bold text-sm text-eloop-darkGray"
                : "p-3 bg-eloop-green cursor-pointer select-none font-bold text-center rounded-md text-gray-900 text-sm"
            }
          >
            {userPlan === "Pro" ? t("upgrade.5") : t("upgrade.6")}
          </div>
          <div className=" flex flex-col gap-2">
            <div className="flex items-start gap-2">
              <BsCheckLg className="text-eloop-green" />
              {t("upgrade.15")}
            </div>
            <div className="flex items-start gap-2">
              <BsCheckLg className="text-eloop-green" />
              {t("upgrade.16")}
            </div>
            <div className="flex items-start gap-2">
              <BsCheckLg className="text-eloop-green" />
              {t("upgrade.17")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upgrade;

function Popup({
  popupFunc,
  popupText,
  planType,
  setPopupOpen,
  yearly,
  popupPrice,
}) {
  const { t, i18n } = useContext(CoreContext);

  return (
    <>
      <div>
        <div className="h-screen w-screen z-30 bg-gray-900 opacity-60 transition-all fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "></div>
        <div className=" h-auto w-25 md:w-auto animate-fade-in z-30  fixed shadow-2xl border-none  bg-gray-100 flex flex-col items-center justify-start rounded-xl text-gray-900 pt-6 pb-2  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="flex  flex-col items-left text-left justify-center  h-full w-full">
            <div className="w-auto px-5 p-3 text-xl font-bold whitespace-pre-line">
              {popupText}
              <br />
              <h2 className="font-medium mt-5 mb-3 text-base md:pr-4 pr-0  whitespace-pre-line text-left">
                {planType === "Plus" ? (
                  <div>
                    {!yearly
                      ? t("upgrade.plusDescription1") +
                        (popupPrice / 12).toFixed(2) +
                        t("upgrade.plusDescription2")
                      : t("upgrade.plusDescription1") +
                        popupPrice +
                        t("upgrade.plusDescription2")}
                    <span
                      className="underline cursor-pointer text-blue-500"
                      onClick={() =>
                        window.open(
                          i18n.language === "en"
                            ? "https://eloop.app/en/memberships/plus/"
                            : "https://eloop.app/de/mitgliedschaften/plus/",
                          "_blank"
                        )
                      }
                    >
                      {i18n.language === "en" ? "here." : "hier."}
                    </span>
                    {!yearly &&
                      t("upgrade.yearlyPlusAddOn") +
                        popupPrice +
                        t("upgrade.yearlyPlusAddOn2")}
                  </div>
                ) : (
                  <div>
                    {!yearly
                      ? t("upgrade.proDescription1") +
                        (popupPrice / 12).toFixed(2) +
                        t("upgrade.proDescription2")
                      : t("upgrade.proDescription1") +
                        popupPrice +
                        t("upgrade.proDescription2")}
                    <span
                      className="underline cursor-pointer text-blue-500"
                      onClick={() =>
                        window.open(
                          i18n.langugage === "en"
                            ? "https://eloop.app/en/memberships/pro/"
                            : "https://eloop.app/de/mitgliedschaften/pro/",
                          "_blank"
                        )
                      }
                    >
                      {i18n.language === "en" ? "here." : "hier."}
                    </span>
                    {!yearly &&
                      t("upgrade.yearlyProAddOn") +
                        popupPrice +
                        t("upgrade.yearlyProAddOn2")}
                  </div>
                )}
              </h2>
            </div>
            <div className=" flex mb-5 flex-row items-center justify-around w-full">
              <div
                onClick={() => {
                  setPopupOpen(false);
                }}
                className="cursor-pointer w-full  text-sm md:text-base h-10 mx-3 bg-white text-gray-800 flex items-center justify-center px-2 rounded-full font-bold"
              >
                {t("Login.109")}
              </div>
              <div
                onClick={() => {
                  setPopupOpen(false);
                  popupFunc();
                }}
                className="cursor-pointer text-center text-sm md:text-base text-md w-full mx-3 h-10 bg-eloop-green text-gray-900 flex items-center justify-center px-2 rounded-full font-bold"
              >
                {t("upgrade.20")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
