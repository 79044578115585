//importing config json from ./src/ which has all credentials to handle requests
import config from "../config/config";
import { format } from "date-fns";
import {loadStripe} from '@stripe/stripe-js';
// import credits from "../examples/credits.json";

//api class to handle requests to VULOG
class VulogAPI {
  //declaring url vars for requests
  baseURL = process.env.REACT_APP_VULOG_BASE_URL;
  loginURL = config.VULOG.endpoints.login;
  userInfoURL = config.VULOG.endpoints.getUserInfo;
  getPaymentInfoURL = config.VULOG.endpoints.getPaymentInfo;
  getPromoCreditURL = config.VULOG.endpoints.getPromoCredits;
  getUserPlansURL = config.VULOG.endpoints.getUserPlans;
  applyPromoCodeURL = config.VULOG.endpoints.applyPromoCode;
  changePasswordURL = config.VULOG.endpoints.changePassword;
  sendResetPasswordLinkURL = config.VULOG.endpoints.sendResetPasswordLink;
  registerURL = config.VULOG.endpoints.register;
  getRefreshTokenURL = config.VULOG.endpoints.getRefreshToken;
  apiKey = config.VULOG.credentials.apiKey;

  //this function returns header for requests with given token.
  MakeHeaders(refreshToken) {
    var headers = {
      Authorization: `Bearer ${refreshToken}`,
      "Content-Type": "application/json",
      "x-api-key": this.apiKey,
    };
    return headers;
  }

  //hande login request with email & password.
  LoginWithEmail(email, password) {
    try {
      return new Promise((resolve, reject) => {
        if (email && password) {
          //this request supports www-form-urlencoded body format.
          const params = new URLSearchParams();
          params.append("username", email);
          params.append("password", password);
          params.append("client_id", config.VULOG.credentials.clientID);
          params.append("client_secret", config.VULOG.credentials.clientSecret);
          params.append("grant_type", "password");
          params.append(
            "securityOptions",
            config.VULOG.credentials.securityOptions
          );
          const header = {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-API-Key": this.apiKey,
          };
          const options = {
            method: "POST",
            headers: header,
            body: params,
          };
          fetch(`${this.baseURL + this.loginURL}`, options).then(
            async (response) => {
              //check response status, resolve data
              if (response.ok) {
                const data = await response.json();
                resolve(data);
              } else {
                resolve(401);
              }
            }
          );
        } else {
          //we check if email and password exists in params, if not return 111
          resolve(401);
        }
      });
    } catch (e) {}
  }

  //hande login request with email & password.
  LoginWithEmailMunich(email, password) {
    try {
      return new Promise((resolve, reject) => {
        if (email && password) {
          //this request supports www-form-urlencoded body format.
          const params = new URLSearchParams();
          params.append("username", email);
          params.append("password", password);
          params.append("client_id", "CAROO_secure");
          params.append(
            "client_secret",
            "62ae4493-287f-422d-96e3-9197518004da"
          );
          params.append("grant_type", "password");
          params.append(
            "securityOptions",
            config.VULOG.credentials.securityOptions
          );
          const header = {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-API-Key": "3b586f6a-b009-4c79-adb0-183e53187551",
          };
          const options = {
            method: "POST",
            headers: header,
            body: params,
          };
          fetch(
            `${
              this.baseURL + "/auth/realms/CAROO/protocol/openid-connect/token"
            }`,
            options
          ).then(async (response) => {
            //check response status, resolve data
            if (response.ok) {
              const data = await response.json();
              resolve(data);
            } else {
              resolve(401);
            }
          });
        } else {
          //we check if email and password exists in params, if not return 111
          resolve(401);
        }
      });
    } catch (e) {}
  }

  //get user info with refreshToken
  GetUserInfoMunich(refreshToken) {
    try {
      return new Promise((resolve, reject) => {
        if (refreshToken) {
          const options = {
            method: "GET",
            headers: {
              Authorization: `Bearer ${refreshToken}`,
              "Content-Type": "application/json",
              "x-api-key": "3b586f6a-b009-4c79-adb0-183e53187551",
            },
          };
          fetch(`${this.baseURL + this.userInfoURL}`, options).then(
            async (response) => {
              const data = await response.json();
              if (response.ok) {
                resolve(data);
              } else {
                resolve(401);
              }
            }
          );
        } else {
          //we check if refreshToken exists in params, if not return 401
          resolve(401);
        }
      });
    } catch (e) {}
  }

  //handle Login with token
  LoginWithTokenMunich(refreshToken) {
    try {
      return new Promise((resolve, reject) => {
        if (refreshToken) {
          //this request supports www-form-urlencoded body format
          const params = new URLSearchParams();
          params.append("refresh_token", refreshToken);
          params.append("grant_type", "refresh_token");
          params.append("client_id", "CAROO_secure");
          params.append(
            "client_secret",
            "62ae4493-287f-422d-96e3-9197518004da"
          );
          params.append(
            "securityOptions",
            config.VULOG.credentials.securityOptions
          );
          const header = {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-API-Key": "3b586f6a-b009-4c79-adb0-183e53187551",
          };
          const options = {
            method: "POST",
            headers: header,
            body: params,
          };
          fetch(
            `${
              this.baseURL + "/auth/realms/CAROO/protocol/openid-connect/token"
            }`,
            options
          ).then(async (response) => {
            if (response.ok) {
              const data = await response.json();
              resolve(data);
            } else {
              resolve(401);
            }
          });
        } else {
          //we check if token exists in params, if not return 401
          resolve(401);
        }
      });
    } catch (e) {}
  }

  //handle Login with token
  LoginWithToken(refreshToken) {
    try {
      return new Promise((resolve, reject) => {
        if (refreshToken) {
          //this request supports www-form-urlencoded body format
          const params = new URLSearchParams();
          params.append("refresh_token", refreshToken);
          params.append("grant_type", "refresh_token");
          params.append("client_id", config.VULOG.credentials.clientID);
          params.append("client_secret", config.VULOG.credentials.clientSecret);
          params.append(
            "securityOptions",
            config.VULOG.credentials.securityOptions
          );
          const header = {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-API-Key": config.VULOG.credentials.apiKey,
          };
          const options = {
            method: "POST",
            headers: header,
            body: params,
          };
          fetch(`${this.baseURL + this.loginURL}`, options).then(
            async (response) => {
              if (response.ok) {
                const data = await response.json();
                resolve(data);
              } else {
                resolve(401);
              }
            }
          );
        } else {
          //we check if token exists in params, if not return 401
          resolve(401);
        }
      });
    } catch (e) {}
  }

  //  UpgradeToPremium(data) {
  //   return new Promise((resolve, reject) => {

  //     const options = {
  //       method: "POST",
  //       headers: this.MakeHeaders(data.refreshToken),
  //       body: JSON.stringify({
  //         planId: data.planId,
  //         profileId: data.profileId,
  //       }),
  //     };
  //     fetch(
  //       `${process.env.REACT_APP_VULOG_BASE_URL}/apiv5/users/subscriptions`,
  //       options
  //     )
  //       .then(async (response) => {
  //         if (response.ok) {
  //           setUpgradePremiumStatus("loading");
  //           callEvery20Second();
  //           resolve(response.ok);
  //         } else if (!response.ok) {
  //           resolve(!response.ok);
  //           setUpgradePremiumStatus("fail");
  //         }
  //       })
  //       .catch((error) => {});
  //   });
  // }

  //get user info with refreshToken
  GetUserInfo(refreshToken) {
    try {
      return new Promise((resolve, reject) => {
        if (refreshToken) {
          const header = this.MakeHeaders(refreshToken);
          const options = {
            method: "GET",
            headers: header,
          };
          fetch(`${this.baseURL + this.userInfoURL}`, options).then(
            async (response) => {
              const data = await response.json();
              if (response.ok) {
                resolve(data);
              } else {
                resolve(401);
              }
            }
          );
        } else {
          //we check if refreshToken exists in params, if not return 401
          resolve(401);
        }
      });
    } catch (e) {}
  }

  InactiveUser(refreshToken) {
    try {
      return new Promise((resolve, reject) => {
        if (refreshToken) {
          const header = this.MakeHeaders(refreshToken);
          const options = {
            method: "POST",
            headers: header,
          };
          fetch(
            `${this.baseURL + config.VULOG.endpoints.inactiveUser}`,
            options
          ).then(async (response) => {
            if (response.ok) {
              resolve(200);
            } else {
              resolve(401);
            }
          });
        } else {
          //we check if refreshToken exists in params, if not return 401
          resolve(401);
        }
      });
    } catch (e) {}
  }

  //get users payment details with token and entity id
  GetPaymentDetails(refreshToken, entityID) {
    try {
      return new Promise((resolve, reject) => {
        if (refreshToken && entityID) {
          const header = {
            Authorization: `Bearer ${refreshToken}`,
            "X-API-Key": this.apiKey,
          };
          const options = {
            method: "GET",
            headers: header,
          };
          fetch(
            `${this.baseURL + this.getPaymentInfoURL + entityID}`,
            options
          ).then(async (response) => {
            if (response.ok) {
              var data;
              try {
                data = await response.json();
              } catch (e) {
                data = 404;
              }
              resolve(data);
            } else {
              resolve(401);
            }
          });
        } else {
          //we check if refreshToken and entityID exists in params, if not return 401
          resolve(401);
        }
      });
    } catch (e) {}
  }

  //get users promo credits with token and entity id
  GetCredits(refreshToken, entityID) {
    try {
      return new Promise((resolve, reject) => {
        if (refreshToken && entityID) {
          const header = this.MakeHeaders(refreshToken);
          const options = {
            method: "GET",
            headers: header,
          };
          // setTimeout(()=>resolve(credits), 200)
          fetch(
            `${this.baseURL + this.getPromoCreditURL + entityID}`,
            
            options
          ).then(async (response) => {
            const data = await response.json();
            if (response.ok) {
              resolve(data);
            } else {
              resolve(401);
            }
          });
        } else {
          //we check if refreshToken and entityID exists in params, if not return 401
          resolve(401);
        }
      });
    } catch (e) {}
  }
  //get user plans  with token and profile id
  GetPlans(refreshToken, profileID) {
    try {
      return new Promise((resolve, reject) => {
        if (refreshToken && profileID) {
          const header = this.MakeHeaders(refreshToken);
          const options = {
            method: "GET",
            headers: header,
          };
          fetch(
            `${this.baseURL + this.getUserPlansURL + profileID}`,
            options
          ).then(async (response) => {
            const data = await response.json();
            if (response.ok) {
              resolve(data);
            } else {
              resolve(401);
            }
          });
        } else {
          //we check if refreshToken and profile id exists in params, if not return 401
          resolve(401);
        }
      });
    } catch (e) {}
  }
  //change user passwrod with given password and refresh token
  ChangePassword(newPassword, refreshToken) {
    try {
      return new Promise((resolve, reject) => {
        if (newPassword && refreshToken) {
          const header = this.MakeHeaders(refreshToken);
          const options = {
            method: "POST",
            headers: header,
            body: JSON.stringify({
              password: newPassword,
            }),
          };
          fetch(`${this.baseURL + this.changePasswordURL}`, options).then(
            async (response) => {
              if (response.ok) {
                resolve(200);
              } else {
                var data = await response.json();
                resolve(data);
              }
            }
          );
        } else {
          //we check if new password and refreshToken exists in params, if not return 401
          resolve(401);
        }
      });
    } catch (e) {}
  }

  //Accept latest TOU
  AcceptTOU(refreshToken) {
    try {
      return new Promise((resolve, reject) => {
        if (refreshToken) {
          const header = this.MakeHeaders(refreshToken);
          const options = {
            method: "POST",
            headers: header,
            body: JSON.stringify({
              cityId: config.cities.vienna.id,
            }),
          };
          fetch(`${this.baseURL + "/apiv5/termsOfUse"}`, options).then(
            async (response) => {
              if (response.ok) {
                resolve(200);
              } else {
                var data = await response.json();
                resolve(data);
              }
            }
          );
        } else {
          //we check if new password and refreshToken exists in params, if not return 401
          resolve(401);
        }
      });
    } catch (e) {}
  }

  GetPasswordResetToken() {
    try {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append(
          "client_id",
          config.VULOG.credentials.passwordResetToken.clientId
        );
        params.append(
          "client_secret",
          config.VULOG.credentials.passwordResetToken.clientSecret
        );
        params.append(
          "securityOptions",
          config.VULOG.credentials.securityOptions
        );
        params.append(
          "grant_type",
          config.VULOG.credentials.passwordResetToken.grantType
        );
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-API-Key": config.VULOG.credentials.passwordResetToken.xApiKey,
          },
          body: params,
        };
        fetch(`${this.baseURL + this.loginURL}`, options).then(
          async (response) => {
            const data = await response.json();
            if (response.ok) {
              resolve(data.access_token);
            } else {
              resolve(401);
            }
          }
        );
      });
    } catch (e) {}
  }
  SendPassResetLink(passwordResetToken, email) {
    try {
      return new Promise((resolve, reject) => {
        if (passwordResetToken) {
          const options = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${passwordResetToken}`,
              "X-API-Key": config.VULOG.credentials.passwordResetToken.xApiKey,
            },
            body: JSON.stringify({
              login: email,
            }),
          };
          fetch(
            `${this.baseURL + this.sendResetPasswordLinkURL}`,
            options
          ).then(async (response) => {
            if (response.ok) {
              resolve(200);
            } else {
              resolve(401);
            }
          });
        } else {
          //we check if refreshToken exists in params, if not return 401
          resolve(401);
        }
      });
    } catch (e) {}
  }
  ResetPassword(passwordResetToken, password, userToken) {
    try {
      return new Promise((resolve, reject) => {
        if (passwordResetToken) {
          const options = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${passwordResetToken}`,
              "X-API-Key": config.VULOG.credentials.passwordResetToken.xApiKey,
            },
            body: JSON.stringify({
              token: userToken,
              password: password,
            }),
          };
          fetch(`${this.baseURL + "/apiv5/resetLostPassword"}`, options).then(
            async (response) => {
              if (response.ok) {
                resolve(200);
              } else {
                resolve(401);
              }
            }
          );
        } else {
          //we check if refreshToken exists in params, if not return 401
          resolve(401);
        }
      });
    } catch (e) {}
  }

  ApplyPromoCode = (refreshToken, promoCode) => {
    return new Promise((resolve, reject) => {
      var myHeaders = this.MakeHeaders(refreshToken);
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        `${this.baseURL + this.applyPromoCodeURL + promoCode}`,
        requestOptions
      )
        .then((response) => {
          if (response.ok) {
            resolve(200);
          }
          if (!response.ok) {
            resolve(401);
          }
        })
        .catch((e) => {});
    });
  };

  Register = (email, password, lang) => {
    try {
      const registerData = {
        email: email,
        password: password,
        password2: password,
        fleetId: "CAROO-ATVIE",
        language: lang,
      };
      const header = this.MakeHeaders("undefined");
      return new Promise((resolve, reject) => {
        const options = {
          method: "POST",
          headers: header,
          body: JSON.stringify(registerData),
        };
        fetch(`${this.baseURL + this.registerURL}`, options).then(
          async (response) => {
            if (response.ok) {
              const data = await response.json();

              resolve(data.token);
            } else if (!response.ok) {
              resolve(401);
            }
          }
        );
      });
    } catch (e) {}
  };

  GetRefreshToken = (token, email, password) => {
    try {
      let header = this.MakeHeaders(token);
      let registerData = {
        email: email,
        password: password,
        password2: password,
      };
      return new Promise(async (resolve, reject) => {
        const options = {
          method: "POST",
          headers: header,
          body: JSON.stringify(registerData),
        };
        return fetch(`${this.baseURL + this.getRefreshTokenURL}`, options).then(
          async (response) => {
            const data = await response.json();
            resolve(data.refreshToken);
            if (!response.ok) {
              resolve(401);
            }
          }
        );
      });
    } catch (e) {}
  };

  // update user with given data
  UpdateUser(arg) {
    return new Promise((resolve, reject) => {
      var dataToUpdate;
      try {
        if (arg.type === "info") {
          dataToUpdate = {
            birthDate: format(arg.birthday, "yyyy-MM-dd"),
            firstName: arg.firstName,
            lastName: arg.lastName,
            gender: arg.gender,
            locale: arg.locale,
          };
        }
        if (arg.type === "address") {
          dataToUpdate = {
            address: {
              streetAddress: arg.streetAdress,
              locality: arg.city,
              postalCode: arg.postcode,
              country: arg.country,
            },
          };
        }
        if (arg.type === "lang") {
          dataToUpdate = {
            locale: arg.locale,
          };
        }
        let header = this.MakeHeaders(arg.refreshToken);
        const options = {
          method: "PUT",
          headers: header,
          body: JSON.stringify(dataToUpdate),
        };
        fetch(`${this.baseURL + this.userInfoURL}`, options).then(
          (response) => {
            if (response.ok) {
              resolve(200);
            } else {
              resolve(401);
            }
          }
        );
      } catch (e) {}
    });
  }
  getInvoicesWith3DS(token) {
    try {
      return new Promise((resolve, _) => {
        if (token) {
          const header = this.MakeHeaders(token);
          const options = {
            method: "GET",
            headers: header
          };
        fetch(`${this.baseURL + config.VULOG.endpoints.invoicesWith3DS}`, options).then(
            async (response) => {
              try {
                const data = await response.json();
                if (response.ok) {
                  resolve(data);
                } else {
                  resolve(401);
                }
              } catch (e) {
                resolve(401);
              }
            }
          );
        } else {
          // we check if token exists in params, if not return 401
          resolve(401);
        }
      });
    } catch (e) {
      console.error(e);
      return 401;
    }
  }
  async replayInvoicesWith3DS(token, invoicesObj) {
    try {
        if (token) {
          const header = this.MakeHeaders(token);
          const options = {
            method: "POST",
            headers: header,
            body: JSON.stringify(invoicesObj)
          };
        let response = await fetch(`${this.baseURL + config.VULOG.endpoints.replayInvoicesWith3DS}`, options);
        // console.log(response);
        let responseJson = await response.json();
        // console.log("responseJson", responseJson);
        let stripe = await loadStripe(responseJson.pspPublishableKey);
        let stripeResponse = await stripe.confirmPayment({
          clientSecret: responseJson.pspClientSecret,
          confirmParams: {
            return_url: 'https://frontoffice.eloop.app',
          },
        });
        return stripeResponse;
      }
    } catch (e) {
      console.error(e);
      return 401;
    }
  }

  
}

export default VulogAPI;
