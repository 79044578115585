import React, { useState, createContext } from "react";
import jwt_decode from "jwt-decode";

import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import config from "../config/config";
import VulogApi from "../api/vulogAPI";
import DatawarehouseApi from "../api/datawarehouseAPI";
import BackofficeAPI from "../api/backofficeAPI";

//this is for to detect if device is dark or light mode and adjust our app to that mode
const getInitialTheme = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    const userMedia = window.matchMedia("(prefers-color-scheme: dark)");
    if (userMedia.matches) {
      return "light";
    }
  }
  //if this function cannot detect systems appearence mode, set light as initial theme
  return "light";
};

export const CoreContext = createContext();

function CoreContextProvider(props) {
  //creating API objects from our API classes
  const VulogAPI = new VulogApi();
  const DatawarehouseAPI = new DatawarehouseApi();

  // page identifier for registration
  const [registerStep, setRegisterStep] = useState(0);

  // this is for profile section. to not load it every time.
  const [firstLoad, setFirstLoad] = useState(true);

  //using for app language
  const [showLang, setShowLang] = useState();
  const { t, i18n } = useTranslation();
  function handleClick(lang) {
    i18n.changeLanguage(lang);
    setShowLang(!showLang);
  }
  const [hasUnpaidInvoice, setHasUnpaidInvoice] = useState(false);
  //forChange/Add Card
  const [cardSuccess, setCardSuccess] = useState(true);
  const [cardLoading, setCardLoading] = useState("");
  const [errorReason, setErrorReason] = useState("");
  const [fleetId, setFleetId] = useState("");

  //to clear all data on logout
  function Logout() {
    Cookies.remove("fromApp");
    Cookies.remove("addCard");
    setHasUnpaidInvoice(false);
    setRegisterStep(0);
    setLoggedIn(false);
    Cookies.remove("token");
    setOpenNotif(false);
    setPopupOpen(false);
    setEmail("");
    setMissingAddress(false);
    setMissingInfo(false);
    setPassword("");
    setRefreshToken("");
    setAccessToken("");
    setFirstName("");
    setLastName("");
    setLanguage("");
    setPhoneNumber("");
    setEntityId("");
    setUserId("");
    setProfileStatus("");
    setServiceName("");
    setServiceStatus("");
    setStreet("");
    setCity("");
    setPostCode("");
    setRegion("");
    setCountry("");
    setBusinessName("");
    setHasBusiness(false);
    setBusinessStatus("");
    setBusinessServiceName("");
    setBusinessServiceStatus("");
    setBusinessEmail("");
    setBusinessPhoneNum("");
    setBusinessEntityId("");
    setBusinessProfileId("");
    setMissingCard(false);
    setAddCard(false);
    setCardSuccess("");
    setCardLoading("");
    setErrorReason("");
  }

  //for theme (dark/light modes)
  const [theme, setTheme] = React.useState(getInitialTheme);

  const rawSetTheme = (rawTheme) => {
    const root = window.document.documentElement;
    const isDark = rawTheme === "dark";

    root.classList.remove(isDark ? "light" : "dark");

    root.classList.add(rawTheme);
    localStorage.setItem("color-theme", rawTheme);
  };

  React.useEffect(() => {
    rawSetTheme(theme);
  }, [theme]);

  const [planExpDate, setPlanExpDate] = useState("");
  // for users membership status
  const [userPlan, setUserPlan] = useState();

  function GetPlans(myProfileId) {
    return new Promise(async (resolve, reject) => {
      const data = await VulogAPI.GetPlans(refreshToken, myProfileId);
      if (data.length > 0) {
        if (
          data[0].planId === config.plans.plus.yearly.id ||
          data[0].planId === config.plans.plus.monthly.id
        ) {
          setPlanExpDate(data[0].expiryDate.split("T")[0]);
          setUserPlan("Plus");
        } else if (data[0].planId === "2532f9d3-44a4-4d59-920e-c3fbc10084e7") {
          // setPlanExpDate(data[0].expiryDate.split("T")[0]);
          setUserPlan("Pro (old)");
        } else if (
          data[0].planId === config.plans.pro.monthly.id ||
          data[0].planId === config.plans.pro.yearly.id
        ) {
          setPlanExpDate(data[0].expiryDate.split("T")[0]);
          setUserPlan("Pro");
        }
      } else {
        setUserPlan("Basic");
      }
      if (data === 401) {
      }
    });
  }

  //for ecolelct payment popup
  const [showEcollectPopup, setShowEcollectPopup] = useState(false);
  const [ecollectLink, setEcollectLink] = useState("");
  const [ecollectPrice, setEcollectPrice] = useState("");

  function openEcollectPopup(ecollectUrl, price) {
    setShowEcollectPopup(true);
    setEcollectLink(ecollectUrl);
    setEcollectPrice(price);
  }

  // for Premium popup
  const [modalOpen, setModalOpen] = useState("");

  //for normal popup
  const [popupFunc, setPopupFunc] = useState(function () {});
  const [popupPrice, setPopupPrice] = useState("");
  const [popupText, setPopupText] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  function OpenPopup(func, price, text) {
    setPopupOpen(true);
    setPopupFunc(func);
    setPopupPrice(price);
    if (text) {
      setPopupText(text);
    } else {
      setPopupText("");
    }
  }

  const [missingInfo, setMissingInfo] = useState(false);
  const [missingAddress, setMissingAddress] = useState(false);
  const [firstLang, setFirstLan] = useState(true);

  //for Home adress
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostCode] = useState("");
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("");
  const [addAdress, setAddAdress] = useState(false);
  const [addName, setAddName] = useState(false);
  const [dlStatus, setDlStatus] = useState("");
  //for business account∂
  const [hasBusiness, setHasBusiness] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [businessStatus, setBusinessStatus] = useState("");
  const [businessServiceName, setBusinessServiceName] = useState("");
  const [businessServiceStatus, setBusinessServiceStatus] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessPhoneNum, setBusinessPhoneNum] = useState("");
  const [businessEntityId, setBusinessEntityId] = useState("");
  const [businessProfileId, setBusinessProfileId] = useState("");
  const [profileType, setProfileType] = useState(true);

  //to get user info
  async function GetUserInfo(token) {
    var tokenToUse;
    if (token) {
      setAccessToken(token);
      tokenToUse = token;
    } else {
      // check if token is expired
      let checkedToken = await isJwtNotExpired(refreshToken);
      tokenToUse = checkedToken;
    }

    return new Promise(async (resolve, reject) => {
      try {
        //get user info with a function that is in vulogAPI class

        let data = await VulogAPI.GetUserInfo(tokenToUse);

        if (data === 401) {
          resolve(401);
        }
        // save user data
        getCashback(data.id);
        setUserId(data.id);
        setFirstName(data.firstName);
        setLastName(data.lastName);

        data.address.locality
          ? setCity(data.address.locality)
          : setMissingAddress(true);
        data.address.streetAddress
          ? setStreet(data.address.streetAddress)
          : setMissingAddress(true);
        data.address.country
          ? setCountry(data.address.country)
          : setMissingAddress(true);
        data.address.postalCode
          ? setPostCode(data.address.postalCode)
          : setMissingAddress(true);

        // save profile data
        let singleProfile = data.profiles.find(
          (profile) => profile.type === "Single"
        );

        setProfileId(singleProfile.id);
        setEntityId(singleProfile.entityId);
        setEmail(singleProfile.email);

        setPhoneNumber(singleProfile.phoneNumber);
        let service = singleProfile.services.find(
          (service) => service.id === config.serviceIds.vienna
        );
        setServiceName(service.name);
        setServiceStatus(service.status);
        let paymentData = await VulogAPI.GetPaymentDetails(
          refreshToken,
          singleProfile.entityId
        );

        if (singleProfile.status === "APPROVED") {
          if (
            service.status === "DOC_MISSING" ||
            service.status === "DOC_EXPIRED"
          ) {
            setProfileStatus("DOC_MISSING");
          } else if (service.status === "SERVICE_REG_SUSPENDED") {
            setProfileStatus("SERVICE_REG_SUSPENDED");
          } else if (service.status === "SERVICE_REG_REJECTED") {
            setProfileStatus("SERVICE_REG_REJECTED");
          } else if (service.status === "DOC_PENDING_REVIEW") {
            setProfileStatus("PENDING");
          } else if (service.status === "TOU_UNACCEPTED") {
            setProfileStatus("TOU_UNACCEPTED");
          } else {
            setProfileStatus(service.status);
          }
        } else if (singleProfile.status === "PENDING") {
          setProfileStatus("PENDING");
        } else if (singleProfile.status === "SUSPENDED") {
          setProfileStatus("SERVICE_REG_SUSPENDED");
        } else if (singleProfile.status === "OUTSTANDING_BALANCE") {
          if (paymentData.mopStatus === "MOP_INVALID") {
            setProfileStatus("INCOMPLETE");
          } else {
            setProfileStatus("OUTSTANDING_BALANCE");
          }
        } else if (
          singleProfile.status === "MOP_EXPIRED" ||
          singleProfile.status === "MOP_MISSING" ||
          singleProfile.status === "MOP_UPDATE_FAILED" ||
          singleProfile.status === "MOP_INVALID" ||
          singleProfile.status === "MOP_UPDATING"
        ) {
          setProfileStatus("INCOMPLETE");
        } else {
          setProfileStatus(singleProfile.status);
        }
        // save business profile info if exists
        let businessProfile = data.profiles.find(
          (profile) => profile.type === "Business"
        );

        if (businessProfile) {
          setHasBusiness(true);
          setBusinessEntityId(businessProfile.entityId);
          setBusinessEmail(businessProfile.email);
          setBusinessName(businessProfile.entityName);
          setBusinessStatus(businessProfile.status);
          let service = businessProfile.services.find(
            (service) => service.name === "Vienna"
          );
          setBusinessServiceName(service.name);
          setBusinessServiceStatus(service.status);
        }

        // save language and set website lang to users lang
        if (data.locale === "en_GB") {
          if (firstLang) {
            i18n.changeLanguage("en");
            setFirstLan(false);
          }
          setLanguage("English");
        } else {
          if (firstLang) {
            i18n.changeLanguage("de");
            setFirstLan(false);
          }
          setLanguage("Deutsch");
        }

        //check user info via datawarehouse api
        let userData = await CheckUserInfo(data.id);

        setDlStatus(userData.dlStatus);

        //data to return from function
        let returnData = {
          paymentStatus: paymentData.mopStatus,
          profileStatus: singleProfile.status,
          dlStatus: userData.dlStatus,
          kycStatus: userData.kycStatus,
          userId: data.id,
          entityId: singleProfile.entityId,
          profileId: singleProfile.id,
          age: userData.age,
          serviceStatus: service.status,
        };

        // get users membership info
        GetPlans(singleProfile.id);

        //return user info
        resolve(returnData);
      } catch (e) {
        console.log(e);
      }
    });
  }

  //to get payment details
  const [missingCard, setMissingCard] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [addCard, setAddCard] = useState(false);
  const [cashback, setCashback] = useState("0 €");

  let backofficeApi = new BackofficeAPI();

  function getCashback(userId) {
    try {
      new Promise(async (resolve, reject) => {
        const data = await backofficeApi.CalculateCashback(userId);
        if (data) {
          setCashback(data.cashback + " €");
          resolve(200);
        } else {
          resolve(500);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  function GetPaymentDetails(myEntityId) {
    new Promise(async (resolve, reject) => {
      const data = await VulogAPI.GetPaymentDetails(refreshToken, myEntityId);
      if (data.mopStatus === "MOP_VALID") {
        resolve(data.mopStatus);
        setMissingCard(false);
        if (data.paymentMethod === "CREDIT_CARD") {
          setPaymentMethod("Credit Card");
        } else {
          setPaymentMethod(data.paymentMethod);
        }
        setCardHolderName(data.holderName);
        setCardNumber("**** **** **** " + data.cardSummary);
        setExpiryDate(data.expiryMonth + "/" + data.expiryYear);
      } else {
        resolve(data.mopStatus);
      }
      if (data === 404) {
        setMissingCard(true);
      }
    }).catch((e) => {
      console.log(e);
    });
  }

  const [premiumPrice, setPremiumPrice] = useState("");

  function GetPremiumPrice() {
    try {
      new Promise(async (resolve, reject) => {
        const data = await DatawarehouseAPI.GetPremiumPrice();
        setPremiumPrice(data.product.price);
        resolve(data.product.price);
      });
    } catch (e) {
      console.log(e);
    }
  }

  //user info for log in
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [wrongInfo, setWrongInfo] = useState(false);
  const [refreshToken, setRefreshToken] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const apiKey = config.VULOG.credentials.apiKey;
  const [clickedLogin, setClickedLogin] = useState(false);

  // to save Personal details
  const [firstName, setFirstName] = useState("");
  const [profileId, setProfileId] = useState("");
  const [lastName, setLastName] = useState("");
  const [language, setLanguage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [entityId, setEntityId] = useState("");
  const [userId, setUserId] = useState("");
  const [profileStatus, setProfileStatus] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [serviceStatus, setServiceStatus] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [whiteListed, setWhiteListed] = useState(false);

  async function Login(token, emailToken) {
    setClickedLogin(true);
    setWrongInfo(false);
    return new Promise(async (resolve, reject) => {
      if (token) {
        let data;
        if (emailToken === "CAROO-DEMUC") {
          data = await VulogAPI.LoginWithTokenMunich(token);
        } else {
          data = await VulogAPI.LoginWithToken(token);
        }

        if (data !== 401) {
          setRefreshToken(data.refresh_token);
          setAccessToken(data.access_token);
          setLoggedIn(true);
          resolve(data.refresh_token);
          setRegisterStep(1);
        } else {
          Cookies.remove("token");
          resolve(401);
        }
      } else {
        let data;
        if (emailToken === "CAROO-DEMUC") {
          setWhiteListed(true);
          data = await VulogAPI.LoginWithEmailMunich(email, password);
        } else {
          data = await VulogAPI.LoginWithEmail(email, password);
        }
        if (data === 401) {
          setWrongInfo(true);
          setClickedLogin(false);
          resolve(401);
        } else {
          setLoggedIn(true);
          setRefreshToken(data.refresh_token);
          setAccessToken(data.access_token);
          resolve(data.refresh_token);
          setRegisterStep(1);
        }
      }
    });
  }

  const [missingKYC, setMissingKYC] = useState(true);
  const [under25, setUnder25] = useState(false);
  const [kycStatus, setKycStatus] = useState("");
  const [age, setAge] = useState("");

  function CheckUserInfo(userId) {
    return new Promise(async (resolve, reject) => {
      const data = await DatawarehouseAPI.CheckUserStatus(userId);
      setAge(data.age);
      if (data.driversLicense === true) {
        setMissingKYC(false);
      }
      if (data.openBalance === true) {
        setHasUnpaidInvoice(true);
      }
      if (data.age === null) {
        setMissingInfo(true);
      } else {
        if (data.age < 25 && data.age > 22) {
          setUnder25(true);
        } else {
          setUnder25(false);
        }
        if (data.paymentMethod === true) {
          setMissingCard(false);
        }
        if (data.profileData === false) {
          setMissingInfo(true);
        }
      }

      if (data.dlStatus === "VALID") {
        setKycStatus("approved");
      } else {
        setKycStatus("undone");
      }
      resolve(data);
      if (data === 401) {
        resolve(401);
      }
    });
  }

  const [fromApp, setFromApp] = useState(false);

  // check if token is expired or not
  function isJwtNotExpired(token) {
    return new Promise(async (resolve, reject) => {
      let tokenDecoded;
      try {
        tokenDecoded = await jwt_decode(token);
        if (tokenDecoded.exp > Date.now() / 1000) {
          // not expired, return same token
          resolve(token);
        } else {
          // token is expired, get new token with refreshToken and return it
          var newTokenFromRefresh = await Login(refreshToken);
          resolve(newTokenFromRefresh);
        }
      } catch (e) {
        console.log(e);
      }
    });
  }

  const [justLoggedIn, setJustLoggedIn] = useState(true);
  const [openNotif, setOpenNotif] = useState(false);

  //for calculator dropdown
  const [minOpen, setMinOpen] = useState(false);
  const [hoursOpen, setHoursOpen] = useState(false);
  const [daysOpen, setDaysOpen] = useState(false);

  //using for calculator
  const [userPlanToCalc, setUserPlanToCalc] = useState("Basic");
  const [minutes, setMinutes] = useState(16);
  const [hours, setHours] = useState(13);
  const [days, setDays] = useState(0);
  const [model, setModel] = useState("Model 3");
  const [eot, setEot] = useState(1000);
  const [calcLoading, setCalcLoading] = useState(false);
  const [calculatedData, setCalculatedData] = useState({});
  const [kmToCalculate, setKmToCalculate] = useState(100);
  // const [allPlanCalculations, setAllPlanCalculations] = useState({});

  function toFixed2Obj(obj) {
    for (let key in obj) {
      if (typeof obj[key] === "number") {
        obj[key] = obj[key].toFixed(2);
      }
    }
    return obj;
  }

  function changeDotsToComma(obj) {
    for (let key in obj) {
      obj[key] = obj[key].toString().replace(".", ",");
    }
    return obj;
  }

  function planFinder(plan, plans) {
    return plans.find(
      (element) => element.billingGroup.toLowerCase() === plan.toLowerCase()
    );
  }

  async function CalculateCost(options) {
    setCalcLoading(true);
    setCalculatedData({});
    try {
      return await new Promise(async (resolve, reject) => {
        // uncomment this after we include cashbackAmount in every plan in datawarehouse
        // if (
        //   options?.plan &&
        //   allPlanCalculations?.pricePerBillingGroup &&
        //   !options?.days &&
        //   !options?.hours &&
        //   !options?.minutes &&
        //   !options?.km
        // ) {
        //   let planData = planFinder(
        //     options.plan,
        //     allPlanCalculations.pricePerBillingGroup
        //   );

        //   planData.finalCost = planData.total - planData.discount;
        //   planData.cashback = allPlanCalculations.cashback;
        //   planData = toFixed2Obj(planData);
        //   planData.cashbackPercentage = allPlanCalculations.cashbackPercentage;

        //   if (i18n.language === "de") {
        //     planData = changeDotsToComma(planData);
        //   }

        //   setCalculatedData(planData);
        //   setCalcLoading(false);
        //   resolve(planData);
        //   return;
        // }

        const planToCalculate = options?.plan || userPlanToCalc;

        const data = await DatawarehouseAPI.CalculateCost(
          options?.days || days,
          options?.hours || hours,
          options?.minutes || minutes,
          options?.km || kmToCalculate,
          planToCalculate,
          options?.eot || eot,
          options?.modelType || model,
          userId
        );

        // setAllPlanCalculations(data);
        let planData = planFinder(planToCalculate, data.pricePerBillingGroup);
        planData.finalCost = planData.total - planData.discount;
        planData.cashback = data.cashback;
        planData = toFixed2Obj(planData);
        planData.cashbackPercentage = data.cashbackPercentage;

        // if language is german, replace . with ,
        if (i18n.language === "de") {
          planData = changeDotsToComma(planData);
        }

        setCalculatedData(planData);
        setCalcLoading(false);
        resolve(planData);
      });
    } catch (e) {
      setCalcLoading(false);
    }
  }

  //to detect if user is registering
  const [registering, setRegistering] = useState(false);

  const value = {
    //for calculator
    CalculateCost,
    kmToCalculate,
    setKmToCalculate,
    calculatedData,
    calcLoading,
    setCalcLoading,
    eot,
    setEot,
    model,
    setModel,
    cashback,
    planExpDate,
    setFleetId,
    fleetId,
    whiteListed,

    ecollectPrice,
    ecollectLink,
    openEcollectPopup,
    showEcollectPopup,
    setShowEcollectPopup,
    dlStatus,
    setProfileStatus,
    setUserPlan,

    setMissingAddress,

    age,
    phoneNumber,

    kycStatus,
    setAccessToken,
    setRefreshToken,
    registering,
    setRegistering,
    setLoggedIn,
    loggedIn,
    minutes,
    hours,
    days,
    setMinutes,
    setHours,
    setDays,
    setUserPlanToCalc,
    userPlanToCalc,
    missingKYC,

    setMissingCard,
    missingCard,
    theme,
    setTheme,
    addName,
    setAddName,
    setFirstName,
    setLastName,
    addAdress,
    setAddAdress,
    setStreet,
    setCity,
    setPostCode,
    setRegion,
    setCountry,
    missingInfo,
    missingAddress,
    premiumPrice,
    GetPremiumPrice,
    popupPrice,

    Logout,
    profileId,
    apiKey,
    popupFunc,
    popupText,
    popupOpen,
    setPopupOpen,
    OpenPopup,
    accessToken,
    fromApp,
    setFromApp,
    minOpen,
    setMinOpen,
    hoursOpen,
    setHoursOpen,
    daysOpen,
    setDaysOpen,
    setUnder25,
    CheckUserInfo,
    under25,
    hasUnpaidInvoice,
    userPlan,
    openNotif,
    setOpenNotif,
    GetPlans,
    modalOpen,
    setModalOpen,
    profileType,
    setProfileType,
    businessName,
    hasBusiness,
    businessStatus,
    businessServiceName,
    businessServiceStatus,
    businessEmail,
    businessPhoneNum,
    businessEntityId,
    businessProfileId,
    isJwtNotExpired,
    justLoggedIn,
    setJustLoggedIn,
    entityId,
    refreshToken,
    cardLoading,
    setCardLoading,
    errorReason,
    setErrorReason,
    cardSuccess,
    setCardSuccess,
    addCard,
    setAddCard,
    userId,
    firstLoad,
    setFirstLoad,

    showLang,
    setShowLang,
    t,
    i18n,
    handleClick,
    paymentMethod,
    cardHolderName,
    cardNumber,
    expiryDate,
    GetPaymentDetails,
    clickedLogin,
    setClickedLogin,
    profileStatus,
    serviceName,
    serviceStatus,
    lastName,
    firstName,
    language,
    street,
    city,
    postcode,
    region,
    country,
    GetUserInfo,

    email,
    setEmail,
    password,
    setPassword,
    Login,
    wrongInfo,
    registerStep,
    setRegisterStep,
    setWrongInfo,
    setPhoneNumber,
  };
  return (
    <CoreContext.Provider value={value}>{props.children}</CoreContext.Provider>
  );
}

export default CoreContextProvider;
