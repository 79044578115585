import React, { useContext } from "react";
import { CoreContext } from "../../context/coreContext";
import DetailText from "../text/detailText";
import SectionText from "../text/sectionText";
import StatusText from "../text/statusText";

function BusinessInfo() {
  const {  email } = useContext(CoreContext);

  return (
    <>
      <div className="w-full items-start gap-2 rounded-2xl mb-4 h-auto dark:bg-eloop-blackish shadow-lg   bg-white pb-2 mt-5 flex flex-col">
        <SectionText label="Business status" />
        <StatusText label="" status="APPROVED" />
        <DetailText label="Email" info={email} />
      </div>
    </>
  );
}

export default BusinessInfo;
