import React from "react"

function SectionText({label}){
    return(
        <>
        <div className="ml-4 mt-2 whitespace-pre-line  mr-4 dark:text-white mb-2 text-base text-black font-bold items-center align-middle text-left">
                {label}
              </div>
        </>
    );
}

export default SectionText;