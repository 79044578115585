import React, { useContext, useEffect } from "react";
import { isMobile } from "react-device-detect";
import MobileNavbar from "../../../components/header/mobileNavbar";
import DesktopNavbar from "../../../components/header/desktopNavbar";
import { CoreContext } from "../../../context/coreContext";
import VerifyIMG from "./assets/verify.svg";

export const Processing = () => {
  const { setRegistering, t, setLoggedIn } = useContext(CoreContext);
  useEffect(() => {
    setRegistering(true);
    setLoggedIn(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {isMobile ? <MobileNavbar /> : <DesktopNavbar />}
      <div className="h-screen  flex flex-col  items-center justify-center">
        <div className="bg-white shadow-lg dark:bg-eloop-blackish  rounded-md items-center gap-5 m-4 md:w-96 py-7 dark:text-white  flex flex-col justify-center px-3">
          <img src={VerifyIMG} className="h-60 mb-6" alt="veriftSVG" />
          <div className="font-bold text-xl mb-2 text-gray-800 dark:text-gray-300">
            {t("Registration.3")}
          </div>
          <div className="w-80 text-center text-gray-800 dark:text-gray-300">
            {t("Registration.4")}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Processing;
