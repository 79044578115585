import React, { useContext, useEffect, useState } from "react";
import { CoreContext } from "../../../context/coreContext";
import Package from "./components/package";
import { FrontOfficeContext } from "../../../context/frontofficeContext";
import { useLoading, Bars } from "@agney/react-loading";
import config from "../../../config/config";
import { buyCredit } from "./api/index";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import eloop250 from "./assets/eloop250.png";
import eloop500 from "./assets/eloop500.png";
import eloop1000 from "./assets/eloop1000.png";
import eloop2500 from "./assets/eloop2500.png";

export const CreditStore = () => {
  const { fromApp, t, profileStatus } = useContext(CoreContext);
  // const { setFOCurrentPage } = useContext(FrontOfficeContext);
  const [popupOpen, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState("");
  const [amount, setAmount] = useState("");
  const [success, setSuccess] = useState(false);
  const [productId, setProductId] = useState("");

  let imagesDesktop = [eloop250, eloop500, eloop1000, eloop2500];

  let navigator = useHistory();
  useEffect(() => {
    navigator.push("/profile");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!loading)
    return (
      <div className="flex pt-20  w-auto items-center  justify-center  dark:text-white ">
        <div className="flex w-screen  md:items-center  justify-center">
          {popupOpen && (
            <div className="w-screen fixed top-0 h-screen z-10 opacity-50  bg-gray-900 " />
          )}
          {popupOpen && (
            <CreditPopup
              amount={amount}
              popupPrice={price}
              loading={loading}
              setOpen={setPopupOpen}
              setLoading={setLoading}
              success={success}
              setSuccess={setSuccess}
              price={price}
              productId={productId}
            />
          )}

          <div
            className={
              fromApp
                ? "bg-white mt-4 h-auto w-11/12 mr-2 ml-2 p-2  rounded-xl  flex flex-col justify-start items-center "
                : "bg-white w-auto  rounded-xl flex flex-col justify-start  items-center  md:justify-start"
            }
          >
            <h2 className="mb-2 mt-2 whitespace-pre-line text-center text-2xl font-bold">
              {t("shop.3")}
            </h2>
            <h2 className="mb-4 mt-1 whitespace-pre-line text-center md:w-1/3  w-96 text-sm px-10 text-gray-600 font-thin">
              {t("shop.16")}
            </h2>
            <div className="border-xl pb-10 mt-2 w-auto md:w-auto md:flex md:flex-col justify-center md:items-center items-start self-center  ">
              <div className="w-auto items-center justify-center flex-wrap  flex md:flex-row flex-col gap-3 ">
                {isMobile
                  ? config.credits.mobile.map((credit, index) => {
                      return (
                        <>
                          <Package
                            price={credit.cost}
                            amount={credit.credits}
                            setPopupOpen={setPopupOpen}
                            setPrice={setPrice}
                            setAmount={setAmount}
                            setProductId={setProductId}
                            productId={credit.id}
                            src={imagesDesktop[index]}
                          />
                        </>
                      );
                    })
                  : config.credits.desktop.map((credit, index) => {
                      return (
                        <>
                          <Package
                            price={credit.cost}
                            amount={credit.credits}
                            setPopupOpen={setPopupOpen}
                            setPrice={setPrice}
                            setAmount={setAmount}
                            setProductId={setProductId}
                            productId={credit.id}
                            src={imagesDesktop[index]}
                          />
                        </>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

function CreditPopup({
  amount,
  price,

  setOpen,

  success,
  setSuccess,
  productId,
}) {
  const { t, userId, entityId } = useContext(CoreContext);
  const [loading, setLoading] = useState(false);
  async function BuyCredit() {
    return;
    setLoading(true);
    let status = await buyCredit(userId, entityId, productId);
    if (status === 200) {
      setSuccess(true);
      setLoading(false);
    } else {
      setLoading(false);
    }
    setLoading(false);
  }
  const { containerProps, indicatorEl } = useLoading({
    loading: loading,
    indicator: (
      <Bars width="50" className="text-eloop-blackish dark:text-white" />
    ),
  });
  return (
    <>
      <div className=" h-auto z-20 w-auto animate-fade-in  fixed shadow-2xl border-none  bg-gray-100 flex flex-col items-center justify-start rounded-xl text-gray-900 pt-6 pb-2  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        {success ? (
          <div className="flex flex-col items-center text-center justify-center  h-full w-full">
            <div className="w-80 p-2 whitespace-pre-line">
              <h2 className="font-bold text-lg">{t("shop.12")}</h2>
              <br />
              <h2 className="text-sm font-medium -mt-4">{t("shop.13")}</h2>
            </div>
            <div className=" flex flex-row items-center justify-around w-full">
              <div
                onClick={() => {
                  setSuccess(false);
                  setOpen(false);
                }}
                className="cursor-pointer w-full h-10 m-5 bg-white text-gray-800 flex items-center justify-center px-2 rounded-full font-bold"
              >
                {t("Registration.19")}
              </div>
            </div>
          </div>
        ) : loading ? (
          <div className="flex flex-col items-center text-center justify-center  h-full w-full">
            <div className="w-80 h-40 p-2 items-center flex justify-center">
              <section {...containerProps}>
                {indicatorEl} {/* renders only while loading */}
              </section>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center text-center justify-center  h-full w-full">
            <div className="w-80 p-2 px-6 whitespace-pre-line">
              <div className="font-bold">{t("shop.8")}</div>

              {`${t("shop.9")} \n${price} € + ${amount - price} ${t(
                "shop.91"
              )}  ${price} ${t("shop.92")}`}
            </div>
            <div className=" flex flex-row items-center justify-around w-full">
              <div
                onClick={() => {
                  setOpen(false);
                }}
                className="cursor-pointer w-full h-10 m-5 bg-white text-gray-800 flex items-center justify-center px-2 rounded-full font-bold"
              >
                {t("Login.109")}
              </div>
              <div
                onClick={BuyCredit}
                className="cursor-pointer w-full m-5 h-10 bg-eloop-green text-gray-900 flex items-center justify-center px-2 rounded-full font-bold"
              >
                {t("shop.7")}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CreditStore;
