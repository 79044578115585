import React, { useContext, useEffect, useState } from "react";
import { CoreContext } from "../../../context/coreContext";
import { FrontOfficeContext } from "../../../context/frontofficeContext";
import SectionText from "../../../components/text/sectionText";
import { useLoading, Bars } from "@agney/react-loading";
import { useHistory } from "react-router-dom";
import Table from "rc-table";
import shopEn from "./assets/shop-promo-en.png";
import shopDe from "./assets/shop-promo-de.png";

function CreditsPage() {
  const history = useHistory();

  const [showTable, setShowTable] = useState(false);

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: (
      <Bars width="50" className="text-eloop-blackish dark:text-white" />
    ),
  });

  const [finishedLoading, setFinishedLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const {
    credits,
    totalCredit,
    firstCodeExpire,
    GetPromoCredits,
    validPromoCode,
    setPromoCode,
    ApplyPromoCode,
    setValidPromoCode,

    setFOCurrentPage,
  } = useContext(FrontOfficeContext);

  const { t, i18n, isJwtNotExpired, accessToken, GetUserInfo } =
    useContext(CoreContext);
  async function Core() {
    setFinishedLoading(false);
    var myToken = await isJwtNotExpired(accessToken);
    var userData = await GetUserInfo(myToken);
    if (
      userData.serviceStatus === "SERVICE_REG_SUSPENDED" ||
      userData.profileStatus === "SUSPENDED"
    ) {
      history.push("/profile");
    }
    await GetPromoCredits(userData.entityId);
    setValidPromoCode(1);
    setFinishedLoading(true);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    setFOCurrentPage("credits");
    Core();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //setup credits table
  const columns = [
    {
      title: t("Login.45"),
      dataIndex: "availableAmount",
      key: "availableAmount",
      width: 100,
      className:
        "border-b-4 border-white text-xs md:text-sm   font-bold rounded-l-lg  md:p-2  bg-eloop-blackish text-white min-width-100",
    },
    {
      title: t("Login.43"),
      dataIndex: "validityEndDate",
      key: "validityEndDate",
      width: 80,
      className:
        " border-b-4 m-4 p-2 border-white text-xs md:text-sm  md:p-2  bg-eloop-blackish text-white ",
    },
    {
      title: t("credits.initalAmount"),
      dataIndex: "initialAmount",
      key: "initialAmount",
      width: 100,
      className:
        "border-b-4 p-1 border-white text-xs font-bold md:text-sm  md:p-2  bg-eloop-blackish text-white",
    },
    {
      title: t("Login.42"),
      dataIndex: "promoCodeReference",
      key: "promoCodeReference",
      width: 100,
      className:
        "border-b-4 p-1 border-white text-xs font-bold md:text-sm  md:p-2  bg-eloop-blackish text-white",
    },
    {
      title: t("Login.44"),
      dataIndex: "discountPercentage",
      key: "discountPercentage",
      width: 65,
      className:
        " border-b-4 border-white text-xs md:text-sm font-bold w-auto  rounded-r-lg   md:p-2 bg-eloop-blackish text-white ",
    },
  ];

  //if data is not fetched, show loader
  if (!finishedLoading) {
    return (
      <>
        <div className="flex items-center justify-center h-screen">
          <section {...containerProps}>
            {indicatorEl} {/* renders only while loading */}
          </section>
        </div>
      </>
    );
  } else
    return (
      <>
        <div className="flex  h-screen items-center md:items-start justify-center dark:bg-eloop-darkGray dark:text-white bg-whiet scale-0">
          <div className="h-auto flex w-screen  items-start justify-center">
            <div className=" bg-white shadow-lg dark:bg-eloop-mediumGray md:mt-24 dark:text-white w-11/12 rounded-xl flex flex-col justify-center items-center p-5">
              {/* <div className="flex gap-4 pt-2 flex-col justify-center rounded-2xl w-80 my-4 h-auto bg-white shadow-lg items-center p-4">
                <div className="ml-4 mt-2 whitespace-pre-line  mr-4 dark:text-white mb-2 text-base text-black font-bold text-center items-center align-middle">
                  {t("shop.21")}
                </div>
                <h2 className="w-5/6 -mt-3 text-sm text-center justify-center items-center flex text-md ">
                  {t("shop.20")}
                </h2>
                <img
                  src={i18n.language === "en" ? shopEn : shopDe}
                  alt="img"
                  className="w-56 object-fill -mt-10 -mb-10"
                />

                <div className="flex items-center flex-col  justify-center">
                  <div
                    onClick={() => history.push("/store")}
                    className="h-10 w-32 rounded-full ml-2 bg-eloop-purple cursor-pointer flex items-center justify-center font-bold text-white"
                  >
                    {t("shop.1")}
                  </div>
                </div>
              </div> */}
              <SectionText label={t("Login.38")} />

              <div className="flex flex-col justify-center mt-2 mb-2">
                <div className="flex flex-row">
                  <input
                    onChange={(e) => {
                      setClicked(false);
                      setPromoCode(e.target.value);
                    }}
                    className="bg-gray-200 appearance-none dark:bg-eloop-blackish dark:text-white mr-2 border-2 border-gra y-200 rounded-full w-3/6 py-2 text-center  px-4 text-gray-700 leading-tight focus:outline-none  "
                    id="inline-full-name"
                    type="text"
                    placeholder={t("Login.39")}
                  />
                  <div
                    onClick={async () => {
                      setClicked(true);
                      if (clicked === false) {
                        var result = await ApplyPromoCode();
                        setClicked(false);
                        result === "ok" && Core();
                      }
                    }}
                    className={
                      clicked
                        ? "h-10 w-32 rounded-full ml-2 bg-eloop-lightGray cursor-default flex items-center justify-center font-bold text-eloop-blackish"
                        : "h-10 w-32 rounded-full ml-2 bg-eloop-green cursor-pointer flex items-center justify-center font-bold text-eloop-blackish"
                    }
                  >
                    {t("Login.40")}
                  </div>
                </div>
              </div>

              {validPromoCode === 3 && (
                <div className="text-red-600 mb-1 text-sm font-bold">
                  {t("Login.131")}
                </div>
              )}
              {/* <div className=" self-center mt-4 mb-4 w-1 1/12 bg-gray-500 h-0.5" /> */}
              {totalCredit > 0 && (
                <div className="w-11/12 md:w-80 mb-2  shadow-lg flex rounded-md bg-eloop-lightGray h-20">
                  <div className="w-1/2 border-r-2 flex flex-col items-center justify-center border-white text-eloop-blackish">
                    <div className="text-lg font-bold">€{totalCredit}</div>
                    <div className="text-xs text-center p-0.5">
                      {t("Login.132")}
                    </div>
                  </div>
                  <div className="w-1/2  flex flex-col items-center justify-center  text-eloop-blackish">
                    <div className="text-lg font-bold">{firstCodeExpire}</div>
                    <div className="text-xs text-center p-0.5">
                      {t("Login.133")}
                    </div>
                  </div>
                </div>
              )}
              {credits.length > 0 && (
                <div className="border-xl w-11/12 md:w-4/5 flex flex-col justify-center items-center self-center  ">
                  <div
                    onClick={() => {
                      setShowTable(!showTable);
                    }}
                    className="p-2  font-bold self-center cursor-pointer place-self-center mb-2 bg-eloop-green text-eloop-blackish w-36 rounded-full items-center justify-center flex"
                  >
                    {showTable ? t("Login.135") : t("Login.134")}
                  </div>

                  <div
                    className={
                      showTable
                        ? " w-full md:w-4/5"
                        : " hidden w-11/12 md:w-4/5"
                    }
                  >
                    <Table
                      rowKey={(credits) => credits.id}
                      emptyText="No Data"
                      tableLayout="fixed"
                      className="text-center w-auto"
                      rowClassName="bg-gray-800 text-white"
                      columns={columns}
                      data={credits}
                      scroll={{ x: true, y: 300 }}
                    />
                  </div>
                </div>
              )}
              {/* <div className="w-11/12 flex-col items-center mt-1 justify-center md:w-80 mb-2  shadow-lg flex rounded-md bg-eloop-lightGray h-20">
                <div className="text-lg font-bold">{cashback + " €"}</div>
                <div className="text-xs text-center p-0.5">EOT CASHBACK</div>
                <div className="text-xs text-gray-400 mt-2">
                  What is{" "}
                  <span
                    className="underline"
                    onClick={() =>
                      window.location.replace("https://eloop.one/en/")
                    }
                  >
                    EOT CASHBACK?
                  </span>{" "}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
}

export default CreditsPage;
