import React, { useContext } from "react";
import { CoreContext } from "../../context/coreContext";
import DetailText from "../text/detailText";
import SectionText from "../text/sectionText";


function HomeAddress() {
  const {
    t,
    UpdateProfile,
    street,
    city,
    postcode,
    country,
    missingAddress,
  } = useContext(CoreContext);
  return (
    <>
      <div className="w-full items-start rounded-2xl h-auto bg-white shadow-lg dark:bg-eloop-blackish dark:text-white mt-5  pb-2 flex flex-col">
        <div className="flex w-full flex-row justify-between">
          <SectionText label={t("Login.28")} />
        </div>
        {missingAddress ? (
          <div className="flex flex-col">
          <div
              onClick={() => {
                UpdateProfile("adress");
              }}
              className=" ml-3 h-9 p-3 text-center  w-auto text-sm rounded-md bg-gray-900 cursor-pointer flex items-center justify-center  text-white"
            >
              {t("Login.106")}
            </div>
          </div>
        ) : (
          <div>
            <DetailText label={t("Login.29")} info={street} />
            <DetailText label={t("Login.30")} info={city} />
            <DetailText label={t("Login.31")} info={postcode} />
            <DetailText label={t("Login.33")} info={country} />
          </div>
        )}
      </div>
    </>
  );
}

export default HomeAddress;
