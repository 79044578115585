import React, { useContext, useState } from "react";
import { CoreContext } from "../../context/coreContext";
import DetailText from "../text/detailText";
import SectionText from "../text/sectionText";
import CopyToClipboard from "react-copy-to-clipboard";
import { QRCodeSVG } from "qrcode.react";
import DatawarehouseAPI from "../../api/datawarehouseAPI";

function BusinessInvite() {
  const [email, setEmail] = useState("");
  const { businessEntityId, businessName } = useContext(CoreContext);
  const [copied, setCopied] = useState(false);
  function handleCopy() {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  }
  let datawarehouseApi = new DatawarehouseAPI();
  const [invited, setInvited] = useState(false);
  async function inviteUser() {
    try {
      let response = await datawarehouseApi.InviteToBusiness(
        email,
        businessEntityId,
        businessName
      );
      if (response !== 400) {
        setInvited(true);
        setTimeout(() => {
          setInvited(false);
        }, 2000);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <div className="w-full items-start gap-2 rounded-2xl mb-4 h-auto dark:bg-eloop-blackish shadow-lg   bg-white pb-2 mt-5 flex flex-col">
        <SectionText label="Invite to your business" />

        <DetailText label="Invite via email" />
        <div className="w-full h-10 flex gap-2 items-center px-4">
          <input
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-3/4 text-sm lowercase focus:border-transparent focus:outline-none bg-gray-100 h-10 rounded-full truncate px-3"
          />
          <CopyToClipboard text="signup.eloop.app/business/invite">
            <button onClick={()=>inviteUser()} className="w-1/4 bg-eloop-green rounded-full h-10">
              {invited ? "Invited!" : "Invite"}
            </button>
          </CopyToClipboard>
        </div>
        <DetailText label="Copy invite link" />
        <div className="w-full h-10 flex gap-2 items-center px-4">
          <CopyToClipboard
            onCopy={() => handleCopy()}
            text={`https://signup.eloop.app/en/vienna/business/join?businessId=${businessEntityId}`}
          >
            <button className="w-full bg-eloop-green rounded-full h-10">
              {copied ? "Copied!" : "Copy invite link"}
            </button>
          </CopyToClipboard>
        </div>
        <DetailText label="QR code" />
        <div className="w-full flex gap-2 justify-center items-center px-4">
          <div className="bg-eloop-lightGray  p-2 rounded-lg ">
            <QRCodeSVG
              className="w-40 h-40"
              value={`https://signup.eloop.app.app/en/vienna/business/join?businessId=${businessEntityId}`}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessInvite;
