import React, { useContext } from "react";
import { CoreContext } from "../../context/coreContext";
import { CgMenuLeft, CgClose } from "react-icons/cg";
// import { FaSun, FaMoon } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { slide as Menu } from "react-burger-menu";
import { FrontOfficeContext } from "../../context/frontofficeContext";

function MobileNavbar() {
  const { FOcurrentPage } = useContext(FrontOfficeContext);
  const {
    theme,
    // setTheme,
    profileStatus,
    loggedIn,
    fromApp,
    t,
  } = useContext(CoreContext);

  var darkStyles = {
    bmBurgerButton: {
      position: "fixed",
      width: "25px",
      height: "25px",
      left: "25px",
      top: "20px",
    },
    bmBurgerBars: {
      background: "#373a47",
    },

    bmMenu: {
      background: "#f7f7f7",
      padding: "2.5em 0.1em",
      fontSize: "1.15em",
    },

    bmItemList: {
      outline: "none",
      color: "#111827",
      padding: "0.5rem",
    },
    bmItem: {
      outline: "none",
      fontWeight: "700",
      margin: "1rem",
      display: "flex",
    },
  };
  var styles = {
    bmBurgerButton: {
      position: "fixed",
      width: "25px",
      height: "25px",
      left: "25px",
      top: "20px",
    },
    bmBurgerBars: {
      background: "#373a47",
    },

    bmMenu: {
      background: "#f7f7f7",
      padding: "2.5em 0.1em",
      fontSize: "1.15em",
    },

    bmItemList: {
      outline: "none",
      color: "#1F2937",
      padding: "0.5rem",
    },
    bmItem: {
      outline: "none",
      fontWeight: "700",
      margin: "1rem",
      display: "flex",
    },
  };

  if (Cookies.get("fromApp") === true || fromApp) {
    return <></>;
  } else {
    return (
      <>
        {loggedIn ? (
          <div>
            <div className=" shadow-lg w-full h-16 z-20 bg-white fixed justify-end gap-2 pr-10" />
            {profileStatus === "APPROVED" ? (
              <Menu
                disableAutoFocus
                styles={theme === "dark" ? darkStyles : styles}
                width={"60%"}
                customBurgerIcon={
                  <CgMenuLeft className="text-xl stroke-black stroke-1  text-gray-800 " />
                }
                customCrossIcon={
                  <CgClose className="text-xl stroke-black stroke-1 text-gray-800  mt-2 select-none" />
                }
              >
                <NavbarButton
                  isActive={FOcurrentPage === "profile"}
                  navigateTo="/profile"
                  label={t("Login.11")}
                />
                <NavbarButton
                  isActive={FOcurrentPage === "credits"}
                  navigateTo="/credits"
                  label={t("Login.13")}
                />

                {/* <NavbarButton
                  isActive={FOcurrentPage === "store"}
                  navigateTo="/store"
                  label={"SHOP"}
                /> */}
                <NavbarButton
                  isActive={FOcurrentPage === "invoice"}
                  navigateTo="/invoice"
                  label={t("Login.12").toUpperCase()}
                />

                <NavbarButton
                  isActive={FOcurrentPage === "contact"}
                  navigateTo="/contact"
                  label={t("Login.6")}
                />
                <a
                  href="https://eloop.app/de/hilfe/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className=" -my-2 text-center w-full text-gray-900 dark:text-white font-bold cursor-pointer">
                    FAQs
                  </div>
                </a>

                <LogoutButton />
              </Menu>
            ) : (
              <Menu
                disableAutoFocus
                styles={theme === "dark" ? darkStyles : styles}
                width={"60%"}
                customBurgerIcon={
                  <CgMenuLeft className="text-xl stroke-black stroke-1  text-gray-800 " />
                }
                customCrossIcon={
                  <CgClose className="text-xl stroke-black stroke-1 text-gray-800  mt-2 select-none" />
                }
              >
                <NavbarButton
                  isActive={FOcurrentPage === "profile"}
                  navigateTo="/profile"
                  label={t("Login.11")}
                />

                <NavbarButton
                  isActive={FOcurrentPage === "contact"}
                  navigateTo="/contact"
                  label={t("Login.6")}
                />
                <a
                  href="https://eloop.app/de/hilfe/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className=" -my-2 text-center w-full text-gray-900 dark:text-white font-bold cursor-pointer">
                    FAQs
                  </div>
                </a>

                <LogoutButton />
              </Menu>
            )}

            <div className="top-4 left-16 z-20 fixed text-md font-bold text-white  bg-eloop-purple rounded-full px-5 pb-1.5 pt-1.5">
              {FOcurrentPage === "profile" && t("Login.11")}
              {FOcurrentPage === "store" && "SHOP"}
              {FOcurrentPage === "invoice" && t("Login.12")}
              {FOcurrentPage === "credits" && t("Login.13")}
              {FOcurrentPage === "deposit" && t("Login.49")}
              {FOcurrentPage === "calculator" && t("Login.100")}
              {FOcurrentPage === "contact" && t("Login.6")}
              {FOcurrentPage === "stats" && t("Login.153").toUpperCase()}
              {FOcurrentPage === "activeTrip" && t("Login.159").toUpperCase()}
              {FOcurrentPage === "referral" && t("Login.169").toUpperCase()}
              {FOcurrentPage === "upgrade" && t("Login.136").toUpperCase()}
              {FOcurrentPage === "downgrade" && "DOWNGRADE"}
              {FOcurrentPage === "rookie" && t("Login.213")}
            </div>
          </div>
        ) : (
          <div className="flex shadow-lg w-full py-4 fixed justify-end gap-2 pr-10">
            <a
              href="https://eloop.app/de/hilfe/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="p-2  text-center text-gray-900 font-bold cursor-pointer">
                FAQ
              </div>
            </a>

            <NavbarButton
              isActive={false}
              navigateTo="/registration"
              label={t("Login.7")}
            />
          </div>
        )}
      </>
    );
  }
}

function NavbarButton({ isActive, navigateTo, label }) {
  let navigator = useHistory();
  return (
    <>
      <div
        className={
          isActive
            ? "bg-eloop-purple  transition-all duration-200  rounded-full text-center h-auto cursor-pointer"
            : ""
        }
      >
        <div
          onClick={() => {
            navigateTo === "/registration"
              ? window.open("https://signup.eloop.app", "_blank")
              : navigator.push(navigateTo);
          }}
          className={
            isActive
              ? "p-2 font-bold text-white"
              : "pt-2 pb-2 pl-1 font-bold text-center dark:text-white  cursor-pointer"
          }
        >
          {label}
        </div>
      </div>
    </>
  );
}

function LogoutButton() {
  const { t, Logout, loggedIn } = useContext(CoreContext);
  const { FrontOfficeLogout } = useContext(FrontOfficeContext);
  let navigator = useHistory();
  return (
    <>
      <div
        onClick={() => {
          Logout();
          FrontOfficeLogout();
          Cookies.remove("token");
          navigator.push("/login");
        }}
        className="pt-2 pb-2 pl-1 text-center font-bold dark:text-white  cursor-pointer"
      >
        {loggedIn ? t("Login.14") : t("Login.2").toUpperCase()}
      </div>
    </>
  );
}

export default MobileNavbar;
