import React, { useContext} from "react";
import { CoreContext } from "../context/coreContext";
import Cookies from "js-cookie";

function Popup() {
  const { popupFunc, popupText,popupPrice, setPopupOpen, t } = useContext(CoreContext);
  return (
    <>
      <div className=" h-auto z-40 w-auto animate-fade-in  fixed shadow-2xl border-none  bg-gray-100 flex flex-col items-center justify-start rounded-xl text-gray-900 pt-6 pb-2  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="flex flex-col items-center text-center justify-center  h-full w-full">
          <div className="w-80 p-2 font-bold whitespace-pre-line">{popupText}<br/>{ popupPrice}{t("Login.111")}</div>
          <div className=" flex flex-row items-center justify-around w-full">
            <div
              onClick={() => {
                setPopupOpen(false);
              }}
              className="cursor-pointer w-full h-10 m-5 bg-white text-gray-800 flex items-center justify-center px-2 rounded-full font-bold"
            >
              {t("Login.109")}
            </div>
            <div
              onClick={() => { 
                setPopupOpen(false);
                popupFunc();
              }}
              className="cursor-pointer w-full m-5 h-10 bg-eloop-green text-gray-900 flex items-center justify-center px-2 rounded-full font-bold"
            >
              {t("Login.110")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



export function EcollectPopup() {
  const {  t, setShowEcollectPopup, ecollectLink, ecollectPrice} = useContext(CoreContext);
  return (
    <>
      <div className=" h-auto w-auto animate-fade-in fixed shadow-2xl border-gray-300 border-2 bg-gray-100 flex flex-col items-center justify-start rounded-full text-gray-800 pt-6 pb-2  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="flex flex-col items-center text-center justify-center  h-full w-full">
          <div className="w-80 p-2 font-bold ">Trips<br/> {t("Login.205")} { ecollectPrice} {t("Login.206")} {t("Login.207")}</div>
          <div className=" flex flex-row items-center justify-around w-full">
            <div
              onClick={() => {
                setShowEcollectPopup(false);
              }}
              className=" cursor-pointer w-full h-10 m-5 bg-white text-gray-800 flex items-center justify-center px-2 rounded-full font-bold"
            >
              {t("Login.109")}
            </div>
            <div
              onClick={() => { 
                Cookies.set("onInvoicePage", true);
                Cookies.set("fromApp", true)
                setShowEcollectPopup(false);
                window.location.replace(ecollectLink);
              }}
              className="cursor-pointer w-full m-5 h-10 bg-eloop-purple text-white flex items-center justify-center px-2 rounded-full font-bold"
            >
              {t("Login.110")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}




export default Popup;
