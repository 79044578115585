import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./App/App.css";
import App from "./App/";
import "./i18next";
import CoreContextProvider from "./context/coreContext";
import RegistrationContextProvider from "./context/registrationContext";
import FrontOfficeContextProvider from "./context/frontofficeContext";

// when needed uncomment cody below and change with  <App/> to show maintenance page to disable app
// import Unavailable from "./pages/maintenance/";

ReactDOM.render(
  // <Unavailable/>,
  <Suspense
    fallback={
      <div>
        <center>Loading...</center>
      </div>
    }
  >
    <CoreContextProvider>
      <RegistrationContextProvider>
        <FrontOfficeContextProvider>
          <App />
        </FrontOfficeContextProvider>
      </RegistrationContextProvider>
    </CoreContextProvider>
  </Suspense>,
  document.getElementById("root")
);
