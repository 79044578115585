import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { CoreContext } from "../../context/coreContext";
import SectionText from "../text/sectionText";

function OtherButtons() {
  let navigator = useHistory();
  const { t, profileStatus } = useContext(CoreContext);
  return (
    <>
      <div className=" w-full items-start rounded-2xl h-auto dark:bg-eloop-blackish  bg-white shadow-lg mt-5 mb-5 md:mb-1 pb-2 flex flex-col">
        <SectionText label={t("Login.99")} />
        <div className="flex flex-row flex-wrap pl-4 px-2 pb-2 justify-start w-full items-center">
          <div
            onClick={() => {
              navigator.push("/invoice");
            }}
            className="mr-2 h-10 px-5 md:w-40 md:h-9  w-36 text-sm text-right grid place-content-center rounded-full mt-2 bg-eloop-green cursor-pointer   text-eloop-blackish"
          >
            <div className="cursor-pointer font-semibold">{t("Login.12")}</div>
          </div>
          <div
            onClick={() => {
              navigator.push("/active");
            }}
            className="mr-2 h-10 px-5 md:w-40 md:h-9  w-36 text-sm text-right grid place-content-center rounded-full mt-2 bg-eloop-green cursor-pointer   text-eloop-blackish"
          >
            <div className="cursor-pointer font-semibold">{t("Login.159")}</div>
          </div>

          <div
            onClick={() => {
              navigator.push("/referral");
            }}
            className="mr-2 h-10 px-5 md:w-40 md:h-9 w-36 text-sm text-center grid place-content-center rounded-full mt-2 bg-eloop-green cursor-pointer   text-eloop-blackish"
          >
            <div className="cursor-pointer font-semibold">{t("Login.169")}</div>
          </div>
          <div
            onClick={() => {
              navigator.push("/stats");
            }}
            className="mr-2 h-10 px-5 md:w-40 md:h-9 w-36 text-sm text-right grid place-content-center rounded-full mt-2 bg-eloop-green cursor-pointer   text-eloop-blackish"
          >
            <div className=" cursor-pointer  font-semibold">
              {t("Login.153")}
            </div>
          </div>
          <div
            onClick={() => {
              navigator.push("/calculator");
            }}
            className="mr-2 h-10 px-5 md:w-40 md:h-9 w-36 text-sm text-center grid place-content-center rounded-full mt-2 bg-eloop-green cursor-pointer   text-eloop-blackish"
          >
            <div className=" cursor-pointer  font-semibold">
              {t("Login.97")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OtherButtons;
