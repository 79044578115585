import React, { useContext } from "react";
import { CoreContext } from "../../context/coreContext";
import DetailText from "../text/detailText";
import SectionText from "../text/sectionText";

function ProfileDetails(){

    const {
        t,
       phoneNumber,
       email,
    } = useContext(CoreContext);

    return(
        <>
            <div className="w-full items-start rounded-2xl h-auto dark:bg-eloop-blackish shadow-lg   bg-white pb-2 mt-5 flex flex-col">
                <SectionText label={t("Login.17")} />
                {phoneNumber &&
                <DetailText
                  label={t("Login.18")}
                  info={phoneNumber}
                />
}
                <DetailText label="Email" info={email} />
              </div>
        </>
    );


}

export default ProfileDetails;