import React, { useContext } from "react";
import { CoreContext } from "../../context/coreContext";

function DetailText({ label, info }) {
  const { t } = useContext(CoreContext);
  return (
    <>
      <div className="flex flex-col mb-2">
        <div className="ml-4 text-xs dark:text-white text-black font-light">{label}</div>

        {info === "Vienna" ? (
          <div className=" ml-4 text-sm dark:text-white text-black font-normal">
            {t("Login.120")}
          </div>
        ) : (
          <div className=" ml-4 text-sm dark:text-white text-black font-normal">{info}</div>
        )}
      </div>
    </>
  );
}

export default DetailText;
