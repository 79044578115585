import React, { useContext } from "react";
import { CoreContext } from "../../context/coreContext";

function PlanSelector({ calculateCost }) {
  const { userPlanToCalc, setUserPlanToCalc, model, setModel } =
    useContext(CoreContext);

  return (
    <>
      <div className="flex gap-2 w-full">
        <div className=" mb-3 w-3/4  rounded-full h-10  bg-gray-200  text-gray-600  dark:bg-gray-900 flex flex-row justify-center md:justify-start">
          <div
            onClick={() => {
              setUserPlanToCalc("Basic");
              calculateCost({
                plan: "Basic",
              });
            }}
            className={`cursor-pointer text-sm w-1/3 transition-all  rounded-full font-bold flex items-center justify-center 
            ${userPlanToCalc === "Basic" && "bg-eloop-green text-black"}`}
          >
            Basic
          </div>
          <div
            onClick={() => {
              setUserPlanToCalc("Plus");
              calculateCost({
                plan: "Plus",
              });
            }}
            className={`cursor-pointer text-sm w-1/3 transition-all  rounded-full font-bold flex items-center justify-center 
            ${userPlanToCalc === "Plus" && "bg-eloop-green text-black "}`}
          >
            Plus
          </div>
          <div
            onClick={() => {
              setUserPlanToCalc("Pro");
              calculateCost({
                plan: "Pro",
              });
            }}
            className={`cursor-pointer text-sm w-1/3 transition-all   rounded-full font-bold flex items-center justify-center 
            ${userPlanToCalc === "Pro" && "bg-eloop-green text-black "}`}
          >
            Pro
          </div>
        </div>
        <div
          onClick={() => {
            if (model === "Model 3") {
              setModel("Model Y");
              calculateCost({
                modelType: "Model Y",
              });
            } else {
              setModel("Model 3");
              calculateCost({
                modelType: "Model 3",
              });
            }
          }}
          className={`${
            model === "Model 3"
              ? "bg-eloop-green "
              : "bg-eloop-purple text-white "
          } cursor-pointer mb-3 w-1/4 transition-all text-sm grid place-content-center rounded-full h-10 font-bold`}
        >
          {model}
        </div>
      </div>
    </>
  );
}

export default PlanSelector;
