import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import MobileNavbar from "../../components/header/mobileNavbar";
import DesktopNavbar from "../../components/header/desktopNavbar";
import { CoreContext } from "../../context/coreContext";
import VulogAPI from "../../api/vulogAPI";

export const ForgotPassword = () => {
  const VulogApi = new VulogAPI();
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  async function SendResetLink() {
    const token = await VulogApi.GetPasswordResetToken();
    if (token) {
      let status = await VulogApi.SendPassResetLink(token, email);
      if (status === 200) {
        setSent(true);
        setEmail("");
      }
    }
  }
  const { setRegistering, t } = useContext(CoreContext);
  useEffect(() => {
    setRegistering(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {isMobile ? <MobileNavbar /> : <DesktopNavbar />}
      <div className="h-screen  flex flex-col  items-center justify-center">
        <div className="bg-gray-200 shadow-sm dark:bg-eloop-blackish rounded-md items-center gap-5 m-4 md:w-96 py-7 dark:text-white  flex flex-col justify-center px-3">
          <h1 className="font-bold">{t("Login.10")}</h1>
          <p className="w-10/12 text-center">{t("Registration.25")}</p>

          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("Login.189")}
            className="text-sm text-center dark:bg-eloop-mediumGray dark:text-white border-none h-10 w-10/12 appearance-none focus:ring-0 bg-white rounded-full mb-2 outline-none"
          />
          <div
            onClick={() => {
              email && SendResetLink();
            }}
            className="text-md font-bold text-center cursor-pointer bg-eloop-purple flex items-center justify-center dark:text-gray-900 text-white border-none h-10 w-40 appearance-none focus:ring-0  rounded-full  outline-none"
          >
            {t("Login.114")}
          </div>
          {sent && (
            <div className="text-eloop-purple font-semibold">
              {t("Registration.26")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
