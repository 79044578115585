import React, { useContext } from "react";
import { CoreContext } from "../../context/coreContext";

import SectionText from "../text/sectionText";

function CreateBusiness() {
  const { refreshToken } = useContext(CoreContext);

  return (
    <>
      <div className="w-full items-start gap-2 rounded-2xl mb-4 h-auto dark:bg-eloop-blackish shadow-lg   bg-white pb-2 mt-5 flex flex-col">
        <SectionText label="Create new business" />
        <div className="w-full h-10 flex gap-2 items-center px-4">
          <button
            onClick={() =>
              window.open(
                `https://signup.eloop.app/en/vienna/business?bt=${refreshToken}`,
                "_blank"
              )
            }
            className="w-full bg-eloop-green rounded-full h-10"
          >
            Create new business
          </button>
        </div>
      </div>
    </>
  );
}

export default CreateBusiness;
