import React, { useContext, useEffect, useState } from "react";
import SectionText from "../../../components/text/sectionText";
import { CoreContext } from "../../../context/coreContext";
import { useLoading, Bars } from "@agney/react-loading";
import { useHistory } from "react-router-dom";
import ProductDetails from "../../../components/billing/productDetails";
import TripDetails from "../../../components/billing/tripDetails";
import { FrontOfficeContext } from "../../../context/frontofficeContext";
import Cookies from "js-cookie";

function InvoicesPage() {
  let navigator = useHistory();

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Bars width="30" className="text-eloop-blackish mt-5 mb-5" />,
  });

  const [finishedLoading, setFinishedLoading] = useState(false);

  const { t, accessToken, isJwtNotExpired, fromApp } = useContext(CoreContext);
  const {
    GetInvoices,
    trips,
    products,
    setFOCurrentPage,
    payableTrips,
    payableProducts,
  } = useContext(FrontOfficeContext);

  let GetInvoiceInterval;

  const [type, setType] = useState("unpaid");
  async function Core() {
    let token = await isJwtNotExpired(accessToken);
    if (token !== 401) {
      let invoices = await GetInvoices(token);
      try {
        let unpaidTripInvoice = invoices.trips.find(
          (invoice) => invoice.status === "PENDING"
        );
        let unpaidProdctInvoice = invoices.products.find(
          (invoice) => invoice.status === "PENDING"
        );
        setFinishedLoading(true);
        if (unpaidProdctInvoice || unpaidTripInvoice) {
          GetInvoiceInterval = setInterval(async function () {
            let token = await isJwtNotExpired(accessToken);
            await GetInvoices(token);
          }, 15000);
        }
      } catch (e) {}
    } else {
      navigator.push("/login");
    }
  }

  // run this function when page mounted.
  useEffect(() => {
    Cookies.remove("onInvoicePage");
    setFOCurrentPage("invoice");
    Core();
    //stop invoice getting interval on page changes
    return () => clearInterval(GetInvoiceInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex h-auto w-auto pb-2 items-center  justify-center dark:bg-eloop-darkGray dark:text-white bg-white ">
        <div className="h-auto flex w-screen  items-center  justify-center">
          <div
            className={
              fromApp
                ? "bg-white shadow-lg dark:bg-eloop-mediumGray mt-10 h-auto w-11/12 mr-2 ml-2 p-2  rounded-xl  flex flex-col justify-start  items-center "
                : "bg-white shadow-lg  dark:bg-eloop-mediumGray mt-24 h-auto w-11/12 mr-2 ml-2 p-2  rounded-xl  flex flex-col justify-start  items-center "
            }
          >
            <SectionText label={t("Login.34")} />
            <div className="flex flex-row mb-2">
              <div
                onClick={() => {
                  setType("tripDetails");
                }}
                className={
                  type === "tripDetails"
                    ? "cursor-pointer  text-sm h-8 w-20 md:w-32 rounded-l-full bg-eloop-purple text-white font-bold flex items-center justify-center"
                    : "cursor-pointer text-sm h-8 w-20 md:w-32 rounded-l-full bg-eloop-lightGray text-gray-900 font-bold flex items-center justify-center"
                }
              >
                {t("Login.46")}
              </div>
              <div
                onClick={() => {
                  setType("unpaid");
                }}
                className={
                  type === "unpaid"
                    ? "cursor-pointer text-sm h-8 w-20 md:w-32 px-2   bg-eloop-purple text-white font-bold flex items-center justify-center"
                    : "cursor-pointer text-sm h-8 w-20 md:w-32 px-2  bg-eloop-lightGray text-gray-900 font-bold flex items-center justify-center"
                }
              >
                {t("Login.200")}
              </div>
              <div
                onClick={() => {
                  setType("productDetails");
                }}
                className={
                  type === "productDetails"
                    ? "cursor-pointer text-sm h-8 w-auto  md:w-32 px-2 rounded-r-full bg-eloop-purple text-white font-bold flex items-center justify-center"
                    : "cursor-pointer text-sm h-8  w-auto md:w-32 px-2 rounded-r-full bg-eloop-lightGray text-gray-900 font-bold flex items-center justify-center"
                }
              >
                {t("Login.47")}
              </div>
            </div>
            {finishedLoading ? (
              <div className="border-xl w-11/12 md:w-4/5  justify-center items-center self-center  ">
                {type === "tripDetails" && (
                  <div className="md:flex md:flex-row md:justify-around md:flex-wrap">
                    {trips.map(function (obj) {
                      return (
                        <TripDetails
                          key={obj.invoiceId}
                          invoiceStatus={obj.status}
                          date={obj.date}
                          car={obj.car}
                          duration={obj.duration}
                          cost={obj.cost}
                          distance={obj.distance}
                          viewLink={obj.viewLink}
                          id={obj.invoiceId}
                          isPayable={obj.isPayable}
                          paymentLink={obj.paymentLink}
                          needs3DSecure={obj.needs3DSecure}
                        />
                      );
                    })}
                  </div>
                )}
                {type === "productDetails" && (
                  <div className="md:flex md:flex-row md:justify-around md:flex-wrap">
                    {products.map(function (obj) {
                      return (
                        <ProductDetails
                          key={obj.invoiceId}
                          invoiceStatus={obj.status}
                          date={obj.date}
                          type={obj.name}
                          cost={obj.amount}
                          viewLink={obj.viewLink}
                          id={obj.invoiceId}
                          isPayable={obj.isPayable}
                          needs3DSecure={obj.needs3DSecure}
                        />
                      );
                    })}
                  </div>
                )}
                {type === "unpaid" && (
                  <div className="md:flex md:flex-row  md:justify-around md:flex-wrap">
                    {payableProducts.map(function (obj) {
                      return (
                        <ProductDetails
                          key={obj.invoiceId}
                          invoiceStatus={obj.status}
                          date={obj.date}
                          type={obj.name}
                          cost={obj.amount}
                          viewLink={obj.viewLink}
                          id={obj.invoiceId}
                          isPayable={obj.isPayable}
                          needs3DSecure={obj.needs3DSecure}
                        />
                      );
                    })}
                    {payableTrips.map(function (obj) {
                      return (
                        <TripDetails
                          key={obj.invoiceId}
                          invoiceStatus={obj.status}
                          date={obj.date}
                          car={obj.car}
                          duration={obj.duration}
                          cost={obj.cost}
                          distance={obj.distance}
                          viewLink={obj.viewLink}
                          id={obj.invoiceId}
                          isPayable={obj.isPayable}
                          paymentLink={obj.paymentLink}
                          needs3DSecure={obj.needs3DSecure}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center">
                <section {...containerProps}>
                  {indicatorEl} {/* renders only while loading */}
                </section>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default InvoicesPage;
