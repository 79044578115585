import React, { useContext } from "react";
import { CoreContext } from "../../context/coreContext";
import DetailText from "../text/detailText";
// import { useHistory } from "react-router-dom";
import SectionText from "../text/sectionText";
// import Cookies from "js-cookie";

function PaymentDetails() {
  // let navigator  = useHistory();
  const {
    t,
    addCard,
    paymentMethod,
    cardHolderName,
    cardNumber,
    // setAddCard,
    profileStatus,
    // setCardSuccess,
    expiryDate,
    refreshToken,
    fromApp,
    // setRegisterStep,
  } = useContext(CoreContext);
  return (
    <>
        <div className="w-full rounded-2xl h-auto items-start dark:bg-eloop-blackish  bg-white shadow-lg mb-4 pb-2 mt-6 flex flex-col">
          <div className="flex mt-2 flex-row justify-between w-full">
            <SectionText label={t("Login.19")} />
            {!addCard && (profileStatus!=="SERVICE_REG_PENDING" && profileStatus!=='SERVICE_REG_SUSPENDED' ) && (
              <div
                onClick={() => {
                  window.location.replace(`https://signup.eloop.app/en/vienna?token=${refreshToken}&updateMop=true&apps=${fromApp}`)
                
                }}
                className=" mr-2  px-1 py-0 h-9  w-52 text-sm text-right grid place-content-center font-semibold rounded-full mt-1 bg-eloop-green cursor-pointer   text-eloop-blackish"
              >
                {t("Login.50")}
              </div>
            )}
          </div>
          {paymentMethod==='Credit Card' ?
            <DetailText
              label={t("Login.20")}
              info={paymentMethod ? t("Login.127") : "No data"}
            />:
            <DetailText
              label={t("Login.20")}
              info={paymentMethod ? paymentMethod : "No data"}
            />
          }

          <DetailText
            label={t("Login.21")}
            info={cardHolderName ? cardHolderName : "No data"}
          />

          <DetailText
            label={t("Login.22")}
            info={cardNumber ? cardNumber : "No data"}
          />

          <DetailText
            label={t("Login.23")}
            info={expiryDate ? expiryDate : "No data"}
          />
        </div>

      
    </>
  );
}

export default PaymentDetails;
