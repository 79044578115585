import React, { useContext } from "react";
import { CoreContext } from "../../context/coreContext";

export function MinDropdown() {
  const { minutes, setMinutes, t } = useContext(CoreContext);

  return (
    <>
      <div className="w-full  m-1 relative inline-block text-left ">
        <div>
          <div className="flex  px-2 items-center flex-col justify-center">
            <div className="flex  w-full ">
              <div className="w-1/2 rounded-l-full shadow-md grid place-content-center ">
                {t("Login.130")}
              </div>
              <div className="w-1/2 flex items-end justify-end">
                <CustomDropdown
                  value={minutes}
                  onClick={setMinutes}
                  maxNum={59}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function HourDropdown() {
  const { hours, setHours, t } = useContext(CoreContext);

  return (
    <>
      <div className="w-full  m-1 relative inline-block text-left ">
        <div>
          <div className="flex  px-2 items-center flex-col justify-center">
            <div className="flex  w-full ">
              <div className="w-1/2 rounded-l-full shadow-md grid place-content-center ">
                {t("Login.92")}
              </div>
              <div className="w-1/2 flex items-end justify-end">
                <CustomDropdown value={hours} onClick={setHours} maxNum={23} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function DayDropdown() {
  const { days, setDays, t } = useContext(CoreContext);

  return (
    <>
      <div className="w-full  m-1 relative inline-block text-left ">
        <div>
          <div className="flex  px-2 items-center flex-col justify-center">
            <div className="flex  w-full ">
              <div className="w-1/2 rounded-l-full shadow-md grid place-content-center ">
                {t("Login.93")}
              </div>
              <div className="w-1/2 flex items-end justify-end">
                <CustomDropdown value={days} onClick={setDays} maxNum={30} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function KilometerInput() {
  const { t, kmToCalculate, setKmToCalculate } = useContext(CoreContext);
  return (
    <>
      <div className="w-full m-1 mb-4 relative inline-block text-left ">
        <div>
          <div className="flex  px-2 items-center flex-col justify-center">
            <div className="flex  w-full ">
              <div className="w-1/2 shadow-lg rounded-l-full grid place-content-center ">
                {t("calculator.kilometre")}
              </div>
              <input
                type="number"
                value={kmToCalculate}
                className="flex w-1/2 shadow-lg h-10 text-center pl-2 text-black border-none focus:outline-none focus:ring-0  items-center justify-center rounded-r-full border px-4 py-2 bg-eloop-green text-sm font-medium "
                onChange={(e) => {
                  setKmToCalculate(e.target.value);
                }}
                // if user enters a number less than 0, set km to 0 also if its empty
                onBlur={(e) => {
                  if (e.target.value < 0 || e.target.value === "") {
                    setKmToCalculate(0);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function TokenInput() {
  const { t, eot, setEot, CalculateCost } = useContext(CoreContext);
  function handleKeyDown(e) {
    if (e.key === "Enter" || e.key === "Tab") {
      console.log("enter");
      CalculateCost({
        eot: e.target.value,
      });
    }
  }

  return (
    <>
      <div className="w-full m-1 relative inline-block text-left ">
        <div>
          <div className="flex px-2 items-center flex-col justify-center">
            <div className="flex w-full ">
              <div className="w-1/2 text-sm text-center bg-eloop-darkGray text-eloop-green rounded-l-full grid place-content-center ">
                {t("calculator.eotNumber")}
              </div>
              <input
                value={eot}
                onChange={(e) => {
                  setEot(e.target.value);
                }}
                onKeyDown={handleKeyDown}
                className="flex w-1/2 h-10 text-center pl-2 text-black border-none focus:outline-none focus:ring-0  items-center justify-center rounded-r-full border px-4 py-2 bg-eloop-green text-sm font-medium "
                onBlur={(e) => {
                  CalculateCost({
                    eot: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function CustomDropdown({ value, onClick, maxNum }) {
  return (
    <select
      style={{
        textAlignLast: "center",
        textAlign: "center",
        WebkitAppearance: "none",
        MozAppearance: "none",
        appearance: "none",

        paddingRight: "1.5rem",
      }}
      value={value}
      onChange={(e) => {
        onClick(e.target.value);
      }}
      className="flex w-full focus:ring-0  text-center h-10 shadow-md  items-center justify-center  border-none  py-2 bg-eloop-green text-sm font-medium rounded-r-full"
    >
      {new Array(maxNum + 1).fill(0).map((_, i) => (
        <option key={i} value={i}>
          {i}
        </option>
      ))}
    </select>
  );
}
