const prod = {
  signupUrl: "https://signup.eloop.app/en/vienna",
  serviceIds: {
    vienna: "51538904-5f65-4f9f-bb06-7cf92751c2a8",
  },
  cities: {
    vienna: {
      id: "81cb4310-3b59-11e9-9928-06cf929d95ea",
    },
  },
  plans: {
    plus: {
      monthly: {
        price: "9.99",
        id: "3ca70c08-f5f1-4431-a159-4af477c61bb1",
      },
      yearly: {
        price: "99.99",
        id: "81df841e-5af7-49d5-acfd-8907e6f63a47",
      },
    },
    pro: {
      monthly: {
        price: "49.99",
        id: "89457e06-29b7-4eea-ab7b-8218f6ce8e66",
      },
      yearly: {
        id: "f980680a-277f-4bde-aa9c-6722703ad853",
        price: "499.99",
      },
    },
  },
  VULOG: {
    credentials: {
      apiKey: "3b586f6a-b009-4c79-adb0-183e53187551",
      clientID: "CAROO_secure",
      clientSecret: "62ae4493-287f-422d-96e3-9197518004da",
      securityOptions: "SSL_OP_NO_SSLv3",
      passwordResetToken: {
        clientId: "CAROO_anon",
        clientSecret: "6c1c866a-33cf-44b7-8a4c-5facf72ffca5",
        grantType: "client_credentials",
        xApiKey: "87aa6d57-ad1e-4f2f-8cb8-dcdcb48cffcb",
      },
    },
    endpoints: {
      login: "/auth/realms/CAROO/protocol/openid-connect/token",
      getUserInfo: "/apiv5/user",
      inactiveUser: "/apiv5/user/privacy/inactiveaccount",
      getPaymentInfo: "/apiv5/user/paymentMethodDetails/",
      getPromoCredits: "/apiv5/wallet/entity/",
      getUserPlans: "/apiv5/users/subscriptions/?profileId=",
      applyPromoCode: "/apiv5/user/promoCodes/redeem/",
      changePassword: "/apiv5/changePassword",
      sendResetPasswordLink: "/apiv5/sendLostPasswordEmail",
      register: "/registration/register",
      getRefreshToken: "/registration/get-refresh-token",
      invoicesWith3DS: "/apiv5/paymentOptions",
      replayInvoicesWith3DS: "/apiv5/user/invoices/replays",
    },
  },
  DATAWAREHOUSE: {
    endpoints: {
      checkUserStatus: "/checkUserStatus",
      checkRookieFee: "/checkRookieFee",
      getPremiumPrice: "/products",
      getInvoices: "/invoices",
      payInvoiceAgain: "/payAgain",
      getStats: "/myStats",
      getReferralStats: "/myReferralStats",
      getActiveTrip: "/activeTrip",
      calculateCost: "/calcCost",
      signUpRookie: "/rookieSignUp",
      addPhoneNum: "/addPhoneNum",
      sendOTP: "/api/registration/verify/phoneNumber",
      checkOTP: "/api/registration/verify/phoneNumberCode",
      getAdyenFormData: "/adyenFormData",
      kycStatus: "/api/registration/kycStatus",
      sumsubToken: "/api/registration/verify/token",
      applyPromo: "/applyPromoCode",
      getPromoMembership: "/memberships",
    },
  },
  PASSBASE: {
    endpoints: {
      sendSMS:
        "https://api.passbase.com/verification/v2/identity_accesses/redirect_to_mobile",
      checkVerification:
        "https://api.passbase.com/verification/v2/iy_accesses/",
    },
  },
  CONTACT: {
    email1: "support@eloop.app",
    email2: "schaden@eloop.app",
    phone: "+43 1 4350050",
  },
  credits: {
    desktop: [
      {
        name: "ELOOP 250",
        id: "0f6cc915-2d7d-49f9-8776-bf917f3eda3e",
        cost: 250,
        credits: 265,
        extraCredits: 15,
        assetName: "eloop250.png",
      },

      {
        name: "ELOOP 500",
        id: "a6bef72a-0cae-420c-a46a-c668a1babd7e",
        cost: 500,
        credits: 550,
        extraCredits: 50,
        assetName: "eloop500.png",
      },
      {
        name: "ELOOP 1000",
        id: "3e3db4e3-0d62-4445-8b55-30b07aeb5584",
        cost: 1000,
        credits: 1150,
        extraCredits: 150,
        assetName: "eloop1000.png",
      },
      {
        name: "ELOOP 2500",
        id: "9e9c10dd-ee81-493d-8e2d-cbef30da5e9a",
        cost: 2500,
        credits: 3000,
        extraCredits: 500,
        assetName: "eloop2500.png",
      },
    ],
    mobile: [
      {
        name: "ELOOP 250",
        id: "0f6cc915-2d7d-49f9-8776-bf917f3eda3e",
        cost: 250,
        credits: 265,
        extraCredits: 15,
        assetName: "eloop250.png",
      },

      {
        name: "ELOOP 500",
        id: "a6bef72a-0cae-420c-a46a-c668a1babd7e",
        cost: 500,
        credits: 550,
        extraCredits: 50,
        assetName: "eloop500.png",
      },
      {
        name: "ELOOP 1000",
        id: "3e3db4e3-0d62-4445-8b55-30b07aeb5584",
        cost: 1000,
        credits: 1150,
        extraCredits: 150,
        assetName: "eloop1000.png",
      },
      {
        name: "ELOOP 2500",
        id: "9e9c10dd-ee81-493d-8e2d-cbef30da5e9a",
        cost: 2500,
        credits: 3000,
        extraCredits: 500,
        assetName: "eloop2500.png",
      },
    ],
  },

  minAge: 21,
};

const dev = {
  signupUrl: "https://plankton-app-ggfum.ondigitalocean.app/en/vienna",
  serviceIds: {
    vienna: "a0efc0e7-5642-4f97-8304-767c536abdb1",
  },
  cities: {
    vienna: {
      id: "221821d8-38da-11e9-972e-f2f47e3e3172",
    },
  },
  plans: {
    plus: {
      monthly: {
        price: "9.99",
        id: "311db91a-72b5-43cc-943a-5be22b9aef2c",
      },
      yearly: {
        price: "99.99",
        id: "3e391424-dff4-462c-8a56-b568ae56b271",
      },
    },
    pro: {
      monthly: {
        price: "99.99",
        id: "3e63c687-b385-4f29-9804-e58deec44364",
      },
      yearly: {
        id: "cdf2c555-c5d2-443f-8f72-0b5832982c80",
        price: "499.99",
      },
    },
  },
  VULOG: {
    credentials: {
      apiKey: "23db4ae2-722c-4fde-90bc-53e73b6cdb43",
      clientID: "CAROO_secure",
      clientSecret: "e21387e5-8973-4d8e-ad69-ed5b426007b7",
      securityOptions: "SSL_OP_NO_SSLv3",
      passwordResetToken: {
        clientId: "CAROO_anon",
        clientSecret: "e21387e5-8973-4d8e-ad69-ed5b426007b7",
        grantType: "client_credentials",
        xApiKey: "23db4ae2-722c-4fde-90bc-53e73b6cdb43",
      },
    },
    endpoints: {
      login: "/auth/realms/CAROO/protocol/openid-connect/token",
      getUserInfo: "/apiv5/user",
      inactiveUser: "/apiv5/user/privacy/inactiveaccount",
      getPaymentInfo: "/apiv5/user/paymentMethodDetails/",
      getPromoCredits: "/apiv5/wallet/entity/",
      getUserPlans: "/apiv5/users/subscriptions/?profileId=",
      applyPromoCode: "/apiv5/user/promoCodes/redeem/",
      changePassword: "/apiv5/changePassword",
      sendResetPasswordLink: "/apiv5/sendLostPasswordEmail",
      register: "/registration/register",
      getRefreshToken: "/registration/get-refresh-token",
      invoicesWith3DS: "/apiv5/paymentOptions",
      replayInvoicesWith3DS: "/apiv5/user/invoices/replays",
    },
  },
  DATAWAREHOUSE: {
    endpoints: {
      checkUserStatus: "/checkUserStatus",
      checkRookieFee: "/checkRookieFee",
      getPremiumPrice: "/products",
      getInvoices: "/invoices",
      payInvoiceAgain: "/payAgain",
      getStats: "/myStats",
      getReferralStats: "/myReferralStats",
      getActiveTrip: "/activeTrip",
      calculateCost: "/calcCost",
      signUpRookie: "/rookieSignUp",
      addPhoneNum: "/addPhoneNum",
      sendOTP: "/api/registration/verify/phoneNumber",
      checkOTP: "/api/registration/verify/phoneNumberCode",
      getAdyenFormData: "/adyenFormData",
      kycStatus: "/api/registration/kycStatus",
      sumsubToken: "/api/registration/verify/token",
      applyPromo: "/applyPromoCode",
      getPromoMembership: "/memberships",
    },
  },
  PASSBASE: {
    endpoints: {
      sendSMS:
        "https://api.passbase.com/verification/v2/identity_accesses/redirect_to_mobile",
      checkVerification:
        "https://api.passbase.com/verification/v2/iy_accesses/",
    },
  },
  CONTACT: {
    email1: "support@eloop.app",
    email2: "schaden@eloop.app",
    phone: "+43 1 4350050",
  },
  credits: {
    desktop: [
      {
        name: "ELOOP 1000",
        id: "3e3db4e3-0d62-4445-8b55-30b07aeb5584",
        cost: 1000,
        credits: 1200,
        extraCredits: 200,
      },
      {
        name: "ELOOP 500",
        id: "a6bef72a-0cae-420c-a46a-c668a1babd7e",
        cost: 500,
        credits: 575,
        extraCredits: 75,
      },
      {
        name: "ELOOP 250",
        id: "0f6cc915-2d7d-49f9-8776-bf917f3eda3e",
        cost: 250,
        credits: 265,
        extraCredits: 15,
      },

      {
        name: "ELOOP 2500",
        id: "9e9c10dd-ee81-493d-8e2d-cbef30da5e9a",
        cost: 2500,
        credits: 3000,
        extraCredits: 500,
      },
    ],
    mobile: [
      {
        name: "ELOOP 1000",
        id: "3e3db4e3-0d62-4445-8b55-30b07aeb5584",
        cost: 1000,
        credits: 1200,
        extraCredits: 200,
      },
      {
        name: "ELOOP 500",
        id: "a6bef72a-0cae-420c-a46a-c668a1babd7e",
        cost: 500,
        credits: 575,
        extraCredits: 75,
      },

      {
        name: "ELOOP 250",
        id: "0f6cc915-2d7d-49f9-8776-bf917f3eda3e",
        cost: 250,
        credits: 265,
        extraCredits: 15,
      },

      {
        name: "ELOOP 2500",
        id: "9e9c10dd-ee81-493d-8e2d-cbef30da5e9a",
        cost: 2500,
        credits: 3000,
        extraCredits: 500,
      },
    ],
  },

  minAge: 21,
};

const config = {
  dev,
  prod,
};

export default config[process.env.REACT_APP_ENV];
