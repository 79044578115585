import React, { useContext, useEffect, useState } from "react";
import { CoreContext } from "../../../context/coreContext";
import ReviewStatus from "../../../components/profile/ReviewStatus";
import { useLoading, Bars } from "@agney/react-loading";
import ProfileDetails from "../../../components/profile/ProfileDetails";
import PaymentDetails from "../../../components/profile/PaymentDetails";
import PersonalDetails from "../../../components/profile/PersonalDetails";
import HomeAddress from "../../../components/profile/HomeAddress";
import ChangePassword from "../../../components/profile/ChangePassword";
import ProfileSelector from "../../../components/profile/profileSelector.js";
import Notifications from "../../../components/header/notifications.js";
import OtherButtons from "../../../components/profile/otherButtons.js";
import { FrontOfficeContext } from "../../../context/frontofficeContext";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import VulogAPI from "../../../api/vulogAPI";
import BusinessInfo from "../../../components/profile/businessInfo";
import BusinessInvite from "../../../components/profile/businessInvite";
import CreateBusiness from "../../../components/profile/createBusiness";

function ProfilePage() {
  let navigator = useHistory();
  const {
    missingCard,
    GetUserInfo,
    profileType,
    profileStatus,
    t,
    GetPaymentDetails,
    hasBusiness,
    GetPremiumPrice,
    missingAddress,
    missingInfo,
    setAddCard,
    accessToken,
    fromApp,
    email,
    refreshToken,
  } = useContext(CoreContext);
  const { setFOCurrentPage, GetInvoices } = useContext(FrontOfficeContext);

  let vulogApi = new VulogAPI();

  const [finishedLoading, setFinishedLoading] = useState(false); // show loader untill all data is ready
  let userData;
  async function Core() {
    setFinishedLoading(false); //set loader on
    userData = await GetUserInfo(refreshToken, email); // get user data
    if (userData) {
      await GetPaymentDetails(userData.entityId);
      if (userData.dlStatus === "VALID" || userData.dlStatus === "approved") {
        // get users payment details
        await GetPaymentDetails(userData.entityId);
        await GetPremiumPrice(); // get premium plan fee
        await GetInvoices();
        if (Cookies.get("onInvoicePage")) {
          navigator.push("/invoice");
        }
        setAddCard(false); // user clicks add card to add a new card, set it false as default
        setFinishedLoading(true); // finish loading and show the data
      }
      setFinishedLoading(true);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setFOCurrentPage("profile");
    Core();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: (
      <Bars width="50" className="text-eloop-blackish dark:text-white" />
    ),
  });
  if (!finishedLoading) {
    return (
      <>
        <div className="flex items-center justify-center h-screen">
          <section {...containerProps}>
            {indicatorEl} {/* renders only while loading */}
          </section>
        </div>
      </>
    );
  } else {
    if (profileStatus === "SERVICE_REG_REJECTED") {
      return (
        <>
          <div
            className={
              fromApp
                ? "flex bg-white dark:bg-eloop-darkGray h-auto justify-center pt-8"
                : "flex bg-white dark:bg-eloop-darkGray  h-auto justify-center pt-24"
            }
          >
            <div className="flex flex-row w-screen dark:bg-eloop-darkGray bg-white md:flex-nowrap flex-wrap justify-around">
              <div className="w-full z-0 pl-5 pr-5 md:pl-10  dark:bg-eloop-darkGray md:pr-2 bg-white">
                <ReviewStatus />
                <div className="w-auto font-bold items-center justify-center self-center dark:text-white text-eloop-blackish justify-self-center rounded-md  h-auto dark:bg-transparent  bg-transpaerent mt-5 mb-5 md:mb-4  flex flex-col">
                  <div
                    onClick={() => navigator.push("/close")}
                    className="dark:bg-eloop-blackish cursor-pointer select-none px-4 py-2 bg-eloop-white shadow-lg rounded-md"
                  >
                    {t("Login.202")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (profileStatus === "SUSPENDED") {
      return (
        <>
          <div
            className={
              fromApp
                ? "flex bg-white dark:bg-eloop-darkGray h-auto justify-center pt-8"
                : "flex bg-white dark:bg-eloop-darkGray  h-auto justify-center pt-24"
            }
          >
            <div className="flex flex-row w-screen dark:bg-eloop-darkGray bg-white md:flex-nowrap flex-wrap justify-around">
              <div className="w-full z-0 pl-5 pr-5 md:pl-10  dark:bg-eloop-darkGray md:pr-2 bg-white">
                <div className="w-auto font-bold items-center justify-center self-center dark:text-white text-eloop-blackish justify-self-center rounded-md  h-auto dark:bg-transparent  bg-transpaerent mt-5 mb-5 md:mb-4  flex flex-col">
                  <div
                    onClick={() => navigator.push("/close")}
                    className="dark:bg-eloop-blackish cursor-pointer select-none px-4 py-2 bg-eloop-white shadow-lg rounded-md"
                  >
                    {t("Login.202")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (profileStatus === "SERVICE_REG_SUSPENDED") {
      return (
        <>
          <div
            className={
              fromApp
                ? "flex bg-white  dark:bg-eloop-darkGray h-auto justify-center pt-8"
                : "flex bg-white  dark:bg-eloop-darkGray  h-auto justify-center pt-24"
            }
          >
            <div className="flex flex-row w-screen dark:bg-eloop-darkGray bg-white md:flex-nowrap flex-wrap justify-around">
              <div className="w-full z-0 pl-5 pr-5 md:pl-10  dark:bg-eloop-darkGray md:pr-2 bg-white">
                <Notifications />
                {hasBusiness && <ProfileSelector />}

                <ReviewStatus />
                {!missingAddress && <ProfileDetails />}
                {profileType && !missingCard && <PaymentDetails />}
              </div>
              <div className="w-full justify-center items-center place-content-center pl-5 pr-5 md:pr-10 md:pl-2  md:pt-0 pt-4 dark:bg-eloop-darkGray bg-white">
                {!missingInfo && <PersonalDetails />}
                {!missingAddress && <HomeAddress />}
                <ProfileDetails />
                <OtherButtons />
                {profileType && <ChangePassword />}

                <div className="w-auto font-bold items-center justify-center self-center dark:text-white text-eloop-blackish justify-self-center rounded-md  h-auto dark:bg-transparent  bg-transpaerent mt-5 mb-5 md:mb-4  flex flex-col">
                  <div
                    onClick={() => navigator.push("/close")}
                    className="dark:bg-eloop-blackish cursor-pointer select-none px-4 py-2 bg-eloop-white shadow-lg rounded-md"
                  >
                    {t("Login.202")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (profileStatus === "TOU_UNACCEPTED") {
      return (
        <>
          <div
            className={
              fromApp
                ? "flex bg-white  dark:bg-eloop-darkGray h-auto justify-center pt-8"
                : "flex bg-white  dark:bg-eloop-darkGray  h-auto justify-center pt-24"
            }
          >
            <div className="absolute z-40 items-center flex  flex-col   top-1/2 rounded-lg  h-auto w-80 text-center bg-white text-sm shadow-2xl p-4">
              <div className="font-bold mb-2">{t("Registration.21")}</div>
              <div className="whitespace-pre-line">
                {t("Login.210")}
                <span
                  onClick={() => {
                    window.open("https://eloop.app/de/agb/", "_blank");
                  }}
                  className="underline z-30 cursor-pointer  text-blue-600 "
                >
                  {t("Registration.205")}
                </span>
                {" " + t("Login.211")}
              </div>

              <div
                onClick={async () => {
                  let status = vulogApi.AcceptTOU(accessToken);
                  if (status === 200) {
                    setTimeout(() => Core(), 2000);
                    Core();
                  } else {
                    Core();
                  }
                }}
                className="mt-3  cursor-pointer px-16 text-sm h-8 text-center w-auto rounded-full p-3 bg-eloop-green text-gray-900 font-semibold flex items-center justify-center"
              >
                {t("Login.212")}
              </div>
            </div>
            <div className="flex flex-row filter blur-md   w-screen dark:bg-eloop-darkGray bg-white md:flex-nowrap flex-wrap justify-around">
              <div className="w-full z-0  pl-5  pr-5 md:pl-10 dark:bg-eloop-darkGray md:pr-2 bg-white">
                <Notifications />

                {hasBusiness && <ProfileSelector />}
                <ReviewStatus />
                {!missingAddress && <ProfileDetails />}
                {/* {profileType && !missingCard && <PaymentDetails />} */}
              </div>
              <div className="w-full justify-center  items-center place-content-center pl-5 pr-5 md:pr-10 md:pl-2  md:pt-0 pt-4 dark:bg-eloop-darkGray bg-white">
                {!missingInfo && <PersonalDetails />}
                {!missingAddress && <HomeAddress />}
                {missingAddress && <ProfileDetails />}
                {profileStatus === "APPROVED" && <OtherButtons />}
                {profileType && <ChangePassword />}

                <div className="w-auto font-bold items-center justify-center self-center dark:text-white text-eloop-blackish justify-self-center rounded-md  h-auto dark:bg-transparent  bg-transpaerent mt-5 mb-5 md:mb-4  flex flex-col">
                  <div
                    onClick={() => navigator.push("/close")}
                    className="dark:bg-eloop-blackish cursor-pointer select-none px-4 py-2 bg-eloop-white shadow-lg rounded-md"
                  >
                    {t("Login.202")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className={
              fromApp
                ? "flex bg-white dark:bg-eloop-darkGray h-auto justify-center pt-8"
                : "flex bg-white dark:bg-eloop-darkGray  h-auto justify-center pt-24"
            }
          >
            <div className="flex flex-row w-screen dark:bg-eloop-darkGray bg-white md:flex-nowrap flex-wrap justify-around">
              <div className="w-full z-0 pl-5 pr-5 md:pl-10  dark:bg-eloop-darkGray md:pr-2 bg-white">
                <Notifications />
                <ProfileSelector />
            
                {!hasBusiness && !profileType && <CreateBusiness />}
                {hasBusiness && !profileType && <BusinessInfo />}
                {hasBusiness && !profileType && <BusinessInvite />}
                {profileType && <ReviewStatus />}
                {!missingAddress && profileType && <ProfileDetails />}
                {profileType && !missingCard && <PaymentDetails />}
              </div>
              <div className="w-full justify-center items-center place-content-center pl-5 pr-5 md:pr-10 md:pl-2  md:pt-0 pt-4 dark:bg-eloop-darkGray bg-white">
                {!missingInfo && <PersonalDetails />}
                {!missingAddress && profileType && <HomeAddress />}
                {missingAddress && profileType && <ProfileDetails />}
                {profileStatus === "APPROVED" && <OtherButtons />}
                {profileType && <ChangePassword />}

                {profileType && (
                  <div className="w-auto font-bold items-center justify-center self-center dark:text-white text-eloop-blackish justify-self-center rounded-md  h-auto dark:bg-transparent  bg-transpaerent mt-5 mb-5 md:mb-4  flex flex-col">
                    <div
                      onClick={() => navigator.push("/close")}
                      className="dark:bg-eloop-blackish cursor-pointer select-none px-4 py-2 bg-eloop-white shadow-lg rounded-md"
                    >
                      {t("Login.202")}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default ProfilePage;
