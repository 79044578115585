import React, { useContext, useState } from "react";
// import { useHistory } from "react-router-dom";
import { CoreContext } from "../../context/coreContext";
import InvoiceInfo from "./invoiceInfo";
import { isAndroid } from "react-device-detect";
import DatawarehouseAPI from "../../api/datawarehouseAPI";
import { FrontOfficeContext } from "../../context/frontofficeContext";
import PDFViewer from "./PDFViewer";
import Cookies from "js-cookie";

function ProductDetails({
  date,
  type,
  cost,
  viewLink,
  id,
  invoiceStatus,
  isPayable,
  paymentLink,
  needs3DSecure
}) {
  const GoToEcollect = () => {
    Cookies.set("onInvoicePage", true);
    window.location.replace(paymentLink)};
  const RouteChange = async () => {
    var myToken = await isJwtNotExpired(accessToken);
    if (myToken) {
      window.open(
        `https://java-eu01.vulog.com/apiv5/invoices/${viewLink + myToken}`,
        "_blank"
      );
    }
  };
  const [clickedPay, setClickedPay] = useState(false);
  let DatawarehouseApi = new DatawarehouseAPI();
  // let navigator = useHistory();
  const { t, isJwtNotExpired, accessToken, OpenPopup, getInvoicesWith3ds, replayInvoicesWith3ds } = useContext(CoreContext);
  const { GetInvoices, showInvoice, ViewInvoice } = useContext(FrontOfficeContext);
  const [status, setStatus] = useState("notPaid");

  async function trigger3ds() {
    try {
      let token = await isJwtNotExpired(accessToken);
      if (token !== 401) {
        let invoicesWith3ds = await getInvoicesWith3ds(token);
        // console.log(invoicesWith3ds);
        if (invoicesWith3ds && invoicesWith3ds.stripe && invoicesWith3ds.stripe.v_amount_to_pay) {
          await replayInvoicesWith3ds(token, invoicesWith3ds.stripe);
          // refresh page
          window.location.reload();
        }
      }
    } catch (e) {}
  }
  function Pay(invoiceId) {
    return new Promise(async (resolve, reject) => {
      let response = await DatawarehouseApi.PayInvoiceAgain(invoiceId);
      if (response === 200) {
        GetInvoices();
        setStatus("PENDING");
        callEvery20Second();
        resolve(200);
      } else {
        setStatus("error");
        resolve(401);
      }
    });
  }

  var myInterval;
  function callEvery20Second() {
    myInterval = setInterval(async function () {
      timer += 1;
      CheckInvoice();
    }, 20000);
  }

  var timer = 0;
  if (timer > 30) {
    clearInterval(myInterval);
    setStatus("failed");
  }

  async function CheckInvoice() {
    var myToken = await isJwtNotExpired(accessToken);
    return new Promise(async (resolve, reject) => {
      const data = await DatawarehouseApi.GetInvoices(myToken);
      let invoice = data.productInvoices.find(
        (invoice) => invoice.invoice.id === id
      );

      if (invoice.invoice.billingStatus === "PAID") {
        setStatus("PAID");
        clearInterval(myInterval);
      } else if (invoice.invoice.billingStatus === "REFUSED") {
        setStatus("REFUSED");
        clearInterval(myInterval);
      } else if (invoice.invoice.billingStatus === "CANCELLED") {
        setStatus("REFUSED");
        clearInterval(myInterval);
      }
      resolve(invoice.invoice.billingStatus);
    });
  }
  function ToCall() {
    setClickedPay(true);
    Pay(id);
  }

  return (
    <div className="m-2 h-auto md:w-2/5 rounded-xl p-3 flex flex-col dark:bg-eloop-blackish  dark:text-white bg-white shadow-lg">
      {showInvoice && <PDFViewer />}
      <div className="font-bold mb-2 h-auto dark:bg-eloop-mediumGray   items-center pl-3 pr-3 pb-2 pt-2  justify-between  rounded-md bg-white flex flex-row">
        <div> {t("Login.51")}:</div>
        <div className="text-right "> {date}</div>
      </div>
      <div className=" font-medium rounded-md h-full justify-around dark:bg-eloop-mediumGray dark:text-white  bg-white flex flex-col">
        <InvoiceInfo label={t("Login.57")} info={type} />
        <InvoiceInfo label="Status" info={invoiceStatus} />
        <InvoiceInfo label={t("Login.54")} info={cost} />
        <div className="flex flex-col-reverse md:justify-between ">
          <div
            onClick={() => {
              isAndroid ? ViewInvoice(viewLink, accessToken) : RouteChange();
            }}
          >
            <div className="font-bold mb-2  cursor-pointer  mt-2 h-auto items-center pl-3 pr-3 pb-2 pt-2  justify-center  rounded-full bg-eloop-green text-eloop-blackish mr-2 ml-2 flex flex-row">
              {t("Login.56")}
              
            </div>
          </div>

          {(((isPayable || paymentLink) && (invoiceStatus !== "PENDING") && !clickedPay) || needs3DSecure ||
            status === "PENDING") && (
            <div
              onClick={
                status === "PENDING" || invoiceStatus === "PENDING"
                  ? {}
                  : () => {
                    
                      if(isPayable && !paymentLink){
                        if (needs3DSecure) {
                          trigger3ds();
                        } else {
                          OpenPopup(() => ToCall, cost, type);
                        }
                      }else{
                        GoToEcollect();
                      }
        
                    }
              }
              className={
                status === "PENDING" || invoiceStatus === "PENDING"
                  ? "font-bold  md:mb-2   mt-2 h-auto items-center pl-3 pr-3 pb-2 pt-2  justify-center   rounded-full bg-gray-500 text-white mr-2 ml-2 flex flex-row"
                  : "font-bold cursor-pointer md:mb-2   mt-2 h-auto items-center pl-3 pr-3 pb-2 pt-2  justify-center   rounded-full bg-eloop-green text-eloop-blackish mr-2 ml-2 flex flex-row"
              }
            >
              {(status === "PENDING" || invoiceStatus === "PENDING") &&
                t("Login.65")}
              {status === "error" && t("Login.72")}
              {status === "PAID" && t("Login.61")}
              {status === "REFUSED" && t("Login.73")}
              {status === "notPaid" && t("Login.74")}
              {status === "failed" && t("Login.72")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
