import React, { useContext } from "react";
import { CoreContext } from "../../context/coreContext";

function InvoiceInfo({ label, info }) {
  const { t } = useContext(CoreContext);
  return (
    <>
      <div className="flex flex-row pl-3 pr-3 pb-2 pt-2 w-full  justify-between h-auto">
        <div> {label}:</div>
        {info === "PAID" && <div className="text-start">{t("Login.186")}</div>}
        {info === "CANCELLED" &&
        <div className="text-start">{t("Login.187")}</div>
        }
        {info !== "PAID" && info !== "CANCELLED" && <div className="text-start">  {info}
        </div>}
      </div>
    </>
  );
}

export default InvoiceInfo;
