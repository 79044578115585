import React, { useContext } from "react";
import { CoreContext } from "../../context/coreContext";
import { MdVerified, MdPending, MdCancel } from "react-icons/md";

import { GoUnverified } from "react-icons/go";

function Icon({ status }) {
  switch (status) {
    case "approved":
      return <MdVerified className="text-green-400 text-lg" />;

    case "missing":
      return <GoUnverified className="text-yellow-300 text-lg" />;

    case "pending":
      return <MdPending className="text-gray-800 dark:text-white text-lg" />;
    case "rejected":
      return <MdCancel className="text-red-400 text-lg" />;
    default:
      return <MdCancel className="text-red-400 text-lg" />;
  }
}

function StatusText({ label, status }) {
  const { t } = useContext(CoreContext);
  return (
    <>
      <div className="flex flex-col">
        <div className="ml-4 text-xs dark:text-white text-black font-light">
          {label}{" "}
        </div>
        <div className="ml-4 mt-px flex flex-row items-center">
          {status === "APPROVED" && <Icon status="approved" />}
          {status === "DOC_MISSING" && <Icon status="missing" />}
          {status === "pending" && <Icon status="pending" />}
          {status === "PENDING" && <Icon status="pending" />}
          {status === "SERVICE_REG_INCOMPLETE" && <Icon status="missing" />}
          {status === "MOP_MISSING" && <Icon status="missing" />}
          {status === "SERVICE_REG_PENDING" && <Icon status="pending" />}
          {status === "ARCHIVED" && <Icon status="pending" />}
          {status === "SUSPENDED" && <Icon status="rejected" />}
          {status === "REJECTED" && <Icon status="pending" />}
          {status === "INCOMPLETE" && <Icon status="pending" />}
          {status === "ENTITY_PENDING" && <Icon status="pending" />}
          {status === "SERVICE_REG_REJECTED" && <Icon status="REJECTED" />}
          {status === "SERVICE_REG_SUSPENDED" && <Icon status="rejected" />}
          {status === "OUTSTANDING_BALANCE" && <Icon status="rejected" />}
          {status === "unpaid" && <Icon status="rejected" />}
          {status === "paid" && <Icon status="approved" />}
          {status === "undone" && <Icon status="missing" />}
          {status === "declined" && <Icon status="rejected" />}
          {status === "CANCELLED" && <Icon status="rejected" />}
          {status === "REFUSED" && <Icon status="rejected" />}
          {status === "REFUNDED" && <Icon status="rejected" />}

          {status === "approved" && <Icon status="approved" />}
          {status === "INACTIVE" && <Icon status="rejected" />}
          <div className="ml-1 text-sm dark:text-white  text-black font-normal">
            {status === "OUTSTANDING_BALANCE" && t("Login.137")}
            {status === "DOC_MISSING" && t("Login.119")}
            {status === "SERVICE_REG_REJECTED" && t("Login.124")}
            {status === "SERVICE_REG_PENDING" && "Pending"}
            {status === "SERVICE_REG_INCOMPLETE" && "Incomplete"}
            {status === "SERVICE_REG_SUSPENDED" && "Suspended"}
            {status === "MOP_MISSING" && "Missing Payment Method"}
            {status === "APPROVED" && t("Login.121")}
            {status === "PENDING" && t("Login.122")}
            {status === "ARCHIVED" && "Archived"}
            {status === "INACTIVE" && "Inactive"}
            {status === "SUSPENDED" && t("Login.123")}
            {status === "REJECTED" && t("Login.124")}
            {status === "INCOMPLETE" && t("Login.125")}
            {status === "unpaid" && t("Login.181")}
            {status === "paid" && t("Login.180")}
            {status === "pending" && t("Login.183")}
            {status === "ENTITY_PENDING" && t("Login.183")}
            {status === "undone" && t("Login.182")}
            {status === "declined" && t("Login.184")}
            {status === "approved" && t("Login.121")}
            {status === "REFUSED" && "REFUSED"}
            {status === "CANCELLED" && "CANCELLED"}
            {status === "REFUNDED" && "REFUNDED"}
          </div>
        </div>
      </div>
    </>
  );
}

export default StatusText;
