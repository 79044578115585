import React, {useContext} from 'react'
import { CoreContext } from '../../context/coreContext';
import { FrontOfficeContext } from '../../context/frontofficeContext';
import { Document, Page, pdfjs } from "react-pdf";

function PDFViewer(){
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const {t} = useContext(CoreContext)
    const {invoiceLink,setShowInvoice } = useContext(FrontOfficeContext)
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    return (
      <>
        <div className="fixed animate-fade-in  border-2 p-2 rounded-md shadow-xl bg-eloop-lightGray top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"> 
          <Document file={invoiceLink}>
            <Page width={320} pageNumber={1} />
          </Document>
          <div
          onClick={()=>setShowInvoice(false)}
           className="w-20 h-9 cursor-pointer bg-eloop-green fixed mt-5 border-eloop-lightGray text-eloop-blackish font-bold  left-1/2 rounded-md -translate-x-1/2 border-2 shadow-xl text-sm flex items-center justify-center  transform">{t("Registration.19")}</div>
        </div>
      </>
    );
  }

  export default PDFViewer
