import React, { useContext, useState, useEffect } from "react";
import { CoreContext } from "../../../context/coreContext";
import { useHistory } from "react-router-dom";
import { FaCheck, FaRegClone } from "react-icons/fa";
import {
  TelegramShareButton,
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  PinterestShareButton,
} from "react-share";
import {
  TelegramIcon,
  FacebookIcon,
  RedditIcon,
  FacebookMessengerIcon,
  EmailIcon,
  LinkedinIcon,
  PinterestIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useLoading, Bars } from "@agney/react-loading";
import { FrontOfficeContext } from "../../../context/frontofficeContext";
export const Referral = () => {
  let navigator = useHistory();
  const [finishedLoading, setFinishedLoading] = useState(false);
  const { t, fromApp } = useContext(CoreContext);
  const {
    setFOCurrentPage,
    Referral,
    referralCode,
    referralLink,
    usersReferred,

    earned,
  } = useContext(FrontOfficeContext);
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: (
      <Bars width="50" className="text-eloop-backish dark:text-white" />
    ),
  });
  async function Core() {
    setFinishedLoading(false);
    await Referral();
    setFinishedLoading(true);
  }
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    setFOCurrentPage("referral");
    Core();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!finishedLoading) {
    return (
      <>
        <div className="flex items-center justify-center h-screen">
          <section {...containerProps}>
            {indicatorEl} {/* renders only while loading */}
          </section>
        </div>
      </>
    );
  } else
    return (
      <div className="flex h-screen items-center flex-col dark:text-white justify-center bg-white dark:bg-eloop-darkGray">
        <div className="flex mx-12 flex-col items-center justify-center bg-white  dark:bg-eloop-mediumGray  dark:text-white text-gray-900 shadow-lg p-5 rounded-md">
          <div className="text-2xl text-center font-extrabold ">
            {t("Login.163")}
          </div>

          <div className="text-l text-gray-800 w-11/12 dark:text-gray-300  text-center font-medium mb-4">
            {t("Login.165")}
          </div>
          <div className="text-l text-gray-800 dark:text-white text-center font-bold mb-2 ">
            {t("Login.167")}
          </div>
          <div className="w-11/12 md:w-80 mb-2 flex shadow-md rounded-md bg-eloop-blackish h-20">
            <div className="w-1/2 border-r-2 flex flex-col items-center justify-center border-white text-white">
              <div className="text-3xl font-bold">{usersReferred}</div>
              <div className="text-xs text-center p-0.5">{t("Login.161")}</div>
            </div>
            <div className="w-1/2  flex flex-col items-center justify-center  text-white">
              <div className="text-3xl font-bold">€{earned}</div>
              <div className="text-xs text-center p-0.5">{t("Login.162")}</div>
            </div>
          </div>
          <div className="text-xl text-gray-800 dark:text-gray-200 text-center font-bold mt-2 ">
            {t("Login.166")}
          </div>
          <div className="text-sm text-gray-500 dark:text-gray-600 text-center font-bold ">
            {referralCode}
          </div>
          {fromApp ? (
            <CopyToClipboard
              text={t("Login.168") + referralLink}
              onCopy={() => setCopied(true)}
            >
              <div className="grid items-center cursor-pointer bg-gray-900 p-3 md:p-2.5 rounded-md text-white">
                {copied ? (
                  <FaCheck className="text-xl" />
                ) : (
                  <FaRegClone className="text-xl" />
                )}
              </div>
            </CopyToClipboard>
          ) : (
            <div className="flex flex-row flex-wrap justify-center w-96 items-center">
              <div className="flex flex-row w-80 justify-around pt-2">
                <CopyToClipboard
                  text={t("Login.168") + referralLink}
                  onCopy={() => setCopied(true)}
                >
                  <div className="grid items-center cursor-pointer bg-eloop-green p-3 md:p-2.5 rounded-md text-white">
                    {copied ? (
                      <FaCheck className="text-xl" />
                    ) : (
                      <FaRegClone className="text-xl" />
                    )}
                  </div>
                </CopyToClipboard>
                <FacebookShareButton
                  url={referralLink}
                  quote={t("Login.168") + referralLink}
                >
                  <FacebookIcon round={false} borderRadius="10" size="40" />
                </FacebookShareButton>
                <WhatsappShareButton url={t("Login.168") + referralLink}>
                  <WhatsappIcon round={false} borderRadius="10" size="40" />
                </WhatsappShareButton>
                <TelegramShareButton url={t("Login.168") + referralLink}>
                  <TelegramIcon round={false} borderRadius="10" size="40" />
                </TelegramShareButton>
                <TwitterShareButton url={t("Login.168") + referralLink}>
                  <TwitterIcon round={false} borderRadius="10" size="40" />
                </TwitterShareButton>
              </div>

              <div className="flex flex-row mt-1 w-80 justify-around pt-2">
                <LinkedinShareButton
                  url={referralLink}
                  title={t("Login.168")}
                  summary={t("Login.168")}
                >
                  <LinkedinIcon round={false} borderRadius="10" size="40" />
                </LinkedinShareButton>
                <RedditShareButton url={t("Login.168") + referralLink}>
                  <RedditIcon round={false} borderRadius="10" size="40" />
                </RedditShareButton>
                <EmailShareButton url={t("Login.168") + referralLink}>
                  <EmailIcon round={false} borderRadius="10" size="40" />
                </EmailShareButton>
                <FacebookMessengerShareButton
                  appId="961777394752844"
                  url={referralLink}
                >
                  <FacebookMessengerIcon
                    round={false}
                    borderRadius="10"
                    size="40"
                  />
                </FacebookMessengerShareButton>
                <PinterestShareButton
                  url={referralLink}
                  media={
                    "https://eloop.app/wp-content/uploads/tesla_2_bg-1024x655.png"
                  }
                  description={t("Login.168")}
                >
                  <PinterestIcon round={false} borderRadius="10" size="40" />
                </PinterestShareButton>
              </div>
            </div>
          )}
          {!fromApp && (
            <div
              onClick={() => {
                navigator.push("/profile");
              }}
              className="h-9  px-7 p-3 mb-2 text-center  w-auto text-sm rounded-full mt-4 bg-eloop-green cursor-pointer flex items-center justify-center  text-eloop-blackish font-bold"
            >
              {t("Login.190")}
            </div>
          )}
        </div>
      </div>
    );
};

export default Referral;
