import React, { useContext, useEffect, useState } from "react";
import { CoreContext } from "../../../context/coreContext";
import { useHistory } from "react-router-dom";

import {
  MinDropdown,
  HourDropdown,
  DayDropdown,
  KilometerInput,
  TokenInput,
} from "../../../components/calculator/dropdown";
import PlanSelector from "../../../components/calculator/planSelector";
import { FrontOfficeContext } from "../../../context/frontofficeContext";

function TripCalculator() {
  let navigator = useHistory();
  const { setFOCurrentPage } = useContext(FrontOfficeContext);
  const [kmToCalculate, setKmToCalculate] = useState(1000);
  const {
    userPlanToCalc,
    t,
    i18n,
    fromApp,
    CalculateCost,
    calculatedData,
    calcLoading,
    userPlan,
    setUserPlanToCalc,
  } = useContext(CoreContext);

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
    setFOCurrentPage("calculator");
    setUserPlanToCalc(userPlan);
    CalculateCost({
      plan: userPlan,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="flex h-auto w-auto pb-2 text-sm items-center  justify-center   bg-white ">
        <div className="h-auto flex w-screen  items-center  justify-center">
          <div
            className={
              fromApp
                ? "bg-white   mt-10 h-auto  mr-2 ml-2 p-2  rounded-xl  flex flex-col justify-start  items-center "
                : "bg-white    mt-24 h-auto lg:w-11/12 mr-2 ml-2 p-2  rounded-xl  flex flex-col justify-start  items-center "
            }
          >
            <div className=" py-3 h-min  justify-center px-5 gap-5 lg:w-11/12 flex flex-col md:flex-row  md:items-end items-center">
              <div className="flex md:w-1/3   items-center justify-center flex-col">
                <PlanSelector calculateCost={CalculateCost} />

                <div className="flex   mt-2 shadow-lg p-5 gap-2 rounded-3xl h-96 flex-row flex-wrap  justify-center items-start">
                  <div className="h-5/6 flex w-full justify-between flex-col">
                    <div className=" mb-4  text-left w-full text-lg">
                      {t("calculator.myTrip")}
                    </div>
                    <DayDropdown />
                    <HourDropdown />
                    <MinDropdown />
                    <KilometerInput
                      km={kmToCalculate}
                      setKm={setKmToCalculate}
                    />
                  </div>
                  <CustomButton
                    text={
                      calcLoading
                        ? t("Login.9").toUpperCase()
                        : t("Login.94").toUpperCase()
                    }
                    onClick={CalculateCost}
                    bgColor={"bg-eloop-purple"}
                  />
                </div>
              </div>
              <div className="flex mt-2 h-96 md:w-1/3 shadow-lg p-5 gap-2 rounded-3xl  flex-row flex-wrap  justify-center items-start">
                <div className="h-5/6 flex w-full justify-between flex-col">
                  <div className=" mb-4  text-left w-full text-lg">
                    {t("calculator.estimatedCost")}
                  </div>

                  <div className="w-full flex justify-between">
                    <h1>Unlock</h1>
                    <h1>{calculatedData?.unlock || "..."}&nbsp;€</h1>
                  </div>
                  <div className="w-full flex justify-between">
                    <h1>{t("calculator.duration")}</h1>
                    <h1>{calculatedData?.time || "..."}&nbsp;€</h1>
                  </div>
                  <div className="w-full flex justify-between">
                    <h1>
                      {t("calculator.kilometre")} ({calculatedData?.kmPrice}
                      €/km)
                    </h1>
                    <h1>{calculatedData?.km || "..."}&nbsp;€</h1>
                  </div>
                  <div className="w-full h-1 rounded-sm bg-gray-300" />
                  <div
                    className={`${
                      userPlanToCalc === "Basic" && "hidden"
                    } w-full   flex justify-between`}
                  >
                    <h1>{t("calculator.subtotal")}</h1>
                    <h1>{calculatedData.total}&nbsp;€</h1>
                  </div>

                  <div
                    className={`${
                      userPlanToCalc === "Basic" && "hidden"
                    } w-full  text-eloop-green flex justify-between`}
                  >
                    <h1>{`${t("calculator.discount")} (${userPlanToCalc})`}</h1>
                    <h1>
                      {" "}
                      {userPlanToCalc !== "Basic" && "-"}{" "}
                      {calculatedData?.discount || "..."}&nbsp;€
                    </h1>
                  </div>

                  <div className="w-full md:text-sm t mt-2 flex justify-between">
                    <h1 className="text-sm">{t("calculator.totalCost")}</h1>
                    <h1 className="font-bold">
                      {calculatedData?.finalCost || "..."}
                      &nbsp;€
                    </h1>
                  </div>

                  <h1 className="w-full whitespace-pre-line text-start text-gray-500 text-xs font-medium">
                    {t("calculator.disclaimer")}
                    <span
                      onClick={() =>
                        window.open(
                          i18n.language === "de"
                            ? "https://eloop.app/de/preisliste"
                            : "https://eloop.app/en/price-list"
                        )
                      }
                      className="underline"
                    >
                      {" "}
                      {t("calculator.disclaimerLink")}
                    </span>
                  </h1>

                  <div
                    className={`flex flex-col  items-center  gap-2 ${
                      userPlanToCalc === "Pro" && "invisible"
                    }`}
                  >
                    <h1 className="text-sm  text-eloop-purple text-center font-semibold">
                      {t("calculator.save1")}{" "}
                      {userPlanToCalc === "Basic"
                        ? calculatedData?.potentialSavingsWithPlus
                        : calculatedData?.potentialSavingsWithPro || "..."}
                      &nbsp;€ {t("calculator.save2")}
                      {userPlanToCalc === "Basic" ? "Plus" : "Pro"}
                    </h1>
                  </div>
                </div>
                <CustomButton
                  text={t("upgrade.20").toUpperCase()}
                  onClick={() => {
                    navigator.push("/upgrade");
                  }}
                  bgColor={"bg-gradient-to-l from-eloop-green to-eloop-purple"}
                />
              </div>
              <div className="flex mt-2 bg-black h-96 text-white md:w-1/3 shadow-lg p-5 gap-2 rounded-3xl  flex-row flex-wrap  justify-center items-start">
                <div className="h-5/6 flex justify-between flex-col">
                  <div className=" mb-4  text-left w-full text-lg">
                    ELOOP ONE Token
                  </div>
                  <div className=" mb-4  text-left w-full ">
                    {t("calculator.tokenDisclaimer")}
                  </div>

                  <TokenInput />
                  <div className="w-full flex justify-between">
                    <h1>Cashback</h1>
                    <h1>{calculatedData?.cashback || "..."}&nbsp;€</h1>
                  </div>
                  <div className="w-full -mt-5 text-gray-400 flex justify-between">
                    <h1>Cashback in %</h1>
                    <h1>{calculatedData?.cashbackPercentage || "..."}</h1>
                  </div>
                </div>
                <CustomButton
                  text={t("Login.99").toUpperCase()}
                  onClick={() => {
                    window.open("https://eloop.one", "_blank");
                  }}
                  bgColor="bg-eloop-purple"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function CustomButton({ text, onClick, bgColor }) {
  return (
    <div
      onClick={onClick}
      className={`w-52 h-10  ${bgColor} text-sm text-center cursor-pointer px-4 grid place-content-center py-2.5 rounded-full text-white `}
    >
      {text}
    </div>
  );
}

export default TripCalculator;
