import { createContext, useContext, useState } from "react";
import PassbaseAPI from "../api/passbaseAPI";
import { CoreContext } from "./coreContext";
import DatawarehouseAPI from "../api/datawarehouseAPI";
import VulogAPI from "../api/vulogAPI";

export const RegistrationContext = createContext();

// creating api objects
const PassbaseApi = new PassbaseAPI();
const DatawarehouseApi = new DatawarehouseAPI();
const VulogApi = new VulogAPI();

// get necessary information from coreContext
function RegistrationContextProvider(props) {
  const {
    userId,
    profileId,
    email,
    phoneNumber,
    setRegisterStep,
    setRefreshToken,
  } = useContext(CoreContext);

  //for getting gender;
  const [gender, setGender] = useState("");

  //get refresh token with the token that returned after registration
  async function GetRefreshToken(token, email, password) {
    return await new Promise(async (resolve, reject) => {
      try {
        let refreshToken = await VulogApi.GetRefreshToken(
          token,
          email,
          password
        );
        if (refreshToken !== 401) {
          setRefreshToken(refreshToken);
          resolve(refreshToken);
        } else {
          resolve(401);
        }
      } catch (e) {}
    });
  }

  // registration current page; look registrationRouter.js to see which number represents which page
  const [RegCurrentPage, setRegCurrentPage] = useState("");

  // passbase step, 0 for first page (send verification sms)
  const [passbaseStep, setPassbaseStep] = useState(0);

  // to save token that returns after user sends sms to phone
  const [pbReferenceToken, setPbReferenceToken] = useState("");

  const [showDataPrivacy, setShowDataPrivacy] = useState(false);
  const [showAgb, setShowAgb] = useState(false);

  //check if user finished passbase process
  async function CheckPassbase() {
    let key = await PassbaseApi.CheckPassbaseVerification(pbReferenceToken);
    if (key !== 401) {
      // if user finished passbase process route to final page
      setRegisterStep(6);
      //send the key to server
      await DatawarehouseApi.SendPBkey(key, userId, profileId);
    } else {
    }
  }

  //check if user sent passbaseLink, used to handle state
  const [pbError, setPbError] = useState(false);

  //handle passbase submit button
  async function PassbaseSubmit() {
    setPbReferenceToken("");
    let token = await PassbaseApi.SendSMS(email, phoneNumber);
    DatawarehouseApi.AddPhoneNum(profileId,phoneNumber);
    if (token !== 401) {
      //set passbaseLink as sent
 
      // save pb reference token;
      setPbReferenceToken(token);
      // go to waiting screen (wait to user untill user finishes uploading drivers liciens on passbase)
      setPassbaseStep(1);
  
      /** creatinng the pbkey for the first time after sucessfull got it back from passbase **/
      await DatawarehouseApi.SendPBkey(token, userId, profileId);
      //check verification Loop
    } else {
      
      setPassbaseStep(0);
    }
  }
  const [triedDropIn, setTriedDropIn] = useState(false);
  const [sentPbLink, setSentPbLink] = useState(false);
  const value = {
    triedDropIn,setTriedDropIn,
    showAgb, setShowAgb,
    setShowDataPrivacy,
    showDataPrivacy,
    pbError, setPbError,
    sentPbLink, setSentPbLink,
    gender,
    setGender,
    CheckPassbase,
    GetRefreshToken,
    pbReferenceToken,
    PassbaseSubmit,
    RegCurrentPage,
    setRegCurrentPage,
    passbaseStep,
    setPassbaseStep,
  };

  return (
    <RegistrationContext.Provider value={value}>
      {props.children}
    </RegistrationContext.Provider>
  );
}

export default RegistrationContextProvider;
