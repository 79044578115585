import React, { useContext, useState, useEffect } from "react";
import { CoreContext } from "../../../context/coreContext";
import { FrontOfficeContext } from "../../../context/frontofficeContext";
import config from "../../../config/config";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCheck, FaRegClone } from "react-icons/fa";
import MobileNavbar from "../../../components/header/mobileNavbar";
import DesktopNavbar from "../../../components/header/desktopNavbar";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";

function ContactPage() {
  const [email1Copied, setEmail1Copied] = useState(false);
  const [email2Copied, setEmail2Copied] = useState(false);
  const [phoneNumCopied, setPhoneNumCopied] = useState(false);
  let app = new URLSearchParams(useLocation().search).get("cityID");
  const { setFOCurrentPage } = useContext(FrontOfficeContext);
  const { t, setTheme, setRegistering } = useContext(CoreContext);
  function SetAsCopied(which) {
    switch (which) {
      case 1:
        setEmail1Copied(true);
        setTimeout(() => setEmail1Copied(false), 5000);
        return;
      case 2:
        setEmail2Copied(true);
        setTimeout(() => setEmail2Copied(false), 5000);
        return;
      case 3:
        setPhoneNumCopied(true);
        setTimeout(() => setPhoneNumCopied(false), 5000);
        return;
      default:
        setTimeout(() => setEmail1Copied(false), 5000);
    }
  }
  useEffect(() => {
    if (app) {
      setTheme("light");
    }

    setFOCurrentPage("contact");
    setRegistering(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!app && (isMobile ? <MobileNavbar /> : <DesktopNavbar />)}
      <div className="flex h-screen justify-center flex-col dark:text-white items-center bg-white dark:bg-eloop-darkGray">
        <div className="flex rounded-lg w-96 dark:text-white  px-4 mx-2  dark flex-col text-eloop-darkGray items-center text-center ">
          <div className="font-bold mb-2 text-3xl">{t("Login.144")}</div>
          <div>
            {t("Login.145").substring(0, t("Login.145").indexOf("?") + 1)}
          </div>
          <div>{t("Login.145").substring(t("Login.145").indexOf("?") + 1)}</div>
          <div className="flex mt-6 flex-col self-center items-center">
            <div className="flex items-start flex-col">
              <div className="flex flex-row mt-2">
                <div className="ml-2">{config.CONTACT.email1}</div>

                <CopyToClipboard
                  text={config.CONTACT.email1}
                  onCopy={() => SetAsCopied(1)}
                >
                  <div
                    className={
                      app
                        ? "grid items-center cursor-pointer ml-2 mt-1 rounded-md text-eloop-blackish"
                        : "grid items-center cursor-pointer ml-2 mt-1 rounded-md text-eloop-purple"
                    }
                  >
                    {email1Copied ? (
                      <FaCheck className="text-l" />
                    ) : (
                      <FaRegClone className="text-l" />
                    )}
                  </div>
                </CopyToClipboard>
              </div>
              <div className="flex flex-row mt-2">
                <div className="ml-2">{config.CONTACT.email2}</div>

                <CopyToClipboard
                  text={config.CONTACT.email2}
                  onCopy={() => SetAsCopied(2)}
                >
                  <div
                    className={
                      app
                        ? "grid items-center cursor-pointer ml-2 mt-1 rounded-md text-eloop-blackish"
                        : "grid items-center cursor-pointer ml-2 mt-1 rounded-md text-eloop-purple"
                    }
                  >
                    {email2Copied ? (
                      <FaCheck className="text-l " />
                    ) : (
                      <FaRegClone className="text-l" />
                    )}
                  </div>
                </CopyToClipboard>
              </div>
            </div>
            <div className="flex flex-row mt-2">
              <div className="mx-1">{config.CONTACT.phone}</div>
              <div>{t("Login.146")}</div>
              <CopyToClipboard
                text={config.CONTACT.phone}
                onCopy={() => SetAsCopied(3)}
              >
                <div
                  className={
                    app
                      ? "grid items-center cursor-pointer ml-2 mt-1 rounded-md text-eloop-blackish"
                      : "grid items-center cursor-pointer ml-2 mt-1 rounded-md text-eloop-purple"
                  }
                >
                  {phoneNumCopied ? (
                    <FaCheck className="text-l" />
                  ) : (
                    <FaRegClone className="text-l" />
                  )}
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ContactPage;
