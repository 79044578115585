import React, { useContext, useState } from "react";
import VulogAPI from "../../api/vulogAPI";
import { CoreContext } from "../../context/coreContext";
import DetailText from "../text/detailText";
import SectionText from "../text/sectionText";

function PersonalDetails() {
  const {
    t,
    firstName,
    lastName,
    i18n,
    language,
    GetUserInfo,
    accessToken,
    isJwtNotExpired,
    hasBusiness,

  } = useContext(CoreContext);
  const [clicked, setClicked] = useState(false);

  let VulogApi = new VulogAPI();
  async function ChangeLang(newLang) {
    let token = await isJwtNotExpired(accessToken);
    let dateToUpdate = {
      type: "lang",
      refreshToken: token,
      locale: newLang,
    };
    let status = await VulogApi.UpdateUser(dateToUpdate);
    if (status === 200) {
      i18n.changeLanguage(t("Login.3"));
      setClicked(false);
      GetUserInfo(token);
    }
   
  }
  return (
    <>
      <div
        className={
          hasBusiness
            ? "w-full rounded-2xl items-start h-auto  mt-6 md:mt-12 dark:bg-eloop-blackish  bg-white shadow-lg pb-2 flex flex-col"
            : "w-full rounded-2xl items-start h-auto  bg-white shadow-lg dark:bg-eloop-blackish  pb-2 flex flex-col"
        }
      >
        <SectionText label={t("Login.24")} />
        <div className="flex flex-col w-full ">
          <DetailText label={t("Login.25")} info={firstName} />

          <DetailText label={t("Login.26")} info={lastName} />
          <div className="flex justify-between flex-row w-full">
            <DetailText label={t("Login.27")} info={language} />
            <div>
              <div
                onClick={() => setClicked(!clicked)}
                className=" mr-2 select-none px-1 py-0 h-9 w-52 text-sm text-right grid place-content-center font-semibold rounded-full mt-1 bg-eloop-green cursor-pointer   text-eloop-blackish"
              >
                {t("Login.208")}
              </div>
              {clicked && (
                <div
                  onClick={() => {
                    let lang;
                    if (language === "Deutsch") {
                      lang ="en_GB";
                    } else {
                      lang = "de_AT";
                    }
                    ChangeLang(lang);
                  }}
                  className=" mr-2 absolute select-none px-1 py-0 h-9 w-52 text-sm text-right grid place-content-center font-semibold rounded-full mt-1 bg-eloop-green cursor-pointer   text-eloop-blackish"
                >
                  {language === "Deutsch" ? "English" : "Deutsch"}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PersonalDetails;
